<template>
  <div>
    <div :class="hideCurrency ? 'blur-effect row' : 'row'">
      <BarCharts :data="data" />
    </div>
    <div class="row mt-3">
      <TableHistoric :data="data" />
    </div>
    <div class="row">
      <TotalBox :type="'historic'" :transactions="data" class="col-lg-4" />
    </div>
  </div>
</template>

<script>
import TableHistoric from "@/components/transaction/TableHistoric.vue";
import BarCharts from "@/components/transaction/BarCharts.vue";
import TotalBox from "@/components/transaction/TotalBox.vue";
import { useAppStore } from "@/store/store.js";
import { mapActions, mapState } from "pinia";
export default {
  components: { TableHistoric, BarCharts, TotalBox },
  props: {
    data: Object,
  },
  computed: {
    ...mapState(useAppStore, ["hideCurrency"]),
  },
};
</script>
