<template>
  <div class="box">
    <div class="title">
      <p class="mb-1">Saldo</p>
    </div>
    <div class="investment-value">
      <div>
        <h2>Saldo das contas de Investimento</h2>
        <span :class="hideCurrency ? 'blur-effect' : ''">{{
          formatValue(balance)
        }}</span>
      </div>
      <div class="logo">
        <img
          class="logo-icon"
          src="~@/assets/logo-icon.png"
          alt="Logo do Dashboard"
        />
      </div>
    </div>

    <div class="btn-box-investments" style="margin-bottom: 9px">
      <button class="btn-default" @click="goTo('/cashflow/settings')">
        Acessar contas <span class="mr-1">&#10095;</span>
      </button>
    </div>
  </div>
</template>

<script>
import { useAppStore } from "@/store/store.js";
import { mapActions, mapState } from "pinia";

export default {
  name: "InvestmentBox",
  props: {
    balance: Number,
  },
  computed: {
    ...mapState(useAppStore, ["hideCurrency"]),
  },
  methods: {
    formatValue(valor) {
      const appStore = useAppStore();
      return appStore.formatValue(valor);
    },
    goTo(route) {
      this.$router.push(route);
      this.currentRoute = route;
    },
  },
};
</script>

<style lang="scss" scoped>
/* Seus estilos personalizados aqui */

.title {
  color: aliceblue;
  font-weight: bolder;
  p {
    font-size: 16px;
  }
  border-bottom: 1px solid #181d2f;
  margin-bottom: 20px;
}

.btn-box-investments {
  margin-top: 20px;
  text-align: end;
}

.box-liquidity {
  display: flex;
  align-items: center;
  border-bottom: 2px solid #181d2f;
  border-top: 2px solid #181d2f;
  justify-content: center;
  h2 {
    font-size: 14px;
    font-weight: 400;
  }
  span {
    font-size: 14px;
    font-weight: bold;
  }
}
.with {
  text-align: center;
  width: 100%;
}
.without {
  text-align: center;
  width: 100%;
}
.vertical-line {
  width: 3px;
  height: 50px;
  background-color: #181d2f;
  margin: 15px;
}
</style>
