<template>
  <div class="loading-container">
    <img class="loading-logo" src="~@/assets/LOGOCOMPLETO.png" alt="Loading" />
  </div>
</template>

<script>
import axios from "axios";
export default {
  mounted() {
    localStorage.setItem("pn_token", this.$route.params.token);
    axios
      .post("user/login_by_token", {
        token: this.$route.params.token,
      })
      .then((response) => {
        const token = response.data?.access_token;
        localStorage.setItem("token", token);
        this.$router.push({ name: "dashboard" });
      })
      .catch((error) => {
        localStorage.removeItem("pn_token");
      });
  },
};
</script>

<style scoped>
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #181d2f;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 1;
  transition: opacity 0.5s ease-in-out; /* Transição suave da opacidade */
  pointer-events: all; /* Permite interação com o loading */
}
.loading-logo {
  width: 100px; /* Largura inicial da logo */
  height: auto;
  animation: pulse 1.5s infinite alternate; /* Animação de pulsação */
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2); /* Aumenta a logo em 20% */
  }
}

.content {
  display: none;
}
@media only screen and (max-width: 990px) {
  .quote {
    margin-left: 10px;
  }
}
</style>
