<template>
  <div>
    <canvas ref="doughnutChart" />
  </div>
</template>

<script>
	import Chart from "chart.js/auto";

	export default {
		props: {
			totalGoal: {
				type: Number,
				required: true,
			},
			gastofixoamount: {
				type: Number,
				required: true,
			},
			gastovariavelamount: {
				type: Number,
				required: true,
			},
			investmentamount: {
				type: Number,
				required: true,
			},
			creditamount: {
				type: Number,
				required: true,
			},
		},
		mounted() {
			this.renderDoughnutChart();
		},
		methods: {

			renderDoughnutChart() {
				const ctx = this.$refs.doughnutChart.getContext("2d");
				const data = {
					  labels: ['Total Goal', 'Gasto fixo', 'Gasto variável ', 'Investmento', 'Entradas'],
					datasets: [
						{
							data: [
								this.totalGoal,
								this.gastofixoamount,
								this.gastovariavelamount,
								this.investmentamount,
								this.creditamount,
							],
							backgroundColor: ["#1E2434", "#004AAD", "#5F91E6", "#0CC1E0", "#5fe687"],
						},
					],
				};
				const options = {
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						legend: {
							display: false, // Oculta a legenda do gráfico
						},
						doughnut: {
							cutout: 20, // Define o tamanho do buraco no meio do gráfico
						},
					},
					elements: {
						arc: {
							borderWidth: 0, // Remove a borda dos arcos do gráfico
						},
					},
					cutout: 80, // Define o tamanho do buraco no meio do gráfico (alternativa para a propriedade plugins.doughnut.cutout)
                    borderRadiusBottom: 20, // Define o valor do borderRadius em todos os cantos do gráfico
				};

				new Chart(ctx, {
					type: "doughnut",
					data: data,
					options: options,
				});
			},
		},
	};
</script>

<style scoped>
	canvas {
		max-width: 200px;
		max-height: 200px;
		margin: 0 auto;
		display: block;
	}
</style>
