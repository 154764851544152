<template>
  <Toast />
  <div>
    <div v-if="accounts?.length != 0" class="form-default container">
      <div>
        <p v-if="successMessage" class="success-message">
          {{ successMessage }}
        </p>

        <p v-else-if="errorMessage" class="error-message">
          {{ errorMessage }}
        </p>
      </div>
      <div v-if="selected == false && typeForm != 'investment'">
        <div class="row">
          <div class="col-12 mt-3">
            <label>Categoria</label>
            <Dropdown
              v-model="transaction.id_transaction_category"
              :options="creditCategories"
              class="custom-dropdown"
              option-label="name"
              option-value="id"
              placeholder="Selecione uma opção"
            >
              <template #value="slotProps">
                <div v-if="slotProps.value" class="flex align-items-center">
                  <div class="custom-option">
                    <i
                      :class="`${
                        categories?.filter(
                          (item) => item.id == slotProps.value
                        )[0].icon || 'fa-regular fa-image'
                      }`"
                      :style="{
                        color: `${
                          categories?.filter(
                            (item) => item.id == slotProps.value
                          )[0].color || 'white'
                        }`,
                      }"
                    />
                    {{
                      categories?.filter(
                        (item) => item.id == slotProps.value
                      )[0].name
                    }}
                  </div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="{ option }">
                <div class="custom-option">
                  <i
                    :class="option.icon ? option.icon : 'fa-regular fa-image'"
                    :style="{
                      color: `${option.color ? option.color : 'white'}`,
                    }"
                  />
                  {{ option.name }}
                </div>
              </template>
            </Dropdown>
          </div>
        </div>

        <div v-if="selected == false" class="row">
          <div class="col mt-3">
            <label for="">Descrição</label>
            <input
              id="description"
              v-model="transaction.description"
              type="text"
              name="description"
              class="form-control"
              placeholder=""
            />
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <label for="">Valor</label>
          <input
            id="amount"
            v-model="transaction.amount"
            v-money="moneyConfig"
            type="text"
            name="amount"
            class="form-control"
            placeholder=""
            required
          />
        </div>
        <div class="col">
          <label for="">Data da transação</label>
          <input
            id="transaction_at"
            v-model="transaction.transaction_at"
            type="date"
            name="transaction_at"
            class="form-control"
            placeholder="dd/mm/yyyy"
            onkeydown="return false"
            onclick="this.showPicker()"
            required
          />
        </div>
      </div>

      <div class="row mt-3">
        <div v-if="!(accounts?.length == 0)" class="col">
          <label>Qual a conta?</label>
          <Dropdown
            v-model="transaction.id_bank_account"
            :options="accounts"
            class="custom-dropdown"
            option-label="name"
            option-value="id"
            placeholder="Selecione uma opção"
          />
        </div>
      </div>
      <!--Repetição -->
      <div class="row mt-3">
        <div class="col">
          <label>Como irá se repetir?</label>
          <Dropdown
            v-model="repeatData.$repeat_type"
            :options="repeatType"
            class="custom-dropdown"
            option-label="text"
            option-value="value"
            placeholder="Selecione uma opção"
          />
        </div>

        <div v-if="repeatData.$repeat_type == 'installment'" class="col">
          <label>Num. parcelas</label>
          <select
            v-model="repeatData.$repeat_number"
            class="form-control form-control"
          >
            <option disabled value="">Selecione uma opção</option>
            <option v-for="n in 12" :key="n" :value="n">
              {{ n }}
            </option>
          </select>
        </div>
      </div>
      <div
        v-if="
          repeatData.$repeat_type == 'installment' &&
          repeatData.$repeat_number > 0 &&
          transaction.amount != 'R$ 0,000'
        "
        class="row calc"
      >
        <h3>
          Sua <span>Despesa</span> será parcelada em
          <span>{{ payload.$repeat_number }}x</span> de
          <span> {{ formatValue(amountInstallment) }}</span>
        </h3>
      </div>
      <button class="mt-3" @click="saveCustom">
        <span> Salvar </span>
      </button>
    </div>
    <div v-else class="no-goals-message">
      Você ainda não tem nenhuma conta ou cartão registrado.
      <br />
      <span
        >Para criar uma conta ou cartão, vá até as
        <router-link class="link-router" to="/cashflow/settings"
          >configurações</router-link
        >
      </span>
    </div>
  </div>
</template>

<script>
import * as luxon from "luxon";
import axios from "axios";
import { useAppStore } from "@/store/store.js";
import shared from "@/shared";
import { unformat } from "v-money3";
import Dropdown from "primevue/dropdown";
export default {
  components: {
    Dropdown,
  },
  props: {
    typeForm: {
      type: String,
      required: true,
    },
    quick: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close", "reload"],
  data() {
    return {
      requiredFields: [],
      selected: false,
      successMessage: "",
      errorMessage: "",
      interval: [
        { value: "month", text: "Mês" },
        { value: "year", text: "Ano" },
      ],
      repeatType: [
        { value: "avulso", text: "Somente este mês" },
        { value: "recurring", text: "Repetir todos os meses" }
      ],
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      transaction: {
        amount: null,
        description:
          this.typeForm == "investment" ? "Transação de Investimento" : "",
        id_bank_account: null,
        id_transaction_category: this.typeForm == "investment" ? 3 : 1,
        id_transaction_method: null,
        id_transaction_type: this.typeForm == "investment" ? 3 : 4,
        transaction_at: luxon.DateTime.now().toFormat("yyyy-MM-dd"),
        type: this.typeForm == "investment" ? "credit" : this.typeForm,
      },
      repeatData: {
        $repeat: false,
        $repeat_has_end_date: false,
        $repeat_end_date: luxon.DateTime.now().toFormat("yyyy-MM-dd"),
        $repeat_interval: "month",
        $repeat_number: 12,
        $repeat_type: "recurring",
      },
      moneyConfig: shared.moneyConfig(),
    };
  },
  computed: {
    amountInstallment() {
      let amount = 0;

      amount = this.payload.amount / this.repeatData.$repeat_number;

      return amount;
    },

    token() {
      const store = useAppStore();
      const token = store.token;

      return token;
    },

    transactionMethod() {
      const store = useAppStore();
      const transactionMethodResponse = store.transactionMethodResponse;

      return transactionMethodResponse;
    },
    transactionType() {
      const store = useAppStore();
      const transactionTypeResponse = store.transactionTypeResponse;
      const t = transactionTypeResponse?.filter((item) =>
        [3, 4].includes(item.id)
      );
      return t;
    },
    categories() {
      const store = useAppStore();
      const categories = store.categoriesResponse;

      return categories;
    },
    accounts() {
      const store = useAppStore();
      const accounts = store.accountsResponse;
      const filteredAccounts = accounts?.filter(
        (acc) => acc.id_account_type == "1" || acc.id_account_type == "2"
      );
      return filteredAccounts;
    },
    creditCategories() {
      const cat = this.categories
        ?.filter((acc) => acc.id_transaction_type == 4)
        .sort((a, b) => b.name.localeCompare(a.name)); // Ordena por 'name' em ordem decrescente
      return cat;
    },
    calculateDiff() {
      const initialDate = luxon.DateTime.fromISO(
        this.transaction.transaction_at
      );
      const endDate = luxon.DateTime.fromISO(this.repeatData.$repeat_end_date);

      const diff = {
        day: Math.floor(endDate.diff(initialDate, "days").days),
        week: Math.floor(endDate.diff(initialDate, "weeks").weeks),
        month: Math.floor(endDate.diff(initialDate, "months").months),
        year: Math.floor(endDate.diff(initialDate, "years").years),
      };
      return diff;
    },
    payload() {
      const unformattedAmount = unformat(
        this.transaction.amount,
        this.moneyConfig
      );

      return {
        amount: unformattedAmount,
        description: this.transaction.description,
        id_bank_account: this.transaction.id_bank_account,
        id_transaction_category: this.transaction.id_transaction_category,
        id_transaction_method: this.transaction.id_transaction_method,
        id_transaction_type: this.transaction.id_transaction_type,
        transaction_at: this.transaction.transaction_at,
        type: this.transaction.type,
        $repeat: this.repeatData.$repeat,
        $repeat_interval: this.repeatData.$repeat_interval,
        $repeat_number:
          this.repeatData.$repeat_has_end_date &&
          this.repeatData.$repeat_type == "recurring"
            ? this.calculateDiff[this.repeatData.$repeat_interval]
            : this.repeatData.$repeat_number,
        $repeat_type: this.repeatData.$repeat_type,
      };
    },
  },
  watch: {
    quick() {
      // this.selected = this.quick;
      this.selected = false;
    },
    "transaction.id_transaction_category"(newVal) {
      const selectedCategory = this.creditCategories?.find(
        (category) => category.id === newVal
      );
      if (selectedCategory && selectedCategory.name === "Salário") {
        this.transaction.description = "Salário";
      } else {
        this.transaction.description = "";
      }
    },
  },
  created() {
    // this.selected = this.quick;
    this.selected = false;
  },
  mounted() {
    const salaryCategory = this.creditCategories?.find(
      (category) => category.name === "Salário"
    );
    if (salaryCategory) {
      this.transaction.id_transaction_category = salaryCategory.id;
    }
  },
  methods: {
    formatValue(valor) {
      const appStore = useAppStore();
      return appStore.formatValue(valor);
    },

    select(value) {
      // this.selected = value;
      this.selected = false;
    },
    getTransactionType(valor) {
      const appStore = useAppStore();
      return appStore.getTransactionType(valor);
    },

    saveCustom() {
      if (!this.transaction.amount) {
        this.$toast.add({
          severity: "error",
          summary: "Desculpe mas não podemos continuar",
          detail: "Informe o valor da transação",
          life: 5000,
        });
        return false;
      }

      if (this.transaction.amount.includes("-")) {
        this.$toast.add({
          severity: "error",
          summary: "Desculpe mas não podemos continuar",
          detail: "Não é possivel aceitar valor negativo",
          life: 5000,
        });
        return false;
      }

      if (!this.transaction.transaction_at) {
        this.$toast.add({
          severity: "error",
          summary: "Desculpe mas não podemos continuar",
          detail: "A Data não pode ser vazia",
          life: 5000,
        });
        return false;
      }
      if (!this.transaction.id_bank_account) {
        this.$toast.add({
          severity: "error",
          summary: "Desculpe mas não podemos continuar",
          detail: "Por favor selecione um banco",
          life: 5000,
        });
        return false;
      }
      if (!this.transaction.description) {
        this.$toast.add({
          severity: "error",
          summary: "Desculpe mas não podemos continuar",
          detail: "A Descrição não pode ser vazia",
          life: 5000,
        });
        return false;
      }

      if (this.transaction.description?.length > 50) {
        this.$toast.add({
          severity: "error",
          summary: "Desculpe mas não podemos continuar",
          detail: "A Descrição não pode ser maior que 50 caracteres",
          life: 5000,
        });
        return false;
      }

      axios
        .post(`/transaction_custom`, this.payload, {
          headers: { Authorization: `Bearer ${this.token}` },
        })
        .then((response) => {
          // this.successMessage = "Transação salva com sucesso!";
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Transação salva com sucesso!",
            life: 1500,
          });
          this.$emit("reload");
          const store = useAppStore();
          store.updateData();

          setTimeout(() => {
            this.closeModal();
          }, 1500);
        })
        .catch((error) => {
          console.error("Erro ao salvar transação:", error);
          this.transaction.amount = null;
          this.$toast.add({
            severity: "error",
            summary: "Desculpe mas não podemos continuar",
            detail: "Erro ao salvar transação. Por favor, tente novamente.",
            life: 5000,
          });
          // this.errorMessage =
          //   "Erro ao salvar transação. Por favor, tente novamente.";
        });
    },

    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-options-overview {
  border: 1px solid #cb5540;
  border-radius: 5px;
  text-align: center;
  margin: 1px;

  h2 {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    cursor: pointer;
    padding: 10px;
    margin: 0;
  }

  h2:hover {
    font-weight: bold;
  }
}

.calc {
  margin: 20px;
  text-align: center;

  h3 {
    color: #fff;
    font-size: 16px;
  }

  span {
    color: rgb(0, 255, 13);
    font-weight: bold;
  }
}

.switch {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.selected {
  background: #cb5540;
  border-radius: 5px 0px 0px 5px;

  h2 {
    font-weight: bold !important;
  }
}
</style>
