<template>
  <div class="container">
    <table class="table custom-table">
      <thead>
        <tr>
          <th>Mês</th>
          <th>Entradas</th>
          <th>Gasto variável</th>
          <th>Gasto fixo</th>
          <th>Meta Definida</th>
          <th>Investimento</th>
          <th>Saldo</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(line, index) in data"
          :key="index"
          :class="index % 2 == 0 ? 'row-even' : 'row-odd'"
        >
          <td>{{ getMonthName(index) }}</td>
          <td :class="hideCurrency ? 'blur-effect' : ''">
            {{ formatValue(line.credit) }}
          </td>
          <td :class="hideCurrency ? 'blur-effect' : ''">
            {{ formatValue(line.variable) }}
          </td>
          <td :class="hideCurrency ? 'blur-effect' : ''">
            {{ formatValue(line.fixed) }}
          </td>
          <td :class="hideCurrency ? 'blur-effect' : ''">
            {{ formatValue(line.debit_goals) }}
          </td>
          <td :class="hideCurrency ? 'blur-effect' : ''">
            {{ formatValue(line.investment) }}
          </td>
          <td :class="hideCurrency ? 'blur-effect' : ''">
            {{ formatValue(line.credit - line.debit) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { useAppStore } from "@/store/store.js";
import { mapActions, mapState } from "pinia";
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    totalDebitBalanceByGoal: Number,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(useAppStore, ["hideCurrency"]),
  },
  methods: {
    returnIndex(index, data) {
      let keys = Object.keys(data);
      let mainIndex = keys.indexOf(index);
      return mainIndex;
    },
    accumulated(index, mainIndex) {
      var keys = Object.keys(this.data);
      var previousIndex = mainIndex - 1;
      var previousKey = keys[previousIndex];
      var previousValue = this.data[previousKey];
      let accumulated;
      accumulated =
        previousValue.credit -
        previousValue.debit +
        (this.data[index].credit - this.data[index].debit);
      return accumulated;
    },
    formatValue(valor) {
      const appStore = useAppStore();
      return appStore.formatValue(valor);
    },
    getMonthName(value) {
      const date = new Date();
      date.setMonth(value - 1);

      const options = { month: "long" };

      return date.toLocaleString("pt-BR", options);
    },
  },
};
</script>

<style lang="scss"></style>
