<template>
  <div class="main-box" style="overflow-x:auto;">
    <table
      v-if="data.length > 0"
      class="table custom-table"
      
    >
      <thead>
        <tr>
          <th scope="col" width="25%">
            Categoria
          </th>
          <th
            v-if="type == 'credit' || type == 'investment'"
            scope="col"
            width="17.5%"
          >
            Entradas
          </th>
          <th
            v-if="type == 'debit'"
            scope="col"
            width="17.5%"
          >
            Saídas
          </th>
          <th scope="col" width="17.5%">
            Metas
          </th>
          <th scope="col" width="22.5%">
            Progresso.
          </th>
          <th scope="col" width="17.5%">
            Saldo
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(transaction, index) in transactionsPaginated"
          :key="index"
          :class="index % 2 === 0 ? 'row-even' : 'row-odd'"
        >
          <td class="category">
            <ImageContainer
              :icon="transaction.transaction_category_icon"
              :color="transaction.transaction_category_color"
            />
            {{
              transaction.transaction_category_name
                ? transaction.transaction_category_name
                : "Categoria sem Nome"
            }}
          </td>

    
          <td>
            {{ formatValue(Math.abs(transaction.balance)) }}
          </td>
          <td>{{ transaction.goal_amount != null ? formatValue(transaction.goal_amount) : '-' }}</td>
          <td v-if="transaction.goal_amount != null" class="text-center">
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                :style="{
                  width: progress(Math.abs(transaction.balance), transaction.goal_amount) + '%',
                  background: color
                }"
                :aria-valuenow="Math.abs(transaction.balance)"
                aria-valuemin="0"
                :aria-valuemax="transaction.goal_amount"
              />
            </div>
            <span>{{ progress(Math.abs(transaction.balance), transaction.goal_amount) + "%" }}</span>
          </td>
          <td v-else>
            -
          </td>
          <td>{{ formatValue(Math.abs(transaction.balance)) }}</td>
        </tr>
      </tbody>
    </table>
    <div
      v-else
      class="no-goals-message"
    >
      <p>Você ainda não possui histórico para este mês.</p>
    </div>
    <div class="separator" />
    <nav
      v-if="totalPages > 1"
      class="pagination-nav"
    >
      <div
        class="pagination-link-arrow"
        :class="{ disabled: currentPage === 1 }"
        @click="previousPage"
      >
        <i class="fa-solid fa-chevron-left" />
      </div>
      <div class="pagination-box-pages">
        <div
          v-for="page in totalPages"
          :key="page"
          :class="{ active: currentPage === page }"
          class="pagination-pages"
        >
          <span @click="goToPage(page)">
            {{ page }}
          </span>
        </div>
      </div>

      <div
        class="pagination-link-arrow"
        :class="{ disabled: currentPage === totalPages }"
        @click="nextPage"
      >
        <i class="fa-solid fa-chevron-right" />
      </div>
    </nav>
  </div>
</template>

<script>
import { useAppStore } from "@/store/store.js";
import ImageContainer from "@/components/shared/ImageContainer.vue";

export default {
  components: { ImageContainer },
  props: {
    data: {
      type: Array,
      required: true,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    type: {
      type: String,
      default: "credit",
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    totalPages() {
      return Math.floor(Object.values(this.data).length / this.perPage);
    },

    transactionsPaginated() {
      // if (!this.transactions || typeof this.transactions !== "object") {

      // 	return [];
      // }

      // Flatten the data into an array of transactions
      const allTransactions = Object.values(this.data).flat();
      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;

      // Slice the sorted transactions to get the desired page
      const paginatedTransactions = allTransactions.slice(startIndex, endIndex);

      return paginatedTransactions;
    },
    color() {
      if (this.type == "credit") {
        return "#00AD00";
      }
      if (this.type == "debit") {
        return "#D92A2A";
      }
      if (this.type == "investment") {
        return "#56B0EE";
      }
    },
  },
  methods: {
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    goToPage(page) {
      this.currentPage = page;
    },
    progress(valor_atual, valor_meta) {
				let progresso = 0;
				progresso = (valor_atual / valor_meta) * 100;
				return progresso.toFixed(1);
			},

    formatValue(valor) {
      const appStore = useAppStore();
      return appStore.formatValue(valor);
    },
  },
};
</script>

<style lang="scss" scoped>
.category {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  i {
    // width: 10%;
    font-size: 10px;
    padding: 8px;
    border-radius: 100px;
    background-color: #00ad00;
    margin-right: 10px;
  }
}
.payment {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  i {
    // width: 10%;
    font-size: 10px;
    padding: 8px;
    border-radius: 100px;
    background-color: #e67308;
    margin-right: 10px;
  }
}

.day-label {
  font-size: 16px;
  text-align: left;
  font-weight: 600;
  color: #f26236;
}
</style>
