<template>
  <div class="box">
    <div v-if="goalsBalance && !goalsBalance.loading">
      <p>Gráfico de gastos</p>
      <div
        v-if="goalsBalance.data.balanceByType.length === 0"
        class="no-goals-message"
      >
        <p>Nenhuma meta encontrada para a opção selecionada</p>
      </div>
      <div v-else>
        <div class="chart-box">
          {{ calculateTotalAmountByType(4) }}
          <div :class="hideCurrency ? 'blur-effect' : ''">
            <DoughnutChart
              :total-goal="dashboardBalance.data.goals.debit || 0"
              :gastofixoamount="goalsBalance.data.balanceByType[0].balance || 0"
              :gastovariavelamount="goalsBalance.data.balanceByType[1].balance || 0"
              :investmentamount="goalsBalance.data.balanceByType[2].balance || 0"
              :creditamount="goalsBalance.data.balanceByType[3].balance || 0"
            />
          </div>

          <div class="chart-box-goals">
            <div class="chart-total">
              <h2>Meta de gastos</h2>
              <p :class="hideCurrency ? 'blur-effect' : ''">
                {{ formatValue(dashboardBalance.data.goals.debit) }}
              </p>
            </div>
            <div class="chart-total">
              <h2>Total de gastos</h2>
              <p :class="hideCurrency ? 'blur-effect' : ''">
                {{ formatValue(dashboardBalance.data.balance.debit) }}
              </p>
            </div>
          </div>
        </div>

        <div
          v-for="(goal, index) in goalsBalance.data.balanceByType"
          class="progress-box"
        >
          <div
            class="vertical-line-goal"
            :class="{
              'gasto-fixo': goal.id == 1,
              'gasto-variavel': goal.id == 2,
              investment: goal.id == 3,
              credit: goal.id == 4,
            }"
          />
          <div class="goal-item">
            <h3>{{ goal.name }}</h3>
            <div class="goal-amount">
              <h4 :class="hideCurrency ? 'blur-effect' : ''">
                {{ formatValue(Math.abs(goal.balance)) }}
              </h4>
              <h4 :class="hideCurrency ? 'blur-effect' : ''">
                {{ formatValue(goal.goal_amount) }}
              </h4>
            </div>
            <div class="progress">
              <div
                :class="['progress-bar', getProgressBarClass(goal.name)]"
                role="progressbar"
                :style="{
                  width:
                    progress(Math.abs(goal.balance), goal.goal_amount) + '%',
                }"
                :aria-valuenow="goal.goal_amount"
                aria-valuemin="0"
                :aria-valuemax="Math.abs(goal.balance)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore } from "@/store/store.js";
import { mapActions, mapState } from "pinia";
import DoughnutChart from "../transaction/DoughnutChart.vue";
export default {
  name: "CreditCharts",
  components: { DoughnutChart },
  props: {
    goalsBalance: Object,
    dashboardBalance: Object,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState(useAppStore, ["hideCurrency"]),
  },
  watch: {
    // Observa as mudanças nas props para definir "loading" como false
    balanceTypeGoals() {
      if (this.balanceTypeGoals != null) {
        this.loading = false;
      }
    },
  },
  mounted() {
    if (this.balanceTypeGoals != null) {
      this.loading = false; // Define "loading" como false
    }
  },
  methods: {
    propsChanged() {
      // Verifica se todas as props não são mais nulas
      if (
        this.transactions != null &&
        this.financial_goals != null &&
        this.balanceTypeGoals != null
      ) {
        this.loading = false; // Define "loading" como false
      }
    },
    getProgressBarClass(id) {
      switch (id) {
        case "Gasto fixo":
          return "progress-bar-gasto-fixo";
        case "Gasto variável":
          return "progress-bar-gasto-variavel";
        case "Investimento":
          return "progress-bar-investmento";
        case "Entrada":
          return "progress-bar-entrada";
      }
    },
    formatValue(valor) {
      const appStore = useAppStore();
      return appStore.formatValue(valor);
    },
    getTransactionType(valor) {
      const appStore = useAppStore();
      return appStore.getTransactionType(valor);
    },
    calculateTotalAmountByType(id_transaction_type) {
      const transactionType = this.goalsBalance.data.balanceByType.find(
        (item) => item.id_transaction_type === id_transaction_type
      );
      if (transactionType) {
        const totalAmount = parseFloat(transactionType.balance) || 0;
        return totalAmount;
      } else {
        return 0;
      }
    },

    progress(current_amount, amount) {
      let progresso = 0;
      progresso = (current_amount / amount) * 100;
      return progresso.toFixed(1);
    },
    sumAmountByIdTransactionType(idTransactionType, total) {
      total = this.data
        .filter((obj) => obj.id_transaction_type === idTransactionType)
        .reduce((acc, obj) => acc + parseFloat(obj.amount), 0);
    },
    calculateGoals() {
      const goals = this.financial_goals.reduce((acc, obj) => {
        const { id_transaction_type, amount } = obj;
        if (acc[id_transaction_type]) {
          acc[id_transaction_type].totalAmount += parseFloat(amount);
        } else {
          acc[id_transaction_type] = {
            totalAmount: parseFloat(amount),
          };
        }
        return acc;
      }, {});
      this.goals = goals;
    },
  },
};
</script>

<style lang="scss" scoped>
/* Seus estilos personalizados aqui */
.box {
  p {
    color: #fff;
    font-weight: bold;
    font-size: 16px;
  }
}

.chart-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.vertical-line-goal {
  width: 5px;
  height: 60px;
  border-radius: 5px;
  background-color: #181d2f;
  margin: 15px;
}

.gasto-fixo {
  background-color: #00439c;
}

.investment {
  background-color: #0cc1e0;
}

.gasto-variavel {
  background-color: #5f91e6;
}

.credit {
  background-color: #5fe687;
}

.progress-box {
  display: flex;
  align-items: center;
}

.chart-total {
  color: #fff;

  h2 {
    font-size: 16px;
    font-weight: 600;
  }

  p {
    font-size: 14px;
    font-weight: 300;
  }
}

.goal-item {
  color: #fff;
  width: 100%;

  h3 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 5px;
  }

  h4 {
    font-size: 14px;
    font-weight: 400;
  }
}

.goal-amount {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progress-bar-entrada {
  background-color: #5fe687;
}
.progress-bar-investmento {
  background-color: #0cc1e0;
}

.progress-bar-gasto-fixo {
  background-color: #004aad;
}
.progress-bar-gasto-variavel {
  background-color: #5f91e6;
}
</style>
