<template>
  <div class="content">
    <div>
      <div v-if="typeForm == 'detail'" class="btn-options no-border">
        <h2 :class="{ selected: selected == 'credit' }" @click="select('credit')">
          Receita
        </h2>
        <h2 :class="{ selected: selected == 'debit' }" @click="select('debit')">
          Despesa
        </h2>
        <h2 :class="{ selected: selected == 'investment' }" @click="select('investment')">
          Investimento
        </h2>
      </div>
      <FormInvestment v-if="typeForm == 'investment' || selected == 'investment'" :format-value="formatValue" @close="closeModal" @reload="reload"/>

      <FormTransactionCredit v-if="typeForm == 'credit' || selected == 'credit'" :type-form="typeForm" :quick="quick"
        @close="closeModal" @reload="reload" />

      <FormTransactionDebit v-if="typeForm == 'debit' || selected == 'debit'" :type-form="typeForm" :quick="quick"
        @reload="reload" @close="closeModal" />
    </div>
  </div>
</template>

<script>
import { useAppStore } from "@/store/store.js";
import FormTransactionCredit from "./form/FormTransactionCredit.vue";
import FormTransactionDebit from "./form/FormTransactionDebit.vue";
import FormTransfer from "./FormTransfer.vue";
import FormInvestment from "./FormInvestment.vue";
export default {
  components: { 
    FormTransactionCredit, 
    FormTransactionDebit, 
    FormTransfer, 
    FormInvestment 
  },
  props: {
    typeForm: {
      type: String,
      required: true,
    },
    quick: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selected: "",
    };
  },
  watch: {},
  created() { },

  mounted() { },
  methods: {
    formatValue(valor) {
      const appStore = useAppStore();
      return appStore.formatValue(valor);
    },
    select(value) {
      this.selected = value;
    },
    reload() {
      this.$emit("reload");
    },

    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.no-border {
  border: none !important;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-options {
  border: 1px solid #cb5540;
  border-radius: 5px;
  text-align: center;
  margin: 10px 0;

  h2 {
    margin: 0;
    padding: 20px;
    border: 1px solid #cb5540;
  }
}

.btn-options-overview {
  border: 1px solid #cb5540;
  border-radius: 5px;
  text-align: center;
  margin: 1px;

  h2 {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    cursor: pointer;
    padding: 10px;
    margin: 0;
  }

  h2:hover {
    font-weight: bold;
  }
}

.calc {
  text-align: center;

  h3 {
    color: #fff;
    font-size: 16px;
  }

  span {
    color: red;
    font-weight: bold;
  }
}

.switch {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.selected {
  background: #cb5540;
  border-radius: 5px 0px 0px 5px;

  h2 {
    font-weight: bold !important;
  }
}
</style>
