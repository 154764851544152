// utils.js
import * as luxon from "luxon";

const shared = {
  formatDate: function (dateString) {
    return luxon.DateTime.fromISO(dateString).toFormat("dd/MM/yyyy HH:mm");
  },
  formatDateWithoutHours: function (dateString) {
    return luxon.DateTime.fromISO(dateString).toFormat("dd/MM/yyyy");
  },
  moneyConfig: function () {
    return {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      suffix: "",
      precision: 2,
      masked: false,
      modelModifiers: {
        number: true,
      },
    };
  },
};

export default shared;
