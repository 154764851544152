<template>
  <div class="box-filter text-center d-flex justify-content-around">
    <button class="btn-default" @click="openModal">
      <i class="fa-solid fa-filter" /> Filtros
    </button>
    <div class="tag-filter-container">
      <div v-for="(tag, index) in filterTags" :key="index" class="tag-filter">
        {{ tag == "credit" || tag == "debit" ? getTransactionType(tag) : tag }}
        <i class="fa-solid fa-xmark" @click="removeTag(index)" />
      </div>
    </div>
    <div class="search-box">
      <input
        id="formSearch"
        v-model="searchQuery"
        type="text"
        class="form-search"
        placeholder="Pesquisar..."
        @input="emitInput"
      />
      <i class="fa-solid fa-magnifying-glass" />
    </div>
    <Modal
      id="ModalFormFilter"
      title="Filtros"
      :is-open="isModalOpen"
      @close="closeModal"
    >
      <FormFilter
        :transactions="transactions"
        @close="closeModal"
        @filters-applied="handleFiltersApplied"
      />
    </Modal>
  </div>
</template>

<script>
import FormFilter from "../transaction/FormFilter.vue";
import Modal from "@/components/shared/Modal.vue";
import { useAppStore } from "@/store/store.js";

export default {
  name: "TransactionsFilter",
  components: { Modal, FormFilter },
  props: {
    transactions: Array,
    tags: Array,
  },
  data() {
    return {
      filterTags: [],
      searchQuery: "",
      isModalOpen: false,
    };
  },
  computed: {},
  watch: {
    filterTags() {
      this.$emit("tags-change", this.filterTags);
    },
  },
  mounted() {
    this.filterTags = this.tags;
  },
  methods: {
    emitInput() {
      // Emit the input value to the parent
      this.$emit("input-change", this.searchQuery);
    },
    getTransactionType(valor) {
      const appStore = useAppStore();
      return appStore.getTransactionType(valor);
    },

    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    handleFiltersApplied(selectedFilters) {
      this.filterTags = selectedFilters;
    },

    removeTag(index) {
      this.filterTags.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
/* Add any custom styles for the modal here */
.box-filter {
  padding: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.row {
  --bs-gutter-x: 0 !important; // Define explicitamente o valor de --bs-gutter-x como 0
  --bs-gutter-y: 0 !important;
}

.tag-filter-container {
  display: flex;
  justify-content: center;
}
.tag-filter {
  overflow: auto;
  display: flex;
  margin: 0 8px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #fff;
  background: #ea60361e;
  border-radius: 15px;
  padding: 8px;

  i {
    margin-left: 5px;
    font-size: 10px;
    cursor: pointer;
  }
}

.btn-filter {
  background-color: #cb5540;
  border-radius: 5px;
  color: #fff;
  width: 98px;
  height: 30px;
  cursor: pointer;

  span {
    font-size: 12px;
  }
  &:hover {
    transform: scale(1.05);
  }
}
.search-box {
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    background-color: transparent;
    border: 1px solid #61677c;
    border-radius: 6px;
    font-size: 12px;
    padding: 6px;
    color: #61677c;
  }
  i {
    background: #ea6036;
    border-radius: 4px;
    color: #fff;
    padding: 8px;
    margin-left: -6px;
    cursor: pointer;
  }
}
</style>
