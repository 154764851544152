<template>
  <div class="box quick-transactions">
    <h2>Lançamentos rápidos</h2>
    <div class="quick-transactions-btn">
      <div class="content-grid row">
        <div class="col-md">
          <div class="btn-box revenue" @click="emitModalOpen(true, 'credit')">
            <i class="fas fa-solid fa-circle-plus" />
            <span>Receita </span>
          </div>
        </div>
        <div class="col-md">
          <div
            class="btn-box expenses col-md"
            @click="emitModalOpen(true, 'debit')"
          >
            <i class="fas fa-solid fa-circle-minus" />
            <span>Despesa </span>
          </div>
        </div>
        <div class="col-md">
          <div
            class="btn-box investment col-md"
            @click="emitModalOpen(true, 'investment')"
          >
            <i class="fas fa-solid fa-chart-column" />
            <span>Investimento</span>
          </div>
        </div>
      </div>
      <!-- <ButtonGradient :type-button="'historic'" @click="goTo('historic')" /> -->
    </div>
  </div>
</template>

<script>
import ButtonGradient from "@/components/shared/ButtonGradient.vue";

export default {
  components: {
    ButtonGradient,
  },
  name: "QuickBox",
  data() {
    return {};
  },
  methods: {
    emitModalOpen(value, type) {
      this.$emit("modal-open", value, type);
    },
    goTo(route) {
      this.$router.push(route);
      this.currentRoute = route;
    },
  },
};
</script>

<style lang="scss" scoped>
/* Seus estilos personalizados aqui */
.quick-transactions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;

  h2 {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
.quick-transactions-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  min-height: 150px;
  opacity: 1;

  h2 {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

.btn-box {
  display: block;
  padding: 10px 10px;
  text-align: center;
  margin: 0;
  color: #fff;
  margin-bottom: 5px;
  border-radius: 10px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.3s ease;

  i {
    font-size: 35px !important;
    margin-bottom: 10px;
    display: block;
  }
  span {
    font-size: 12px;
  }
  &:hover {
    transform: scale(1.05);
  }
}

.revenue {
  background-color: #00ad00;
}
.expenses {
  background-color: #c62525;
}
.investment {
  background-color: #56b0ee;
}
p {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #f26236;
  opacity: 1;
  cursor: pointer;
  user-select: none;
}
</style>
