<template>
  <div class="view-options">
    <div class="header-box mt-3 mb-2">
      <h3>Método de visualização</h3>
      <!-- <i class="fas fa-info-circle" /> -->
    </div>

    <label :class="{ 'selected-item': selected === 'category' }">
      <input
        v-model="selected"
        type="radio"
        value="category"
        @change="emitSelected"
      >
      <span>
        Categorias
      </span> 
    </label>
    <label :class="{ 'selected-item': selected === 'payment' }">
      <input
        v-model="selected"
        type="radio"
        value="payment"
        @change="emitSelected"
      >
      <span>
        Contas e cartões
      </span>
			
    </label>
  </div>
</template>

<script>
	export default {
		data() {
			return {
				selected: "category",
			};
		},
		methods: {
			emitSelected() {
				this.$emit("option-selected", this.selected);
			},
		},
	};
</script>
<style lang="scss" scoped>

input[type="radio"] {
    opacity: 0; /* Torna o radio input invisível */
    position: absolute; /* Remove o radio input do fluxo do documento */
  }

input[type="radio"] + span:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #61677C;
  margin-right: 8px;
}

input[type="radio"]:checked + span:before {
  background-color: #fff;
  border: 4px solid #cb5540;
}
	.view-options {
		color: #fff;
		font-size: 14px;
		
		label {
			border: 1px solid #fff;
			border-radius: 5px;
			width: 100%;
			cursor: pointer;
			padding: 10px;
			margin: 5px 0;
		}
        input{
            margin-right: 10px;
            background-color: #cb5540 !important;
			
        }


	}
    .selected-item {
           
            background: #cb554031 ;
			border: 1px solid #cb5540 !important;
       
                font-weight: bold;
            
         
			
			// Outros estilos que você deseja aplicar quando selecionado
		}

	.header-box {
		display: flex;
		align-items: center;

		h3 {
			color: #fff;
			font-size: 13px;
			font-weight: 500;
			margin: 0;
		}
		i {
			color: #cb5540;
			margin-left: 5px;
		}
	}
</style>
