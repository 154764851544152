<template>
  <div v-if="isOpen" class="modal">
    <div :class="['modal-content', termsAndPrivacy ? 'width-to-terms' : '']">
      <div :class="['header-modal', !title ? 'justify-content-end' : '']">
        {{ title }}
        <i class="fa-solid fa-xmark" @click="closeModal" />
      </div>
      <slot class="slot" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: Boolean,
    title: String,
    termsAndPrivacy: Boolean,
  },
  watch: {
    isOpen(newVal) {
      // Adiciona ou remove a classe 'no-scroll' no corpo dependendo do estado do modal
      document.body.classList.toggle("no-scroll", newVal);
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
/* Estilização básica para o modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.header-modal {
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  i {
    cursor: pointer;
    user-select: none;
  }
}

.modal-content {
  background: #283045;
  box-shadow: 0px 3px 6px #00000029;
  min-width: 450px;

  max-width: 500px;
  border-radius: 5px;
  padding: 10px 20px;
}

.width-to-terms {
  max-width: 750px !important;
}

@media screen and (max-width: 768px) {
  .modal-content {
    max-width: 85%;
    min-width: none;
    padding: 10px 5px;
  }

  .width-to-terms {
    max-width: 85% !important;
  }
}
</style>
