<template>
  <div>
    <router-view />
    <ConfirmDialog />
  </div>
</template>

<script>
export default {};
</script>
