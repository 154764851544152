<template>
  <div>
    <header>
      <div class="principal">
        <div class="user-info">
          <div class="user-details">
            <h1 class="username">{{ user.name }}</h1>
          </div>
          <img v-if="profileImage" :src="profileImage"  @click="showDropdown = !showDropdown" alt="Imagem do Perfil" />
          <div v-else class="user-icon" @click="showDropdown = !showDropdown">
            <i class="fas fa-user"></i>
          </div>
          <div class="dropdown" v-if="showDropdown">
            <!-- Aqui você pode adicionar as opções do dropdown, como a opção "Sair" -->
            <div class="dropdown-option" @click="logout">Sair</div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { useAppStore } from "@/store/store.js";

export default {
  data() {
    return {
      showDropdown: false
    };
  },
  props: {
    user: Object
  },
  computed: {
    profileImage() {
      const store = useAppStore();
      const profileImage = store.profileImage;

      return profileImage;
    }
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
  }
};
</script>

<style lang="scss" scoped>
.user-icon {
  display:flex;
justify-content:center ;
align-items:center;
  width: 40px;
  height: 40px;
  background: #423645;
  cursor: pointer;
  border-radius: 100px;
  i {
    text-align: center;
    margin:0
  }
}
img {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  cursor: pointer;
}

.principal {
  display: flex;
  justify-content: flex-end;
  align-content: center;

  font: normal normal bold 14px/18px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.dropdown {
  position: absolute;
  top: 80px; /* Ajuste essa posição conforme necessário */
  right: 20px; /* Ajuste essa posição conforme necessário */
  background-color: #423645;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.user-info {
  display: flex;
  text-align: left;
  align-items: center;
  margin: 0 !important;
}

.username {
  margin: 18px;
  font-size: 14px;
  font-weight: bold;
}

header {
  background: #283045 0% 0% no-repeat padding-box;
  border-radius: 7px;
  opacity: 1;
  color: #ffff;
  width: 100%;
  padding:10px
}
</style>
