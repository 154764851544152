<template>
  <div class="main-box">
    <table class="table custom-table">
      <thead>
        <tr>
          <th scope="col">
            Descrição
          </th>

          <th scope="col">
            Categoria
          </th>
          <th>Contas e cartões</th>

          <th scope="col">
            Valor
          </th>
          <th scope="col">
            Ações
          </th>
        </tr>
      </thead>
      <tbody v-for="(transactions, day) in groupByDay">
        <tr>
          <td class="day-label">
            <span>
              {{ formatDateString(day) }}
            </span>
          </td>
        </tr>

        <tr
          v-for="(transaction, index) in transactions"
          :key="index"
          :class="index % 2 === 0 ? 'row-even' : 'row-odd'"
        >
          <!-- Descricao -->
          <td>
            {{ transaction.description ? transaction.description : "-" }}

            <br>
            <span
              v-if="transaction.repeat_type == 'recurring'"
              class="badge text-bg-light"
            >
              N. Recorrência: {{ transaction.installment_number }}</span>

            <span
              v-if="transaction.repeat_type == 'installment'"
              class="badge text-bg-light"
            >
              parcela {{ transaction.installment_number }} /
              {{ transaction.repeat_number }}</span>
          </td>
          <!-- Categoria -->
          <td class="category">
            
            <i v-if="transaction.id_transaction_category_data"
              class="image-container"
              :class="transaction.id_transaction_category_data.icon"
              :style="{
                borderRadius: '50%',
                backgroundColor: transaction.id_transaction_category_data.color,
              }"
            ></i>

          <i v-else class="image-container"></i>

          <span v-if="transaction.id_transaction_category_data" class="badge text-bg-secondary">
            {{
              transaction.id_transaction_category_data
                ? transaction.id_transaction_category_data.name
                : "Categoria sem Nome"
            }}
          </span>

          <span v-else class="badge text-bg-secondary">
            Categoria sem Nome
          </span>

            <br>
            <span
              v-if="!isInvestmentTransaction(transaction)"
              :class="{
                'text-bg-danger': transaction.type === 'debit',
                'text-bg-primary': transaction.type === 'investment',
                'text-bg-success': transaction.type === 'credit',
                'text-bg-warning': transaction.type === 'investment_withdrawal',
              }"
              class="badge"
            >
              {{ getTransactionType(transaction.type) }}
            </span>
            <br>
            <span
              v-if="
                transaction.id_transaction_method_data &&
                  transaction.id_transaction_method_data.name
              "
              class="badge text-bg-info"
            >
              {{ transaction.id_transaction_method_data.name }}
            </span>
            <span
              v-if="!isInvestmentTransaction(transaction)"
              class="badge text-bg-danger"
            >
              {{ transaction.id_transaction_type_data.name }}
            </span>
            <span class="badge text-bg-light">
              {{ getRepeatTypeName(transaction.repeat_type) }}</span>
          </td>
          <!-- Contas e Cartões -->
          <td>
            <img
              :src="
                '/logos_bancos/' +
                  transaction.id_bank_account_data.id_bank +
                  '.png'
              "
              class="img_banco_lancamentos"
            >

            <span class="badge text-bg-light">{{
              transaction.id_bank_account_data.name
            }}</span>
            <br>

            <span class="badge text-bg-light">{{
              getAccountTypeName(
                transaction.id_bank_account_data.id_account_type
              )
            }}</span>
          </td>
          <!-- Valores -->
          <td
            :class="hideCurrency ? 'blur-effect' : ''"
            style="text-align: center"
          >
            {{ formatValue(transaction.amount) }}
          </td>
          <!-- Ações -->
          <td>
            <div
              class="edit-icon"
              @click="emitEdit(transactions[index])"
            >
              <i class="fa-solid fa-pen" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="separator" />
    <nav
      v-if="totalPages > 1"
      class="pagination-nav"
    >
      <div
        class="pagination-link-arrow"
        :class="{ disabled: currentPage === 1 }"
        @click="previousPage"
      >
        <i class="fa-solid fa-chevron-left" />
      </div>
      <div class="pagination-box-pages">
        <div
          v-for="page in totalPages"
          :key="page"
          :class="{ active: currentPage === page }"
          class="pagination-pages"
        >
          <span @click="goToPage(page)">
            {{ page }}
          </span>
        </div>
      </div>

      <div
        class="pagination-link-arrow"
        :class="{ disabled: currentPage === totalPages }"
        @click="nextPage"
      >
        <i class="fa-solid fa-chevron-right" />
      </div>
    </nav>
  </div>
</template>

<script>
import { useAppStore } from "@/store/store.js";
import { mapState } from "pinia";
import ImageContainer from "@/components/shared/ImageContainer.vue";
import { INVESTMENT } from "../../constants/account_types";

export default {
  components: { ImageContainer },
  props: {
    transactions: {
      type: Array,
      required: true,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    visualization: {
      type: String,
    },
  },
  data() {
    return {
      currentPage: 1,
      selectedTransaction: null,
      transactionSelected: [],
    };
  },
  computed: {
    ...mapState(useAppStore, ["hideCurrency"]),

    totalPages() {
      return Math.ceil(Object.values(this.transactions).flat().length / this.perPage);
    },
    groupByDay() {
      return this.transactionsPaginated.reduce(
        (groupedTransactions, transaction) => {
          const transactionDate = transaction.transaction_at;
          if (!groupedTransactions[transactionDate]) {
            groupedTransactions[transactionDate] = [];
          }
          groupedTransactions[transactionDate].push(transaction);
          return groupedTransactions;
        },
        {}
      );
    },
    transactionsPaginated() {
      const allTransactions = Object.values(this.transactions).flat();

      if ((this.currentPage - 1) * this.perPage >= allTransactions.length) {
        this.currentPage = 1;
      }

      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;

      return allTransactions.slice(startIndex, endIndex);
    },
  },
  watch: {},
  methods: {
    emitEdit(index) {
      this.$emit("open", index);
    },
    getAccountTypeName(name) {
      switch (name) {
        case 1:
          "Conta C/C - Poupança";
        case 2:
          return "Conta C/C - Poupança";
        case 3:
          return "Conta Investimento";
        case 4:
          return "Cartão de Crédito";
      }
    },
    getRepeatTypeName(name) {
      switch (name) {
        case "recurring":
          return "Recorrência";
        case "installment":
          return "Parcelado";
        default:
          return "Somente este mês";
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    formatDateString(dateString) {
      const months = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ];
      const dateParts = dateString.split("-");
      const year = parseInt(dateParts[0]);
      const month = parseInt(dateParts[1]);
      const day = parseInt(dateParts[2]);
      const formattedDate = `${day} de ${months[month - 1]}`;
      return formattedDate;
    },
    getTransactionType(valor) {
      const appStore = useAppStore();
      return appStore.getTransactionType(valor);
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    goToPage(page) {
      this.currentPage = page;
    },
    formatValue(valor) {
      const appStore = useAppStore();
      return appStore.formatValue(valor);
    },
    isInvestmentTransaction(transaction) {
      return transaction.id_transaction_type === INVESTMENT;
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-icon {
  cursor: pointer;
  user-select: none;
}

.category {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  i {
    font-size: 10px;
    padding: 8px;
    border-radius: 100px;
    background-color: #00ad00;
    margin-right: 10px;
  }
}

.payment {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  i {
    // width: 10%;
    font-size: 10px;
    padding: 8px;
    border-radius: 100px;
    background-color: #e67308;
    margin-right: 10px;
  }
}

.day-label {
  font-size: 16px;
  text-align: left;
  font-weight: 600;
  color: #f26236;
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
  margin: 3px;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(
    var(--bs-success-rgb),
    var(--bs-bg-opacity, 1)
  ) !important;
}

.cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(
    var(--bs-info-rgb),
    var(--bs-bg-opacity, 1)
  ) !important;
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(
    var(--bs-primary-rgb),
    var(--bs-bg-opacity, 1)
  ) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(
    var(--bs-danger-rgb),
    var(--bs-bg-opacity, 1)
  ) !important;
}

.text-bg-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.text-bg-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.text-bg-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.img_banco_lancamentos {
  border-radius: 50%;
  width: 30px;
  background-color: #fff !important;
}
.image-container {
  font-size: 15px !important;
}
table {
  text-align: center !important;
}
</style>
