<template>
  <div>
    <TableHistoricMethods :data="methodsWithGoals" />
  </div>
</template>

<script>
import TableHistoricMethods from "../transaction_method/TableHistoricMethods.vue";
export default {
  components: {
    TableHistoricMethods,
  },
  props: {
    data: Object,
    financial_goal: Object,
  },
  data() {
    return {};
  },
  computed: {
    methodsWithGoals() {
      if (this.financial_goal) {
        const methodsGoals = this.data.map((method) => {
          const goal = this.financial_goal.find((goal) => goal.id_transaction_method === method.id_transaction_method);

          return {
            ...method,
            goal_amount: goal ? goal.goal_amount : null,
          };
        });
        return methodsGoals;
      } else {
        return [];
      }
    },
    debitTotalGoal() {
      const total = this.debitCategories.reduce((total, category) => {
        const goalAmount = category.goal_amount !== null ? parseFloat(category.goal_amount) : 0;
        return total + goalAmount;
      }, 0);
      return isNaN(total) ? 0 : total;
    },

    creditTotalGoal() {
      const total = this.creditCategories.reduce((total, category) => {
        return total + parseFloat(category.goal_amount);
      }, 0);

      // Verificar se total é NaN e retornar 0 se for
      return isNaN(total) ? 0 : total;
    },

    investmentTotalGoal() {
      const total = this.investmentCategories.reduce((total, category) => {
        return total + parseFloat(category.goal_amount);
      }, 0);

      // Verificar se total é NaN e retornar 0 se for
      return isNaN(total) ? 0 : total;
    },

    debitCategories() {
      const cat = Object.values(this.methodsWithGoals).filter(
        (acc) => acc.id_transaction_type == "2" || acc.id_transaction_type == "1"
      );

      return cat;
    },

    creditCategories() {
      const cat = Object.values(this.methodsWithGoals).filter((acc) => acc.id_transaction_type == "4");

      return cat;
    },
    investmentCategories() {
      const cat = Object.values(this.methodsWithGoals).filter((acc) => acc.id_transaction_type == "3");

      return cat;
    },
    totalDebit() {
      // Calculate the total debit by summing the debit amounts of each category
      return Object.values(this.debitCategories).reduce(
        (total, category) => total + parseFloat(Math.abs(category.balance)),
        0
      );
    },
    totalBalanceInvestment() {
      // Calculate the total debit by summing the debit amounts of each category
      return Object.values(this.investmentCategories).reduce(
        (total, category) => total + parseFloat(category.balance),
        0
      );
    },

    totalCredit() {
      // Calculate the total credit by summing the credit amounts of each category
      return Object.values(this.creditCategories).reduce((total, category) => total + parseFloat(category.balance), 0);
    },
  },
  created() {},
  methods: {},
};
</script>

<style scoped>
/* Estilos específicos do HistoricView */
h2 {
  font-size: 18px;
  font-weight: bold;
}
</style>
