<template>
  <div>
    <!-- Check if totalGoal or amount is zero, display a message -->
    <div v-if="totalGoal == 0 || amount == 0" class="title-chart">
      <p>
        Gráfico de {{ warnInfo }} não disponível.
        <br>
        Por favor, defina uma meta de {{ warnInfo }}.
      </p>
    </div>
    <!-- If totalGoal and amount are not zero, display the chart -->
    <div v-else>
      <div
        class="title-chart"
        :class="{
          'green-cell': type === 'credit',
          'red-cell': type === 'debit',
          'blue-cell': type === 'investment',
          'yellow-cell' : type === 'investment_withdrawal',
        }">
        <h2>{{ title }}</h2>
        <canvas ref="doughnutChart" />
        <div class="amount-box">
          <h4>TOTAL</h4>
          <h2>{{ formatValue(amount) }}</h2>
          <h4>META</h4>
          <h2>{{ formatValue(totalGoal) }}</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import { useAppStore } from "@/store/store.js";

export default {
  props: {
    totalGoal: {
      type: Number,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
    type: String,
  },
  computed: {
    title() {
      if (this.type == "credit") {
        return "Receitas";
      }
      if (this.type == "debit") {
        return "Despesas";
      }
      if (this.type == "investment") {
        return "Investimentos";
      }
    },
    color() {
      if (this.type == "credit") {
        return "#00AD00";
      }
      if (this.type == "debit") {
        return "#D92A2A";
      }
      if (this.type == "investment") {
        return "#56B0EE";
      }
    },
    warnInfo() {
      if (this.type == "credit") {
        return "receita";
      }
      if (this.type == "debit") {
        return "despesa";
      }
      if (this.type == "investment") {
        return "investimento";
      }
    },
  },
  watch: {
    // Watch for changes in props and update the chart when they change
    totalGoal: "renderDoughnutChart",
    amount: "renderDoughnutChart",
  },
  mounted() {
    // Render the initial chart

    this.renderDoughnutChart();
  },
  methods: {
    formatValue(valor) {
      const appStore = useAppStore();
      return appStore.formatValue(valor);
    },
    renderDoughnutChart() {
      if (this.totalGoal === 0 || this.amount === 0) {
        return;
      }
      const ctx = this.$refs.doughnutChart.getContext("2d");

      // Calculate the remaining amount to reach the goal
      let remainingAmount = this.totalGoal - this.amount;

      // Check if remainingAmount is negative, set it to zero
      if (remainingAmount < 0) {
        remainingAmount = 0;
      }

      // Check if totalGoal or amount is zero, don't create the chart

      const data = {
        datasets: [
          {
            data: [this.amount, remainingAmount], // amount and remainingAmount
            backgroundColor: [this.color, "#1E2434"], // Red for amount spent, and another color for remainingAmount
          },
        ],
      };
      const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          doughnut: {
            cutout: 20,
          },
        },
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
        cutout: 80,
        borderRadiusBottom: 20,
      };

      // Destroy the previous chart to prevent duplicates
      if (this.chart) {
        this.chart.destroy();
      }

      this.chart = new Chart(ctx, {
        type: "doughnut",
        data: data,
        options: options,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
canvas {
  max-width: 200px;
  max-height: 200px;
  margin: 0 auto;
  display: block;
}

.amount-box {
  color: #fff;
  margin-top: 20px;
  h4 {
    font-size: 12px;
    font-weight: 300;
  }
  h2 {
    font-size: 19px;
    font-weight: 600;
  }
}
.title-chart {
  text-align: center;
  margin: 10px 0;
  h2 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }
}
</style>
