<template>
  <div>
    <input
      v-model="data1"
      type="date"
      placeholder="Start Date"
    >
    <input
      v-model="data2"
      type="date"
      placeholder="End Date"
    >
    <br>
    <span style="color: white">{{ calculateDiffDays.days }} days</span>
    <br>
    <span style="color: white">{{ parseInt(calculateDiffDays.weeks) }} weeks</span>
    <br>
    <span style="color: white">{{ calculateDiffDays.months }} months</span>
    <br>
    <span style="color: white">{{ calculateDiffDays.years }} years</span>
  </div>
</template>

<script>
import * as luxon from "luxon";

export default {
  data() {
    return {
      data1: luxon.DateTime.now().toFormat("yyyy-MM-dd"),
      data2: luxon.DateTime.now().plus({ years: 1 }).toFormat("yyyy-MM-dd"),
    };
  },
  computed: {
    calculateDiffDays() {
      const dateTime1 = luxon.DateTime.fromISO(this.data1);
      const dateTime2 = luxon.DateTime.fromISO(this.data2);

      const diff = {
        days: dateTime2.diff(dateTime1, "days").days,
        weeks: dateTime2.diff(dateTime1, "weeks").weeks,
        months: dateTime2.diff(dateTime1, "months").months,
        years: dateTime2.diff(dateTime1, "years").years,
      };
      return diff;
    },
  },
  watch: {
    data1(value) {
      this.diffDays = this.calculateDiffDays;
    },
    data2(value) {
      this.diffDays = this.calculateDiffDays;
    },
  },
};
</script>
