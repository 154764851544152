<template>
  <div class="box-dark">
    <div class="account-header">
      <div class="account-header-title">
        <ImageContainer
          :icon="null"
          :color="null"
          :source="account.id_bank_data.id + '.png'"
        />
        <div>
          <h1>{{ account.id_bank_data.name }}<br />{{ account.name }}</h1>
          <p>
            {{ account.label }}
          </p>
        </div>
      </div>
      <div>
        <i
          class="fa-solid fa-pen"
          style="margin-right: 1rem"
          @click="openModal(account)"
        />

        <i
          class="fa-solid fa-trash delete-icon"
          @click="confirmDelete(account.id)"
        />
      </div>
    </div>
    <div class="separator" />
    <div class="balance" v-if="!isCardAccount">
      <p>Saldo</p>
      <p :class="hideCurrency ? 'blur-effect' : ''">
        {{ formatValue(account.balance != null ? account.balance : 0) }}
      </p>
    </div>
  </div>
  <Toast />
</template>

<script>
import { useAppStore } from "@/store/store.js";
import { mapActions, mapState } from "pinia";
import ImageContainer from "@/components/shared/ImageContainer.vue";
import axios from "axios";
import { useConfirm } from "primevue/useconfirm";

export default {
  components: { ImageContainer },
  props: {
    account: {},
    color: String,
  },
  emits: ["accDetail", "editAccount"],

  data() {
    return {};
  },
  computed: {
    ...mapState(useAppStore, ["hideCurrency"]),

    isCardAccount() {
      if (this.account.id_account_type == "4") {
        return true;
      } else {
        return false;
      }
    },
    token() {
      const store = useAppStore();
      const token = store.token;

      return token;
    },
    dollarValue() {
      const store = useAppStore();
      const dollar = store.getExchangeRates?.USDBRL?.high;
      return dollar;
    },

    euroValue() {
      const store = useAppStore();
      const euro = store.getExchangeRates?.EURBRL?.high;
      return euro;
    },

    brlToUsd() {
      const store = useAppStore();
      const coin = store.brlToUsd;
      return coin;
    },

    brlToEur() {
      const store = useAppStore();
      const coin = store.brlToEur;
      return coin;
    },
  },
  setup() {
    return { confirm: useConfirm() };
  },
  color() {},
  methods: {
    async reloadData() {
      const store = useAppStore();
      await store.getAccounts();
    },
    openModal(value) {
      this.$emit("editAccount", value);
    },
    formatValue(valor) {
      const appStore = useAppStore();

      return appStore.formatValue(valor);
    },
    emitAccDetail() {
      this.$emit("accDetail", this.account);
    },
    confirmDelete(id) {
      this.confirm.require({
        message: "Você tem certeza que deseja deletar?",
        header: "Deletar",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Deletar",
        rejectLabel: "Voltar",
        accept: () => {
          this.deleteAccount(id);
        },
      });
    },
    deleteAccount(id) {
      axios
        .delete(`/bank_account/${id}`, {
          headers: { Authorization: `Bearer ${this.token}` },
        })
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Conta deletada com sucesso!",
            life: 3000,
          });
          setTimeout(() => {
            this.reloadData();
          }, 1000);
        })
        .catch((error) => {
          console.error("Error deleting account:", error.response);
          if (error.response.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "Erro ao deletar. A conta possui histórico no sistema.",
              life: 5000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail:
                "Houve um erro ao deletar a conta. Por favor, tente novamente.",
              life: 5000,
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
i {
  cursor: pointer;
  user-select: none;
}

.account-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;

  h1 {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }

  p {
    font-size: 12px;
    font-weight: 300;
    margin: 0;
  }
}

.account-header-title {
  display: flex;
  align-items: center;
}

.balance {
  display: flex;
  align-items: center;
  margin: 20px 0;
  justify-content: space-between;

  p {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }
}

.details {
  text-align: center;

  p {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #cb5540;
    margin: 0;
    cursor: pointer;
  }
}

.box-dark {
  margin: 1rem;
}

.p-confirm-dialog-message {
  white-space: pre-line;
}
</style>
