<template>
  <div>
    <section
      class="row w-100 d-flex justify-content-center align-items-center height-center"
    >
      <div class="col-12 mt-5 steps-title">
        <h1>8 passos para liberdade financeira</h1>
      </div>

      <div
        class="col-lg-6 container-cards d-flex justify-content-center align-items-center flex-column"
      >
        <div class="box-cards">
          <aside class="cards card-1">
            <h1>Educação Financeira</h1>
            <div class="steps-card">
              <div class="step">
                <span>1</span>
              </div>
              <h3>Poupar eficiente</h3>
            </div>
            <div class="steps-card mb-0">
              <div class="step">
                <span>2</span>
              </div>
              <h3>Gestão Financeira</h3>
            </div>
          </aside>

          <aside class="cards card-2">
            <h1>Proteção de Capital</h1>
            <div class="steps-card">
              <div class="step">
                <span>3</span>
              </div>
              <h3>Gestão de risco</h3>
            </div>
            <div class="steps-card mb-0">
              <div class="step">
                <span>4</span>
              </div>
              <h3>Planejamento da renda futura</h3>
            </div>
          </aside>

          <aside class="cards card-3">
            <h1>Acúmulo de Capital</h1>
            <div class="steps-card">
              <div class="step">
                <span>5</span>
              </div>
              <h3>Reserva de emergência</h3>
            </div>
            <div class="steps-card mb-0">
              <div class="step">
                <span>6</span>
              </div>
              <h3>Prioridades e objetivos</h3>
            </div>
          </aside>

          <aside class="cards card-4">
            <h1>Expansão de Capital</h1>
            <div class="steps-card">
              <div class="step">
                <span>7</span>
              </div>
              <h3>Gestão de ativos</h3>
            </div>
            <div class="steps-card mb-0">
              <div class="step">
                <span>8</span>
              </div>
              <h3>Planejamento tributário e sucessório</h3>
            </div>
          </aside>
        </div>
        <div class="markers">
          <div class="marker-1">
            <div></div>
            <span>Não está sendo feito</span>
          </div>
          <div class="marker-2">
            <div></div>
            <span>Já está fazendo</span>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div
          class="w-100 d-flex align-items-center justify-content-center position-relative steps-img"
        >
          <img class="w-100" src="@/assets/steps.png" alt="passos" />
        </div>
      </div>
    </section>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  color: #fff;
}

.steps-title {
  h1 {
    font-size: 3em;
  }
}

.container-cards,
.steps-img {
  margin-bottom: 5rem;
  @media (max-width: 992px) {
    margin-bottom: 3rem;
  }
}

.container-cards {
  .box-cards {
    gap: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    .card-1 {
      background: linear-gradient(90deg, rgb(65 122 201), rgb(39 93 129));
      border: 3px solid;
      transition: all ease-in 0.3s;

      &:hover {
        border: 3px solid #a2bfe0;
        cursor: pointer;
      }
    }

    .card-2 {
      background: linear-gradient(90deg, rgb(255 137 69), rgb(254 104 10));
      border: 3px solid;
      transition: all ease-in 0.3s;

      &:hover {
        border: 3px solid #ffaf80;
        cursor: pointer;
      }
    }

    .card-3 {
      background: linear-gradient(90deg, rgb(25 39 68), rgb(22 70 119));
      border: 3px solid;
      transition: all ease-in 0.3s;

      &:hover {
        border: 3px solid #9cbffb;
        cursor: pointer;
      }
    }

    .card-4 {
      background: linear-gradient(90deg, rgb(231 86 39), rgb(254 123 41));
      border: 3px solid;
      transition: all ease-in 0.3s;

      &:hover {
        border: 3px solid #f6c6aa;
        cursor: pointer;
      }
    }

    .cards {
      width: calc(98% / 2);
      min-width: 237px;
      // max-width: 350px;
      padding: 1.45em;
      border-radius: 20px;

      @media (max-width: 600px) {
        width: 98%;
      }

      h1 {
        font-size: clamp(1.65em, 1.85em + 3vw, 2em);
        font-weight: bold;
        margin-bottom: 2rem;
      }

      .steps-card {
        display: flex;
        align-items: center;
        margin: 0 1rem 1.5rem 0;

        .step {
          width: 40px;
          height: 40px;
          min-width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid #fff;
          border-radius: 100%;
          background-color: #000;
          text-align: center;
          font-size: 1.25em;
          font-weight: bold;
          margin-right: 1rem;
        }

        h3 {
          font-size: clamp(1em, 1.125em + 3vw, 1.25em);
          font-weight: bold;
          margin: 0;
        }
      }
    }
  }

  .markers {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }

  .marker-1,
  .marker-2 {
    display: flex;
    align-items: center;
    div {
      width: 25px;
      height: 25px;
      border-radius: 5px;
      margin-right: 1rem;
    }

    span {
      font-size: 1em;
    }
  }

  .marker-1 {
    margin-right: 1.5rem;
    div {
      background-color: rgb(65 122 201);
    }
  }

  .marker-2 {
    div {
      background-color: rgb(255 137 69);
    }
  }
}

.height-center {
  height: 100%;
  @media (max-width: 1670px) {
    height: auto;
  }
}
</style>
