<template>
  <div class="nav-pagination">
    <!-- Botão de negação para voltar ao ano anterior -->
    <div class="nav-prev" @click="navigate(-1)">
      <i class="fas fa-chevron-left" />
    </div>
    <div ref="btnOptionsWrapper" class="btn-options-wrapper-year">
      <div ref="btnOptions" class="btn-options-year">
        <div v-for="year in anosExibidos" :key="year" @click="select(year)">
          <div
            class="year-box"
            :class="{ 'selected-year': anoSelecionado == year }"
          >
            <h2>{{ year }}</h2>
          </div>
        </div>
      </div>
    </div>

    <!-- Botão de negação para avançar ao próximo ano -->
    <div class="nav-next" @click="navigate(1)">
      <i class="fas fa-chevron-right" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      anoSelecionado: new Date().getFullYear(),
    };
  },
  computed: {
    anosExibidos() {
      const currentYear = new Date().getFullYear();
      const anos = Array.from(
        { length: 11 },
        (_, index) => currentYear - 5 + index
      );

      const selectedIndex = anos.findIndex(
        (year) => year === this.anoSelecionado
      );
      const startIndex = Math.max(0, selectedIndex - 3);
      const endIndex = Math.min(anos.length - 1, selectedIndex + 3);

      return anos.slice(startIndex, endIndex + 1);
    },
  },
  watch: {
    anoSelecionado() {
      this.$emit("selectedYear", this.anoSelecionado);
    },
  },
  methods: {
    select(value) {
      this.anoSelecionado = value;
      this.centerSelectedItem();
    },
    navigate(step) {
      const currentIndex = this.anosExibidos.findIndex(
        (year) => year === this.anoSelecionado
      );
      if (currentIndex !== -1) {
        const nextIndex = currentIndex + step;
        if (nextIndex >= 0 && nextIndex < this.anosExibidos.length) {
          this.anoSelecionado = this.anosExibidos[nextIndex];
          this.$emit("selectedYear", this.anoSelecionado);
          this.centerSelectedItem();
        }
      }
    },
    centerSelectedItem() {
      // Obtém o elemento do item selecionado
      const selectedItem =
        this.$refs.btnOptions.querySelector(".selected-year");
      if (selectedItem) {
        // Calcula a posição do item selecionado em relação à navegação
        const wrapperWidth = this.$refs.btnOptionsWrapper.clientWidth;
        const itemLeft = selectedItem.offsetLeft;
        const itemWidth = selectedItem.offsetWidth;
        const itemCenter = itemLeft + itemWidth / 2;

        // Calcula a posição de rolagem para centralizar o item selecionado
        const scrollLeft = itemCenter - wrapperWidth / 2;
        this.$refs.btnOptionsWrapper.scrollTo({
          left: scrollLeft,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  .nav-prev,
  .nav-next {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    color: #fff;
    background-color: #cb5540;
    border-radius: 50%;
    cursor: pointer;
    user-select: none;

    i {
      font-size: 12px;
    }
  }

  .nav-prev:hover,
  .nav-next:hover {
    font-weight: bold;
  }

  .nav-prev {
    margin-left: 10px;
  }

  .nav-next {
    margin-right: 10px;
  }
}

.btn-options-wrapper-year {
  overflow: hidden; /* Adiciona uma barra de rolagem horizontal */
}

.btn-options-year {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: #fff;

  h2 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
    cursor: pointer;
    user-select: none;
  }

  h2:hover {
    font-weight: bold;
  }
}

.year-box {
  padding: 10px 20px;
  margin: 0 10px 0 0;
}

.selected-year {
  background: #cb5540;
  border-radius: 4px;
  text-align: center;
  h2 {
    font-weight: bold;
  }
}
</style>
