<template>
  <Toast />
  <div v-if="!isDeleting" class="form-default main-content">
    <div>
      <p v-if="successMessage" class="success-message">
        {{ successMessage }}
      </p>
      <p v-else-if="errorMessage" class="error-message">
        {{ errorMessage }}
      </p>
    </div>
    <p>Descrição</p>
    <input
      v-if="isEditing"
      id="description"
      v-model="transaction.description"
      type="text"
      name="description"
      class="form-control"
      placeholder=""
    />
    <h3 v-else>
      {{ transaction.description }}
    </h3>
    <div v-if="isEditing">
      <p class="mt-3">Categoria</p>
      <select id="type" v-model="transaction.id_transaction_category">
        <option
          v-for="(type, index) in filteredCategories"
          :key="index"
          :value="type.id"
        >
          {{ type.name }}
        </option>
      </select>
      <p class="">Tipo de gasto</p>
      <select id="transactionType" name="transactionType" v-model="transaction.id_transaction_type">
        <option
          v-for="(type, index) in filteredTypes"
          :key="index"
          :value="type.id"
        >
          {{ type.name }}
        </option>
      </select>
    </div>

    <div v-else>
      <p>Categoria</p>
      <h3 v-if="transaction.id_transaction_category_data">
        {{ transaction.id_transaction_category_data.name }}
      </h3>
      <p>Tipo de gasto</p>
      <h3 v-if="transaction.id_transaction_type_data">
        {{ transaction.id_transaction_type_data.name }}
      </h3>
    </div>
    <p class="">Valor</p>
    <input
      v-if="isEditing && transaction.repeat_type != 'installment'"
      id="amount"
      v-model.lazy="transaction.amount"
      v-money="moneyConfig"
      name="amount"
      class="form-control"
      placeholder=""
      required
    />
    <h3 v-else>
      {{ formatValue(transaction.amount) }}
    </h3>
    <div class="separator" />
    <div class="row">
      <div class="col-lg mb-2">
        <p>Data</p>
        <h3 v-if="!isEditing">
          {{ formatDate(transaction.transaction_at) }}
        </h3>
        <div v-else class="col">
          <input
            id="transaction_at"
            v-model="transaction.transaction_at"
            type="date"
            name="transaction_at"
            class="form-control"
            placeholder="dd/mm/yyyy"
            onkeydown="return false"
            onclick="this.showPicker()"
            required
          />
        </div>
      </div>
    </div>
    <div>
      <p>Conta:</p>
      <h3 v-if="!isEditing && transaction.id_bank_account_data">
        {{ transaction.id_bank_account_data.name }}
      </h3>
      <div v-else class="col-lg mb-2">
        <Dropdown
          v-model="transaction.id_bank_account"
          :options="filteredAccounts"
          class="custom-dropdown"
          option-label="name"
          option-value="id"
          placeholder="Conta ou cartão"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg">
        <p>Fonte</p>
        <select
          v-if="
            isEditing &&
            transaction.id_bank_account_data.id_account_type != 4 &&
            transaction.id_transaction_category_data.id_transaction_type != 4
          "
          id="type"
          v-model="transaction.id_transaction_method"
        >
          <option
            v-for="(type, index) in transactionMethod"
            :key="index"
            :value="type.id"
          >
            {{ type.name }}
          </option>
        </select>
        <h3 v-else-if="transaction.id_transaction_method_data">
          {{ transaction.id_transaction_method_data.name }}
        </h3>
        <h3 v-else>-</h3>
      </div>
      <div class="col-lg">
        <p>Tipo</p>
        <h3>
          {{ getTransactionType(transaction.type) }}
        </h3>
      </div>
    </div>
    <div class="row" v-if="transaction.repeat_type == 'installment'">
      <div class="col-lg">
        <p>Total de parcelas</p>
        <h3>
          {{ transaction.repeat_number ? transaction.repeat_number : "-" }}
        </h3>
      </div>
      <div class="col-lg">
        <p>Parcela atual</p>
        <h3>{{ transaction.installment_number }}</h3>
      </div>
    </div>
    <div v-if="transaction.repeat_type == null" class="row mt-3">
      <div class="row mt-3">
        <div v-if="repeatData.$repeat" class="col">
          <label>Como irá se repetir?</label>
          <Dropdown
            v-model="repeatData.$repeat_type"
            :options="repeatType"
            class="custom-dropdown"
            option-label="text"
            option-value="value"
            placeholder="Selecione uma opção"
          />
        </div>
      </div>
      <div v-if="repeatData.$repeat">
        <div v-if="repeatData.$repeat_type == 'recurring'" class="mt-3">
          <div class="col-auto switch mt-1">
            <label>Por prazo determinado?</label>
            <div class="form-check form-switch">
              <input
                id="customSwitch"
                v-model="repeatData.$repeat_has_end_date"
                class="form-check-input"
                type="checkbox"
              />
            </div>
          </div>
          <div class="row mt-3">
            <div v-if="repeatData.$repeat_has_end_date == false" class="col">
              <label>Num. ocorrências</label>
              <input
                v-model="repeatData.$repeat_number"
                type="number"
                min="2"
                class="form-control form-control"
              />
            </div>
            <div v-if="repeatData.$repeat_has_end_date == true" class="col">
              <label>Data de Término</label>
              <input
                v-model="repeatData.$repeat_end_date"
                type="date"
                :min="repeatData.repeatData_at"
                class="form-control form-control"
                onkeydown="return false"
                onclick="this.showPicker()"
              />
            </div>
            <div class="col">
              <label>Período</label>
              <Dropdown
                v-model="repeatData.$repeat_interval"
                :options="interval"
                class="custom-dropdown"
                option-label="text"
                option-value="value"
                placeholder="Selecione uma opção"
              />
            </div>
          </div>

          <div class="row" />
        </div>

        <div v-else>
          <div class="row">
            <div class="col">
              <label>Num. Ocorrências</label>
              <input
                v-model="repeatData.$repeat_number"
                type="number"
                min="2"
                class="form-control form-control"
              />
            </div>
            <div class="col">
              <label>Período</label>
              <Dropdown
                v-model="repeatData.$repeat_interval"
                :options="interval"
                class="custom-dropdown"
                option-label="text"
                option-value="value"
                placeholder="Selecione uma opção"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="
          transaction.type == 'credit' &&
          repeatData.$repeat == true &&
          transaction.amount != null
        "
        class="row calc-credit"
      >
        <div v-if="repeatData.$repeat_type == 'recurring'">
          <h3>
            Sua <span>Receita</span> será repetida em
            <span>{{ payloadRepeat.$repeat_number }}x</span>
          </h3>
        </div>
        <div v-else>
          <h3>
            Sua <span>Receita</span> será parcelada em
            <span>{{ payloadRepeat.$repeat_number }}x</span> de
            <span> {{ formatValue(amountInstallment) }}</span>
          </h3>
        </div>
      </div>
    </div>
    <div
      v-if="
        transaction.type == 'debit' &&
        repeatData.$repeat == true &&
        transaction.amount != null
      "
      class="row calc-debit"
    >
      <div v-if="repeatData.$repeat_type == 'recurring'">
        <h3>
          Sua <span>Despesa</span> será repetida em
          <span>{{ payloadRepeat.$repeat_number }}x</span>
        </h3>
      </div>
      <div v-else>
        <h3>
          Sua <span>Despesa</span> será parcelada em
          <span>{{ payloadRepeat.$repeat_number }}x</span> de
          <span> {{ formatValue(amountInstallment) }}</span>
        </h3>
      </div>
    </div>
    <div class="separator" />
    <div class="row">
      <div class="col-lg">
        <button
          v-if="!isEditing"
          @click="
            () => {
              isDeleting = true;
            }
          "
        >
          <i class="fa-solid fa-trash" />
          Excluir
        </button>
        <button v-if="isEditing" @click="editeTransaction">
          <i class="fa-solid fa-xmark" />
          Cancelar edição
        </button>
      </div>
      <div class="col-lg">
        <button v-if="!isEditing" @click="editeTransaction">
          <i class="fa-solid fa-pen" />
          Editar
        </button>
        <button v-if="isEditing" @click="sendEditTransaction">
          <i class="fa-solid fa-pen" />
          Editar
        </button>
      </div>
    </div>
  </div>

  <div v-else>
    <div v-if="isDeleting" class="form-default main-content">
      <p 
        v-if="this.transaction.repeat_type == 'recurring'" 
        class="p-3 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-3"
      >
        Ao confirmar, as recorrências seguintes desse lançamento também serão excluídas.
      </p>
      <div class="separator" />
      <div class="row">
        <div class="col-lg">
          <button v-if="!isEditing" @click="deleteTransaction">
            <i class="fa-solid fa-trash" />
            Confirmar
          </button>
          <button
            class="mt-2"
            v-if="!isEditing"
            @click="() => (isDeleting = false)"
          >
            <i class="fa-solid fa-xmark" />
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useAppStore } from "@/store/store.js";
import ImageContainer from "@/components/shared/ImageContainer.vue";
import shared from "@/shared";
import { format, unformat } from "v-money3";
import { useConfirm } from 'primevue/useconfirm';
import * as luxon from "luxon";
import { useToast } from "primevue/usetoast";

export default {
  components: { ImageContainer },
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  emits: ["close", "reload"],
  data() {
    return {
      selected: "current",
      isEditing: false,
      isDeleting: false,
      successMessage: "",
      errorMessage: "",
      types: ["debit", "credit"],
      fieldNames: {},
      requiredFields: [],
      moneyConfig: shared.moneyConfig(),
      toast: useToast(),
      confirm: useConfirm(),
      repeat_edit_type: "current",
      transaction: {
        ...this.record,
        amount: format(this.record.amount, this.moneyConfig),
        transaction_at: luxon.DateTime.fromISO(this.record.transaction_at).toUTC().toFormat("yyyy-MM-dd"),
      },
      repeatData: {
        $repeat: false,
        $repeat_has_end_date: false,
        $repeat_end_date: luxon.DateTime.now().toFormat("yyyy-MM-dd"),
        $repeat_interval: "week",
        $repeat_number: 2,
        $repeat_type: "recurring",
      },
      interval: [
        { value: "week", text: "Semana" },
        { value: "month", text: "Mês" },
        { value: "year", text: "Ano" },
      ],
      repeatType: [
        { value: "recurring", text: "Recorrente" },
        { value: "installment", text: "Parcelado" },
      ],
    };
  },
  computed: {
    getCategoryType() {
      const cat = this.transaction.id_transaction_category;
      const type = this.categories.find((category) => category.id == cat);
      if (!type) {
        return 0;
      }
      return type.id_transaction_type;
    },
    filteredAccounts() {
      if (
        this.transaction.id_transaction_method === 1 ||
        this.transaction.id_transaction_method === 2 ||
        this.transaction.id_transaction_method === 4 ||
        this.transaction.id_transaction_method === 5
      ) {
        return this.accounts?.filter(
          (account) =>
            account.id_account_type == "1" || account.id_account_type == "2"
        );
      } else if (this.transaction.id_transaction_method === 3) {
        return this.accounts?.filter(
          (account) => account.id_account_type == "4"
        );
      } else {
        return this.accounts?.filter(
          (account) => account.id_account_type != "3"
        );
      }
    },
    filteredCategories() {
      if (this.transaction.type == "credit") {
        return this.categories.filter(
          (category) => category.id_transaction_type_data.id == 4
        );
      } else {
        if (
          this.transaction.id_bank_account_data.id_account_type == 4 ||
          this.transaction.id_bank_account_data.id_account_type == 1 ||
          this.transaction.id_bank_account_data.id_account_type == 2
        ) {
          return this.categories.filter(
            (category) => category.id_transaction_type_data.id == 5
          );
        }
      }
      return this.categories;
    },
    filteredTypes() {
      const store = useAppStore();
      const transactionTypeResponse = store.transactionTypeResponse;
      const t = transactionTypeResponse?.filter((item) =>
        [1, 2].includes(item.id)
      );

      return t;
    },
    calculateDiff() {
      const isoFormattedInitialDate = this.transaction.transaction_at.replace(
        " ",
        "T"
      );
      const initialDate = luxon.DateTime.fromISO(isoFormattedInitialDate);
      const endDate = luxon.DateTime.fromISO(this.repeatData.$repeat_end_date);
      const diff = {
        day: Math.floor(endDate.diff(initialDate, "days").days),
        week: Math.floor(endDate.diff(initialDate, "weeks").weeks),
        month: Math.floor(endDate.diff(initialDate, "months").months),
        year: Math.floor(endDate.diff(initialDate, "years").years),
      };
      return diff;
    },
    categories() {
      const store = useAppStore();
      const categories = store.categoriesResponse;
      return categories;
    },
    accounts() {
      const store = useAppStore();
      const accounts = store.accountsResponse;
      return accounts;
    },
    amountInstallment() {
      let amount = 0;
      amount = this.payload.amount / this.repeatData.$repeat_number;
      return amount;
    },
    transactionMethod() {
      const store = useAppStore();
      let transactionMethodResponse = store.transactionMethodResponse;
      if (
        this.transaction &&
        this.transaction.id_bank_account_data &&
        this.transaction.id_bank_account_data.id_account_type == 4
      ) {
        transactionMethodResponse = transactionMethodResponse.filter(
          (method) => method.id == 3
        );
      } else {
        transactionMethodResponse = transactionMethodResponse.filter((method) =>
          [1, 2, 4, 5].includes(method.id)
        );
      }
      return transactionMethodResponse;
    },
    payloadRepeat() {
      const unformattedAmount = unformat(
        this.transaction.amount,
        this.moneyConfig
      );
      return {
        amount: unformattedAmount,
        description: this.transaction.description,
        id_parent_transaction: this.transaction.id_parent_transaction,
        id_bank_account: this.transaction.id_bank_account,
        id_transaction_category: this.transaction.id_transaction_category,
        id_transaction_method: this.transaction.id_transaction_method,
        id_transaction_type: this.transaction.id_transaction_type,
        transaction_at: this.transaction.transaction_at,
        type: this.transaction.type,
        $repeat: this.repeatData.$repeat,
        $repeat_interval: this.repeatData.$repeat_interval,
        $repeat_number:
          this.repeatData.$repeat_has_end_date &&
          this.repeatData.$repeat_type == "recurring"
            ? this.calculateDiff[this.repeatData.$repeat_interval]
            : this.repeatData.$repeat_number,
        $repeat_type: this.repeatData.$repeat_type,
      };
    },
    transactionType() {
      const store = useAppStore();
      const transactionTypeResponse = store.transactionTypeResponse;
      return transactionTypeResponse;
    },
    token() {
      const appStore = useAppStore();
      return appStore.token;
    },
    payload() {
      return {
        description: this.transaction.description,
        transaction_at: this.transaction.transaction_at,
        amount: this.transaction.amount,
        type: this.transaction.type,
        id_bank_account: this.transaction.id_bank_account,
        id_transaction_category: this.transaction.id_transaction_category,
        id_transaction_method: this.transaction.id_transaction_method,
        id_transaction_type: this.transaction.id_transaction_type,
        $repeat_edit_type: this.repeat_edit_type,
        $repeat_type:this.transaction.repeat_type,
      };
    },
  },
  watch: {},
  created() {
    //this.selected = this.quick;
  },
  mounted() {},
  methods: {
    getFormattedDate(valor) {
      const dateAndTime = valor.split(" ");
      const dateStr = dateAndTime[0].split("-");
      return dateStr[2] + "/" + dateStr[1] + "/" + dateStr[0];
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Os meses começam do 0 em JavaScript
      const day = String(date.getDate()).padStart(2, "0");
      return `${day}/${month}/${year}`;
    },
    duplicateTransaction() {
      if (typeof this.transaction.amount === "string") {
        this.transaction.amount = unformat(this.transaction.amount);
      }
      const payload = {
        description: `${this.transaction.description} (2)`,
        transaction_at: this.transaction.transaction_at,
        amount: this.transaction.amount,
        type: this.transaction.type,
        id_bank_account: this.transaction.id_bank_account,
        id_transaction_category: this.transaction.id_transaction_category,
        id_transaction_method: this.transaction.id_transaction_method,
        id_transaction_type: this.transaction.id_transaction_type,
      };
      axios
        .post(`/transaction`, payload, {
          headers: { Authorization: `Bearer ${this.token}` },
        })
        .then((response) => {
          this.successMessage = "Transação duplicada com sucesso!";
          this.$emit("reload");
          const store = useAppStore();
          store.updateData();
          setTimeout(() => {
            this.closeModal();
          }, 1500);
        })
        .catch((error) => {
          console.error("Erro ao duplicar transação:", error);
          this.errorMessage =
            "Erro ao duplicar transação. Por favor, tente novamente.";
        });
    },
    editeTransaction() {
      this.isEditing = !this.isEditing;
    },
    async confirmRepeatEditType() {
      return new Promise((resolve) => {
        this.confirm.require({
          message: 'Você deseja aplicar esta edição para os lançamentos futuros?',
          header: 'Confirmação',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Sim',
          rejectLabel: 'Não',
          accept: () => {
            resolve(true);
          },
          reject: () => {
            resolve(false);
          }
        });
      });
    },
    editRepeat() {
      const fieldNames = {
        amount: "Valor",
        transaction_at: "Data da transação",
        id_bank_account: "Conta bancária",
        $repeat_interval: "Período",
        $repeat_number: "Num. parcelas",
        $repeat_type: "Tipo de repetição",
      };
      if (this.transaction.$repeat == true) {
        this.requiredFields = [
          "amount",
          "transaction_at",
          "id_bank_account",
          "$repeat_interval",
          "$repeat_number",
          "$repeat_type",
        ];
      } else {
        this.requiredFields = ["amount", "transaction_at", "id_bank_account"];
      }
      const missingFields = [];
      for (const field of this.requiredFields) {
        if (!this.transaction[field]) {
          missingFields.push(fieldNames[field]);
        }
      }
      if (missingFields.length > 0) {
        const missingFieldsString = missingFields.join(", ");
        this.errorMessage = `Os seguintes campos são obrigatórios: ${missingFieldsString}.`;
        return;
      }
      axios
        .post(`/transaction_custom`, this.payloadRepeat, {
          headers: { Authorization: `Bearer ${this.token}` },
        })
        .then((response) => {
          this.successMessage = "Transação salva com sucesso!";
          this.$emit("reload");
          const store = useAppStore();
          store.updateData();
          setTimeout(() => {
            this.closeModal();
          }, 1500);
        })
        .catch((error) => {
          console.error("Erro ao salvar transação:", error);
          this.transaction.amount = null;
          this.errorMessage =
            "Erro ao salvar transação. Por favor, tente novamente.";
        });
    },
    async sendEditTransaction() {
      if (this.repeatData.$repeat) {
        this.editRepeat();
        return;
      }
      if(this.transaction.repeat_type == 'recurring'){
        var userConfirmed = await this.confirmRepeatEditType();
        this.repeat_edit_type = userConfirmed ? 'future' : 'current';
        this.repeatData.$repeat = userConfirmed;
      }
      this.payload.amount = unformat(this.payload.amount, this.moneyConfig);
      axios
        .put(`/transaction_custom/${this.transaction.id}`, this.payload, {
          headers: { Authorization: `Bearer ${this.token}` },
        })
        .then((response) => {
          this.successMessage = "Transação editada com sucesso!";
          this.$emit("reload");
          const store = useAppStore();
          store.updateData();
          this.toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: `Transação editada com sucesso!`,
            life: 3000,
          });
          setTimeout(() => {
            this.closeModal();
          }, 1500);
        })
        .catch((error) => {
          console.error("Erro ao editar transação:", error);
          this.payload.amount = null;
          this.errorMessage =
            "Erro ao editar transação. Por favor, tente novamente.";
        });
    },
    async deleteTransaction() {
      try {
        await axios.delete(
          `/transaction_custom/${this.transaction.id}/${this.repeat_edit_type}`,
          {
            headers: { Authorization: `Bearer ${this.token}` },
          }
        );
        this.toast.add({
          severity: "success",
          summary: "Sucesso",
          detail: `Transação excluída com sucesso!`,
          life: 3000,
        });
        const store = useAppStore();
        store.updateData();
        this.$emit("reload");
        setTimeout(() => {
          this.closeModal();
        }, 1500);
        this.successMessage = "Transação excluida com sucesso!";
      } catch (error) {
        console.error("Erro ao excluir a transação:", error);
        this.errorMessage =
          "Erro ao excluir transação. Por favor, tente novamente.";
      }
    },
    getTransactionType(valor) {
      const appStore = useAppStore();
      return appStore.getTransactionType(valor);
    },
    select(value) {
      this.selected = value;
    },
    formatValue(valor) {
      const appStore = useAppStore();
      return appStore.formatValue(valor);
    },
    toggleReload(valor) {
      const appStore = useAppStore();
      return appStore.toggleReload();
    },
    validateForm(requiredFields) {
      this.errorMessage = "";
      for (const field of requiredFields) {
        if (!this.goal[field]) {
          this.errorMessage = `Por favor, preencha o campo '${field}'.`;
          return false;
        }
      }
      return true;
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  border: 1px solid #e54f00;
  background-color: transparent;
  color: #e54f00;
  padding: 10px;
  width: 100%;
  border-radius: 5px;

  i {
    margin-right: 5px;
  }
}

h3 {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

p {
  font-size: 14px;
  font-weight: 300;
  color: #7c87a7;
  margin: 0;
}

.img-container {
  display: flex;
  margin-bottom: 10px;
}

.main-content {
  text-align: left;
}

.calc-credit {
  margin: 20px;
  text-align: center;

  h3 {
    color: #fff;
    font-size: 16px;
  }

  span {
    color: rgb(0, 255, 13);
    font-weight: bold;
  }
}

.calc-debit {
  margin: 20px;
  text-align: center;

  h3 {
    color: #fff;
    font-size: 16px;
  }

  span {
    color: red;
    font-weight: bold;
  }
}
</style>
