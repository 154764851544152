<template>
  <div class="image-container">
    <div v-if="isImageUrl">
      <img :src="'/logos_bancos/' + this.source" class="img_banco" :class="container_img">
    </div>
    <div v-else>
      <i class="image-container" :class="iconClass" :style="{ borderRadius: '50%', backgroundColor: colorValue }" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    source: {
      type: String,
    },
    color: {
      type: String,
      default: '#d81313'
    },
    icon: {
      type: String,
      default: "fa-regular fa-image"
    },
  },
  computed: {
    isImageUrl() {
      //return /^http(s)?:\/\//.test(this.source);        
      return /\.(jpe?g|png|gif|bmp)$/i.test(this.source);
    },
    iconClass() {
      return this.icon || "icon fa-regular fa-image";
    }, 
    colorValue() {
      return this.color || "#ccc";
    }
  },
};
</script>

<style lang="scss">
.image-container_img {
  width: 40px;
  height: 40px;
  display: flex;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  background-color: #fff;
  padding: 3px;


  i {
    font-size: 20px;
  }

}

.image-container {
  width: 40px;
  height: 40px;
  display: flex;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  // background-color: #ccc;
  padding: 3px;


  i {
    font-size: 20px;
  }

}

/* Estilos padrão para os ícones */
.image-container span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  width: 100%;
  height: 100%;
}

.img_banco {
  border-radius: 50%;
  width: 50px;
  background-color: #fff !important;
}
</style>