<template>
  <div class="form-default main-content">
    <div>
      <p v-if="successMessage" class="success-message">
        {{ successMessage }}
      </p>

      <p v-else-if="errorMessage" class="error-message">
        {{ errorMessage }}
      </p>
    </div>
    <div class="separator" />
    <label class="mt-3">Banco</label>
    <select id="type" v-model="payload.id_bank">
      <option v-for="(type, index) in banks" :key="index" :value="type.id">
        {{ type.name }}
      </option>
    </select>
    <div>
      <label>Nome da conta</label>
      <input
        id="description"
        v-model="payload.name"
        type="text"
        name="description"
        class="form-control"
        placeholder=""
      />
    </div>

    <div v-if="account.id_account_type == 3" class="mt-3">
      <label>Saldo atual</label>
      <input
        id="amount"
        v-model="payload.amount"
        v-money="moneyConfig"
        type="text"
        name="balance"
        class="form-control"
        placeholder=""
      />
    </div>

    <div
      class="row mt-3"
      v-if="account.id_account_type != 4 && payload.id_bank != 20"
    >
      <div class="col-md">
        <label>Agência</label>
        <input
          id="description"
          v-model="payload.account_agency"
          type="number"
          name="description"
          class="form-control"
          placeholder=""
        />
      </div>
      <div class="col-md">
        <label>Conta</label>
        <input
          id="description"
          v-model="payload.account_number"
          type="number"
          name="description"
          class="form-control"
          placeholder=""
        />
      </div>
    </div>

    <div v-if="account.id_account_type == 4">
      <div class="row">
        <div class="col-md">
          <label>limite</label>
          <input
            id="limit"
            v-model.number="payload.limit"
            v-money="moneyConfig"
            class="form-control"
            placeholder=""
          />
        </div>
        <div class="col-md">
          <label>Dia de fechamento </label>
          <input
            id="description"
            v-model="payload.closing_day"
            type="number"
            name="description"
            class="form-control"
            placeholder=""
          />
        </div>
      </div>
    </div>

    <div class="separator" />

    <button @click="sendEdit">Salvar</button>
  </div>
</template>

<script>
import axios from "axios";
import { useAppStore } from "@/store/store.js";
import ImageContainer from "@/components/shared/ImageContainer.vue";
import shared from "@/shared";
import { unformat, format } from "v-money3";

export default {
  components: { ImageContainer },
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selected: "current",
      isEditing: false,
      successMessage: "",
      errorMessage: "",
      moneyConfig: shared.moneyConfig(),
      errorMessage: "",
      modelModifiers: {
        number: true,
      },
      payload: {
        name: this.account.name,
        id_account_type: this.account.type,
        account_agency: this.account.account_agency,
        account_number: this.account.account_number,
        id_bank: this.account.id_bank,
        closing_day: this.account.closing_day,
        limit: format(this.account.limit),
        due_day: this.account.due_day,
        amount: format(this.account.balance),
      },
    };
  },
  computed: {
    token() {
      const appStore = useAppStore();
      return appStore.token;
    },
    banks() {
      const store = useAppStore();
      const banks = store.bankResponse;

      return banks;
    },
    isCardAccount() {
      return this.account.id_account_type == 4;
    },
    isInvestmentAccount() {
      return this.account.id_account_type == 3;
    },
  },
  watch: {},
  mounted() {},
  methods: {
    sendEdit() {
      if (this.account.id_account_type == 4) {
        if (typeof this.payload.limit === "string") {
          this.payload.limit = unformat(this.payload.limit, this.moneyConfig);
        }
      }
      if (this.account.id_account_type == 3) {
        if (typeof this.payload.amount === "string") {
          this.payload.amount = unformat(this.payload.amount, this.moneyConfig);
        }
      }

      const isFormValid = this.validate();
      if (!isFormValid) {
        return;
      }

      axios
        .patch(`/bank_account/${this.account.id}`, this.payload, {
          headers: { Authorization: `Bearer ${this.token}` },
        })
        .then((response) => {
          this.successMessage = "conta editada com sucesso!";

          this.reloadData();

          setTimeout(() => {
            this.closeModal();
            this.$emit("returnToAcc");
          }, 1500);
        })
        .catch((error) => {
          console.error("Erro ao editar conta:", error);
          this.errorMessage =
            "Erro ao editar conta. Por favor, tente novamente.";
        });
    },

    async reloadData() {
      const store = useAppStore();
      await store.getAccounts();
    },

    closeModal() {
      this.$emit("close");
    },

    validate() {
      if (!this.payload.name) {
        this.$toast.add({
          severity: "error",
          summary: "Desculpe mas não podemos continuar",
          detail: "Informe um nome",
          life: 5000,
        });
        return false;
      }
      if (
        !this.isCardAccount &&
        !this.payload.account_agency &&
        this.payload.id_bank != 20
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Desculpe mas não podemos continuar",
          detail: "Informe o número da agência",
          life: 5000,
        });
        return false;
      }
      if (
        !this.isCardAccount &&
        !this.payload.account_number &&
        this.payload.id_bank != 20
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Desculpe mas não podemos continuar",
          detail: "Informe o número da conta",
          life: 5000,
        });
        return false;
      }
      if (!this.payload.id_bank) {
        this.$toast.add({
          severity: "error",
          summary: "Desculpe mas não podemos continuar",
          detail: "Selecione o banco",
          life: 5000,
        });
        return false;
      }
      if (this.isCardAccount && !this.payload.limit) {
        this.$toast.add({
          severity: "error",
          summary: "Desculpe mas não podemos continuar",
          detail: "Informe o limite do cartão",
          life: 5000,
        });
        return false;
      }
      if (this.isCardAccount && !this.payload.closing_day) {
        this.$toast.add({
          severity: "error",
          summary: "Desculpe mas não podemos continuar",
          detail: "Informe um dia de fechamento",
          life: 5000,
        });
        return false;
      }
      if (
        this.isInvestmentAccount &&
        this.payload.amount < this.account.balance
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Desculpe mas não podemos continuar",
          detail:
            "Não é possível subtratir do saldo na conta de investimento. Realize um resgate de investimento",
          life: 5000,
        });
        return false;
      }
      return true;
    },
  },
};
</script>
<style lang="scss" scoped>
.box-flex {
  display: flex !important;
  align-items: center;
  margin-bottom: 10px;
}

h3 {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin: 0;
}

p {
  font-size: 14px;
  font-weight: 300;
  color: #7c87a7;
  margin: 0;
}
</style>
