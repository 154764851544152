<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <div class="box">
          <div class="header-account">
            <div class="header-dash">
              <i
                class="return-icon fa-solid fa-arrow-left"
                @click="returnToAcc"
              />
              <h1>Detalhes da conta</h1>
              <i class="fa-solid fa-circle-info" />
            </div>
            <div
              v-if="!loading"
              class="edit-btn"
              @click="openModalEdit(account)"
            >
              <i class="fa-solid fa-pen" />
            </div>
          </div>
          <div v-if="!loading">
            <div class="title-account">
              <ImageContainer
                :icon="null"
                :color="null"
              />
              <div>
                <h5>Nome da conta</h5>
                <h1>{{ account.name }}</h1>
              </div>
            </div>
            <div class="investment-value">
              <div v-if="account.id_account_type == '4'">
                <h2>Valor da Fatura</h2>
                <span>{{ formatValue(currentBill != null ? currentBill.debit : 0) }}</span>
              </div>
              <div v-else>
                <h2>Saldo</h2>
                <span>{{ formatValue(account != null ? account.balance : 0) }}</span>
              </div>
              <div class="logo">
                <img
                  class="logo-icon"
                  src="~@/assets/logo-icon.png"
                  alt="Logo do Dashboard"
                >
              </div>
            </div>

            <AccountDefaultDetail
              v-if="account.id_account_type == '1' || account.id_account_type == '2'"
              :account="account"
              :format-value="formatValue"
              @openModal="openModal"
            />
            <AccountCardDetail
              v-if="account.id_account_type == '4'"
              :account="account"
              :total-balance="totalBalance"
              :format-value="formatValue"
              @openModal="openModal"
            />
          </div>
          <div
            v-else
            class="loading-min"
          >
            <i class="fas fa-spinner fa-spin" />
          </div>
        </div>
      </div>

      <div
        v-if="account.id_account_type != '3'"
        class="col-md-6"
      >
        <div class="box">
          <div v-if="!loading">
            <div class="btn-options">
              <h2
                :class="{ selected: selected == 'installments' }"
                @click="select('installments')"
              >
                Parcelas
              </h2>
              <h2
                v-if="account.id_account_type == '4'"
                :class="{ selected: selected == 'bills' }"
                @click="select('bills')"
              >
                Faturas
              </h2>
            </div>

            <div
              v-if="selected == 'installments'"
              class="col-md-12 bills"
            >
              <div
                v-if="installments.length == 0"
                class="no-goals-message"
              >
                <p>Nenhuma parcela encontrada</p>
              </div>
              <div v-else>
                <div v-for="(item, index) in installments">
                  <div class="separator" />
                  <div class="title-bills">
                    <h2>{{ item.description }}</h2>

                    <h2>{{ formatValue(item.amount) }}</h2>
                  </div>
                  <div class="separator" />
                  <div
                    v-for="(i, index) in item.installments"
                    class="title-bills"
                  >
                    <h4>
                      {{ i.description }} - {{ i.installment_number }} /
                      {{ item.number_installments }}
                    </h4>
                    <h4>{{ formatValue(i.amount) }}</h4>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="selected == 'bills'"
              class="col-md-12 bills"
            >
              <div
                v-if="bills.length == 0"
                class="no-goals-message"
              >
                <p>Nenhuma fatura encontrada</p>
              </div>
              <div v-else>
                <div class="title-bills">
                  <h2>Mês</h2>
                  <h2>Status</h2>
                  <h2>Valor</h2>
                </div>

                <div class="separator" />

                <div
                  v-for="(item, index) in bills"
                  class="title-bills"
                >
                  <div class="bills-item">
                    <h4>{{ formatYearMonth(item.period) }}</h4>
                  </div>
                  <div class="bills-item">
                    <h4>
                      {{
                        bills_payment[item.period]
                          ? bills_payment[item.period].credit == item.debit
                            ? "PAGO"
                            : "-"
                          : "-"
                      }}
                    </h4>
                  </div>
                  <div class="bills-item">
                    <h4>{{ formatValue(item.debit) }}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="loading-min"
          >
            <i class="fas fa-spinner fa-spin" />
          </div>
        </div>
      </div>
    </div>
    <Modal
      id="ModalAccDetail"
      :title="` ${typeForm}`"
      :is-open="isModalOpen"
      @close="closeModal"
    >
      <FormBills
        v-if="typeForm == 'Pagar Fatura'"
        :format-year-month="formatYearMonth"
        :format-value="formatValue"
        :current-bill="currentBill"
        @close="closeModal"
        @refresh="getBills(account.id)"
      />
      <FormTransfer
        v-if="typeForm == 'Transferência'"
        :format-value="formatValue"
        @close="closeModal"
      />
      <FormUpload
        v-if="typeForm == 'Upload'"
        :account="account"
        @close="closeModal"
      />
    </Modal>
  </div>
</template>

<script>
import { useAppStore } from "@/store/store.js";
import ImageContainer from "@/components/shared/ImageContainer.vue";
import axios from "axios";
import Modal from "@/components/shared/Modal.vue";
import AccountCardDetail from "./AccountCardDetail.vue";
import AccountDefaultDetail from "./AccountDefaultDetail.vue";
import FormBills from "../transaction/FormBills.vue";
import FormTransfer from "../transaction/FormTransfer.vue";
import FormUpload from "../transaction/FormUpload.vue";

export default {
  components: {
    ImageContainer,
    AccountCardDetail,
    AccountDefaultDetail,
    Modal,
    FormTransfer,
    FormBills,
    FormUpload,
  },
  props: {
    account: Object,
  },
  data() {
    return {
      currentDate: new Date(),
      installments: [],
      bills: [],
      bills_payment: [],
      currentBill: [],
      isModalOpen: false,
      selected: "installments",
      typeForm: "",
      loading: true,
      totalBalance: "",
    };
  },
  computed: {
    token() {
      const store = useAppStore();
      const token = store.token;

      return token;
    },
  },
  created() {
    // this.getAccount(this.account.id);
    this.getInstallments(this.account.id);
    this.getBills(this.account.id);
  },
  methods: {
    openModal(value) {
      this.isModalOpen = true;
      this.typeForm = value;
    },
    openModalEdit(value) {
      this.$emit("editAccount", value);
    },
    closeModal() {
      this.isModalOpen = false;
    },
    formatValue(valor) {
      const appStore = useAppStore();
      return appStore.formatValue(valor);
    },
    returnToAcc() {
      this.$emit("returnToAcc");
    },
    async getInstallments(id) {
      try {
        const response = await axios.get(`/installments?id=${id}`, {
          headers: { Authorization: `Bearer ${this.token}` },
        });
        this.installments = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    formatYearMonth(input) {
      const year = input.slice(0, 4);
      const month = input.slice(4);

      const monthNames = ["JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"];

      const formattedMonth = monthNames[parseInt(month, 10) - 1];
      return `${formattedMonth} ${year}`;
    },
    async getBills(id) {
      this.loading = true;
      try {
        const response = await axios.get(`/credit_card_bill?id=${id}`, {
          headers: { Authorization: `Bearer ${this.token}` },
        });
        const response2 = await axios.get(`/credit_card_payment?id=${id}`, {
          headers: { Authorization: `Bearer ${this.token}` },
        });
        this.bills = response.data.data;
        this.currentBill = response.data.current_bill;
        this.totalBalance = response.data.total_balance;
        this.bills_payment = response2.data.data;
        this.loading = false;
      } catch (error) {
        console.error(error);
      }
    },

    select(value) {
      this.selected = value;
    },
  },
};
</script>
<style lang="scss" scoped>
.header-dash {
  h1 {
    margin-left: 0.5rem;
  }
}
.box {
  min-height: 350px;
}
.box-main {
  text-align: center;
}
.bills {
  h2 {
    color: #fff;
    font-size: 18px;
    margin: 0;
  }
  h3 {
    color: #fff;
    font-size: 15px;
    margin: 0;
  }
  h4 {
    color: #fff;
    font-size: 15px;
  }
}
.title-bills {
  display: flex;
  justify-content: space-between;

  .bills-item {
    display: flex;
    justify-content: flex-start;
    // border: 1px solid red;
  }
}

.title-account {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  h1 {
    color: #fff;
    font-size: 18px;
    margin: 0;
  }
}
h5 {
  color: #bdbaba;
  font-size: 12px;
  margin: 0;
  font-weight: 300;
}
.header-account {
  display: flex;
  justify-content: space-between;
}

.return-icon {
  cursor: pointer;
  user-select: none;

  &:hover {
    transform: scale(1.2);
  }
}

.edit-btn {
  display: flex;
  align-items: center;
  color: #fff;
  cursor: pointer;
  p {
    margin: 0;
  }
  i {
    margin-right: 5px;
  }
}
</style>
