<template>
  <!-- CATEGORIA  -->
  <Toast />
  <table v-if="tableType == 'category' && filteredCategoryGoalsDespesas.length === 0"
    class="table custom-table centralizar">
    <thead>
      <tr>
        <th colspan="3" style="text-align: center;">Nenhuma meta de categoria encontrada</th>
      </tr>
    </thead>
  </table>

  <table v-if="tableType == 'category' && filteredCategoryGoalsDespesas.length > 0"
    class="table custom-table centralizar table-fixed">
    <tbody>
      <tr v-for="(goal, index) in  filteredCategoryGoalsDespesas" :key="index" :class="index % 2 === 0 ? 'row-even' : 'row-odd'">
        <td>
          <div class="d-flex space-between flex-wrap mb-2">
              <strong>
                <span style="font-size:12px; word-break: break-all;">{{ goal.transaction_category_name }}</span>
              </strong>
              <div style="margin-left:auto">
                <small>
                  <i class="fa-solid fa-pen" style="margin-left: .5rem;" @click="emitEdit(goal)" />
                </small>
                <small>
                  <i class="fa-solid fa-trash delete-icon" style="margin-left: .5rem;" @click="showDeleteOptions(goal)" />
                </small>
              </div>
          </div>
          <div>
            <table style="width: 100%;"
              :data-tooltip="formatValue(Math.abs(goal.balance)) + ' de ' + formatValue(goal.goal_amount)">
              <tr>
                <td style="width: 80%;">
                  <div class="progress">
                    <div class="progress-bar" role="progressbar"
                      :style="{ width: progress(Math.abs(goal.balance), goal.goal_amount) + '%' }"
                      :aria-valuenow="Math.abs(goal.balance)" aria-valuemin="0" :aria-valuemax="goal.goal_amount">
                      <p class="progress-label"><strong>{{ progress(Math.abs(goal.balance), goal.goal_amount) + "%" }}</strong></p>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <!-- FORMAS DE PAGAMENTOS  -->

  <table v-if="tableType == 'payment' && methodsGoals.length === 0" class="table custom-table centralizar">
    <thead>
      <tr>
        <th colspan="3" style="text-align: center;">Nenhuma meta de forma de pagamento encontrada</th>
      </tr>
    </thead>
  </table>


  <table v-if="tableType == 'payment' && methodsGoals.length > 0" class=" table custom-table centralizar table-fixed">
    <tbody>
      <tr v-for="(goal, index) in methodsGoals" :key="index" :class="index % 2 === 0 ? 'row-even' : 'row-odd'">
        <td>
          <div class="d-flex space-between flex-wrap mb-2">
              <strong>
                <span style="font-size:12px; word-break: break-all;">{{ goal.transaction_method_name }}</span>
              </strong>
              <div style="margin-left:auto">
                <small>
                  <i class="fa-solid fa-pen" style="margin-left: .5rem;" @click="emitEdit(goal)" />
                </small>
                <small>
                  <i class="fa-solid fa-trash delete-icon" style="margin-left: .5rem;" @click="showDeleteOptions(goal)" />
                </small>
              </div>
          </div>
          <div>
            <table style="width: 100%;"
              :data-tooltip="formatValue(Math.abs(goal.balance)) + ' de ' + formatValue(goal.goal_amount)">
              <tr>
                <td style="width: 80%;">
                  <div class="progress">
                    <div class="progress-bar" role="progressbar"
                      :style="{ width: progress(Math.abs(goal.balance), goal.goal_amount) + '%' }"
                      :aria-valuenow="Math.abs(goal.balance)" aria-valuemin="0" :aria-valuemax="goal.goal_amount">
                      <p class="progress-label"><strong>{{ progress(Math.abs(goal.balance), goal.goal_amount) + "%" }}</strong></p>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <!-- TIPOS DE DEBITOS  -->

  <table v-if="tableType == 'debit' && expenseGoals.length === 0" class="table custom-table centralizar">
    <thead>
      <tr>
        <th colspan="3" style="text-align: center;">Nenhuma meta de gastos encontrada</th>
      </tr>
    </thead>
  </table>

  <table v-if="tableType == 'debit' && expenseGoals.length > 0" class="table custom-table centralizar table-fixed">
    <tbody>
      <tr v-for="(goal, index) in expenseGoals" :key="index" :class="index % 2 === 0 ? 'row-even' : 'row-odd'">
        <td>
          <div class="d-flex space-between flex-wrap mb-2">
              <strong>
                <span style="font-size:12px; word-break: break-all;">{{ goal.transaction_type }}</span>
              </strong>
              <div style="margin-left:auto">
                <small>
                  <i class="fa-solid fa-pen" style="margin-left: .5rem;" @click="emitEdit(goal)" />
                </small>
                <small>
                  <i class="fa-solid fa-trash delete-icon" style="margin-left: .5rem;" @click="showDeleteOptions(goal)" />
                </small>
              </div>
          </div>
          <div>
            <table style="width: 100%;"
              :data-tooltip="formatValue(Math.abs(goal.balance)) + ' de ' + formatValue(goal.goal_amount)">
              <tr>
                <td style="width: 80%;">
                  <div class="progress">
                    <div class="progress-bar" role="progressbar"
                      :style="{ width: progress(Math.abs(goal.balance), goal.goal_amount) + '%' }"
                      :aria-valuenow="Math.abs(goal.balance)" aria-valuemin="0" :aria-valuemax="goal.goal_amount">
                      <p class="progress-label"><strong>{{ progress(Math.abs(goal.balance), goal.goal_amount) + "%" }}</strong></p>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <!-- Investimentos  -->
  <table v-if="tableType == 'investiment' && filteredCategoryGoalsInvestment.length === 0"
    class="table custom-table centralizar">
    <thead>
      <tr>
        <th colspan="3" style="text-align: center;">Nenhuma meta de investimentos encontrada</th>
      </tr>
    </thead>
  </table>

  <table v-if="tableType == 'investiment' && filteredCategoryGoalsInvestment.length > 0" class="table custom-table centralizar table-fixed">
    <tbody>
      <tr v-for="(goal, index) in  filteredCategoryGoalsInvestment" :key="index" :class="index % 2 === 0 ? 'row-even' : 'row-odd'">
        <td>
          <div class="d-flex space-between flex-wrap mb-2">
              <strong>
                <span style="font-size:12px; word-break: break-all;">{{ goal.transaction_category_name }}</span>
              </strong>
              <div style="margin-left:auto">
                <small>
                  <i class="fa-solid fa-pen" style="margin-left: .5rem;" @click="emitEdit(goal)" />
                </small>
                <small>
                  <i class="fa-solid fa-trash delete-icon" style="margin-left: .5rem;" @click="showDeleteOptions(goal)" />
                </small>
              </div>
          </div>
          <div>
            <table style="width: 100%;"
              :data-tooltip="formatValue(Math.abs(goal.balance)) + ' de ' + formatValue(goal.goal_amount)">
              <tr>
                <td style="width: 80%;">
                  <div class="progress">
                    <div class="progress-bar" role="progressbar"
                      :style="{ width: progress(Math.abs(goal.balance), goal.goal_amount) + '%' }"
                      :aria-valuenow="Math.abs(goal.balance)" aria-valuemin="0" :aria-valuemax="goal.goal_amount">
                      <p class="progress-label"><strong>{{ progress(Math.abs(goal.balance), goal.goal_amount) + "%" }}</strong></p>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <Toast />

  <Modal :title="'Deletar Meta'" :is-open="isDeleteOptionsModalOpen" @close="closeModal">
    <div class="form-default main-content">
      <div>
        <div class="row">
          <div class="col">
            <label for="">Deseja deletar:</label>
            <select name="recurrence_type" v-model="recurrence_type">
              <option value="current">Apenas esse mês</option>
              <option value="repeat" v-if="goalIdToDelete.recurrence_type !== 'current'">Replicar para o restante do ano
              </option>
            </select>
          </div>
        </div>

      </div>

      <button @click="sendDeletar">Deletar</button>
    </div>

  </Modal>
</template>

<script>
import { useAppStore } from "@/store/store.js";
import axios from "axios";
import { useConfirm } from 'primevue/useconfirm';
import Modal from "@/components/shared/Modal.vue";
import { useToast } from "primevue/usetoast";


export default {
  components: {
    Modal,
  },
  props: {
    categoryGoals: {
      type: Array,
      required: true
    },
    methodsGoals: {
      type: Array,
      required: true
    },
    expenseGoals: {
      type: Array,
      required: true
    },
    balanceByCategory: Array,
    tableType: String
  },
  data() {
    return {

      toast: useToast(),
      selected: "category",
      isDeleteOptionsModalOpen: false,
      goalIdToDelete: {},
      recurrence_type: 'current'
    };
  },
  setup() {
    return { confirm: useConfirm() }
  },
  computed: {
    filteredGoals() {
      if (this.selected === "category") {
        return this.categoryGoals
      } else if (this.selected === "payment") {
        return this.methodsGoals
      } else if (this.selected === "debit") {
        return this.expenseGoals
      }

      return this.goals;
    },

    filteredCategoryGoalsDespesas() {
      return this.categoryGoals.filter(goal => [1, 2, 4, 5].includes(goal.id_transaction_type));
    },

    filteredCategoryGoalsInvestment() {
      return this.categoryGoals.filter(goal => [3].includes(goal.id_transaction_type));
    },

    token() {
      const store = useAppStore();
      const token = store.token;

      return token;
    },
  },
  methods: {
    async reloadData() {
      const store = useAppStore();
      await store.getGoalsBalance();
    },
    sendDeletar(){
      axios
        .delete(`/financial_goal/${this.goalIdToDelete.id}/${this.recurrence_type}`, {
          headers: { Authorization: `Bearer ${this.token}` }
        })
        .then(() => {

          this.$toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Meta deletada com sucesso!',
            life: 3000
          });
          setTimeout(() => {
            this.reloadData()
          }, 1000);
        })
        .catch(error => {
          console.error('Error deleting goal:', error.response);
          if (error.response.status == 400) {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Erro ao deletar. Por favor, tente novamente.',
              life: 5000
            });
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Houve um erro ao deletar a meta. Por favor, tente novamente.',
              life: 5000
            });
          }
        });
    },
    closeModal() {
      this.isDeleteOptionsModalOpen = false;
    },
    emitEdit(index) {
      this.$emit("open", index);
    },

    emitDelete(index) {
      this.$delete("open", index);
    },

    progress(valor_atual, valor_meta) {
      let progresso = 0;
      progresso = (valor_atual / valor_meta) * 100;
      return progresso.toFixed(1);
    },
    formatValue(valor) {
      const appStore = useAppStore();
      return appStore.formatValue(valor);
    },
    select(value) {
      this.selected = value;
      this.$emit("getType", value);
    },
    confirmDelete(id) {
      this.confirm.require({
        message: 'Você tem certeza que deseja deletar?',
        header: 'Deletar',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Deletar',
        rejectLabel: 'Voltar',
        accept: () => {
          this.deleteAccount(id);
        }
      });
    },
    showDeleteOptions(goal) {
      this.isDeleteOptionsModalOpen = true;
      this.goalIdToDelete = goal;
    },
    deleteAccount(id) {
      axios
        .delete(`/financial_goal/${id}`, {
          headers: { Authorization: `Bearer ${this.token}` }
        })
        .then(() => {

          this.$toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Meta deletada com sucesso!',
            life: 3000
          });
          setTimeout(() => {
            this.reloadData()
          }, 1000);
        })
        .catch(error => {
          console.error('Error deleting goal:', error.response);
          if (error.response.status == 400) {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Erro ao deletar. Por favor, tente novamente.',
              life: 5000
            });
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Houve um erro ao deletar a meta. Por favor, tente novamente.',
              life: 5000
            });
          }
        });
    }
  },

};
</script>

<style lang="scss">
.btn-options {
  display: flex;
  justify-content: center;

  // margin-top: 20px;
  h2 {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    cursor: pointer;
    padding: 10px;
    margin-right: 10px;
  }

  h2:hover {
    font-weight: bold;
  }
}

td {
  i {
    cursor: pointer;
    user-select: none;
  }
}

.selected {
  background: #cb5540 0% 0% no-repeat padding-box;
  border-radius: 4px;

  h2 {
    font-weight: bold !important;
  }
}

.payment-type {
  h4 {
    text-align: left;
    font-size: 14px;
    margin: 0;
  }

  p {
    text-align: left;
    color: #7c87a7;
    font-size: 10px;
  }
}

.progress {
  height: 15px !important;
  background: #ffffff46 !important;
  border-radius: 6px;
  --bs-progress-bar-bg: #ad0000 !important;
  font-size: 8px !important;
  text-align: center;
}

.progress-label {
  font-size: 1.2em;
  position: absolute;
  margin: 0;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.progresssuccees {
  height: 15px !important;
  background: #ffffff46 !important;
  border-radius: 6px;
  --bs-progress-bar-bg: #cccc !important;
  font-size: 8px !important;
  text-align: center;
}

.flex {
  display: flex !important;
}

.centralizar {
  margin-left: auto important;
  margin-right: auto important;
  display: block important;
}

.table-fixed {
  table-layout: fixed;
}

.td-name {
  overflow:hidden;
}

/* Adicione estilos adicionais conforme necessário */
[data-tooltip] {
  position: relative;
  cursor: pointer;
}

[data-tooltip]:before {
  content: attr(data-tooltip);
  display: none;
  position: absolute;
  top: 10px;
  width: 100%;
  z-index: 100;
  padding: 3px;
  text-decoration: none;
  font-size: 10px;
  // border: 1px solid red;
  background: #ccc !important;
  color: black;
  border-radius: 5px;

  // box-shadow: 3px 3px 10px grey;
  // left: -40px;
}

[data-tooltip]:hover:before {
  display: inline-block;
}

</style>
