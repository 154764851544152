<template>
  <div class="form-default main-content">
    <div>
      <p v-if="successMessage" class="success-message">
        {{ successMessage }}
      </p>

      <p v-else-if="errorMessage" class="error-message">
        {{ errorMessage }}
      </p>
    </div>
    <div class="separator" />
    <div>
      <div class="box-flex">
        <ImageContainer :icon="null" :color="null" />
        <h3 v-if="goal.transaction_category_name">
          {{ goal.transaction_category_name }}
        </h3>
        <h3 v-if="goal.transaction_method_name">
          {{ goal.transaction_method_name }}
        </h3>
      </div>
      <div style="margin-bottom: 10px;;">
        <label for="">Valor</label>
        <input id="amount" v-model.lazy="payload.amount" v-money="moneyConfig" name="amount" class="form-control"
          placeholder="" required />
      </div>

      <!-- v-if="goal.recurrence_type === 'repeat'" -->
      <div class="row">
        <div class="col">
          <label for="">Deseja alterar:</label>
          <select v-model="goal.recurrence_type" name="recurrence_type">
            <option value="current">Apenas esse mês</option>
            <option value="repeat">Replicar para o restante do ano</option>
          </select>
        </div>
      </div>


    </div>

    <div class="separator" />
    <button @click="sendEdit">Editar</button>
  </div>
</template>

<script>
import axios from "axios";
import { useAppStore } from "@/store/store.js";
import ImageContainer from "@/components/shared/ImageContainer.vue";
import { format, unformat } from "v-money3";

export default {
  components: { ImageContainer },
  props: {
    goal: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      recurrence_type: "repeat",
      isEditing: false,
      successMessage: "",
      errorMessage: "",
      moneyConfig: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: true,
        focusOnRight: true,
        modelModifiers: {
          number: true,
        },
      },
      payload: {
        amount: format(this.goal.goal_amount, this.moneyConfig),
        id_transaction_category: this.goal.id_transaction_category ? this.goal.id_transaction_category : null,
        id_transaction_method: this.goal.id_transaction_method ? this.goal.id_transaction_method : null,
        recurrence_type: this.goal.recurrence_type ? this.goal.recurrence_type : null,
      },
    };
  },
  computed: {
    token() {
      const appStore = useAppStore();
      return appStore.token;
    },
  },
  watch: {},
  created() {
    //this.selected = this.quick;
  },
  mounted() {},
  methods: {
    sendEdit() {
      let payload = { ...this.payload };
      payload.amount = unformat(payload.amount, this.moneyConfig);
      axios
        .patch(`/financial_goal/${this.goal.id}`, payload, {
          headers: { Authorization: `Bearer ${this.token}` },
        })
        .then((response) => {
          this.successMessage = "Meta editada com sucesso!";

          this.reloadData();

          setTimeout(() => {
            this.closeModal();
          }, 1500);
        })
        .catch((error) => {
          console.error("Erro ao editar meta:", error);
          this.payload.amount = null;
          this.errorMessage = "Erro ao editar meta. Por favor, tente novamente.";
        });
    },

    async reloadData() {
      const store = useAppStore();
      //await store.getTransactions();
      this.$emit("reload");
    },
    async deleteTransaction() {
      try {
        await axios.delete(`/transaction/${this.transaction.id}`, {
          headers: { Authorization: `Bearer ${this.token}` },
        });
        this.reloadData();
        this.successMessage = "Transação excluida com sucesso!";
        setTimeout(() => {
          this.closeModal();
        }, 1500);
      } catch (error) {
        console.error("Erro ao excluir a transação:", error);
        this.errorMessage = "Erro ao excluir transação. Por favor, tente novamente.";
      }
    },

    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.box-flex {
  display: flex !important;
  align-items: center;
  margin-bottom: 10px;
}

h3 {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin: 0;
}

p {
  font-size: 14px;
  font-weight: 300;
  color: #7c87a7;
  margin: 0;
}
</style>
