<template>
  <div>
    <div v-if="!loading">
      <div v-if="type == 'transaction'" class="total-box">
        <div class="field">
          <div class="up">
            <i class="fa-solid fa-up-long" />
            <h2>Total de entradas:</h2>
          </div>
          <div>
            <h3 :class="hideCurrency ? 'blur-effect' : ''">
              {{ formatValue(totalReceitas) }}
            </h3>
          </div>
        </div>
        <div class="field">
          <div class="down">
            <i class="fa-solid fa-down-long" />
            <h2>Total de despesas:</h2>
          </div>
          <div>
            <h3 :class="hideCurrency ? 'blur-effect' : ''">
              {{ formatValue(totalDespesas) }}
            </h3>
          </div>
        </div>
        <hr class="line" />
        <div class="field mb-0">
          <h2>Saldo total:</h2>
          <h3 :class="hideCurrency ? 'blur-effect' : ''">
            {{ formatValue(totalReceitas - totalDespesas) }}
          </h3>
        </div>
      </div>
      <div v-if="type == 'transaction'" class="total-box mt-1">
        <div class="field d-flex align-items-center mb-0">
          <div class="investment">
            <i class="fa-solid fa-chart-column"></i>
            <h2>Total de investimentos:</h2>
          </div>
          <div>
            <h3 :class="hideCurrency ? 'blur-effect' : ''">
              {{ formatValue(totalInvestments) }}
            </h3>
          </div>
        </div>
      </div>
      <div v-else-if="type == 'historic'" class="total-box">
        <div class="field">
          <div class="up">
            <i class="fa-solid fa-square" />
            <h2>Total de entradas:</h2>
          </div>
          <div>
            <h3 :class="hideCurrency ? 'blur-effect' : ''">
              {{ formatValue(totalCreditHistoric) }}
            </h3>
          </div>
        </div>
        <div class="field">
          <div class="down">
            <i class="fa-solid fa-square" />
            <h2>Total de despesas:</h2>
          </div>
          <div>
            <h3 :class="hideCurrency ? 'blur-effect' : ''">
              {{ formatValue(totalDebitHistoric) }}
            </h3>
          </div>
        </div>
        <div class="field">
          <div class="investment">
            <i class="fa-solid fa-square" />
            <h2>Total de investimentos:</h2>
          </div>
          <div>
            <h3 :class="hideCurrency ? 'blur-effect' : ''">
              {{ formatValue(totalInvestmentHistoric) }}
            </h3>
          </div>
        </div>
        <hr class="line" />
        <div class="field">
          <h2>Saldo total:</h2>
          <h3 :class="hideCurrency ? 'blur-effect' : ''">
            {{ formatValue(totalCreditHistoric - totalDebitHistoric) }}
          </h3>
        </div>
      </div>
    </div>
    <div v-else class="loading-min total-box">
      <i class="fas fa-spinner fa-spin" />
    </div>
  </div>
</template>

<script>
import { useAppStore } from "@/store/store.js";
import { mapActions, mapState } from "pinia";
export default {
  props: {
    transactions: {
      type: Object,
    },
    type: {
      type: String,
      default: "transaction",
    },
    loading: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState(useAppStore, ["hideCurrency"]),

    totalReceitas() {
      if (this.transactions && this.type == "transaction") {
        return this.transactions
          .filter((transaction) => transaction.type == "credit")
          .reduce(
            (total, transaction) => total + parseFloat(transaction.amount),
            0
          );
      }
      return 0;
    },
    totalInvestments() {
      if (this.transactions && this.type == "transaction") {
        return this.transactions
          .filter((transaction) => {
            return (
              transaction.type == "investment" && !transaction.is_adjustment
            );
          })
          .reduce(
            (total, transaction) => total + parseFloat(transaction.amount),
            0
          );
      }
      return 0;
    },
    totalDespesas() {
      if (this.transactions && this.type == "transaction") {
        return this.transactions
          .filter((transaction) => transaction.type == "debit")
          .reduce(
            (total, transaction) => total + parseFloat(transaction.amount),
            0
          );
      }
      return 0;
    },
    totalDebitHistoric() {
      if (this.transactions) {
        const values = Object.values(this.transactions);
        const totalDebit = values.reduce(
          (total, transaction) => total + parseFloat(transaction.debit),
          0
        );
        return totalDebit;
      }
      return 0;
    },
    totalCreditHistoric() {
      if (this.transactions) {
        const values = Object.values(this.transactions);
        const total = values.reduce(
          (total, transaction) => total + parseFloat(transaction.credit),
          0
        );
        return total;
      }
      return 0;
    },
    totalInvestmentHistoric() {
      if (this.transactions) {
        const values = Object.values(this.transactions);
        const total = values.reduce(
          (total, transaction) => total + parseFloat(transaction.investment),
          0
        );
        return total;
      }
      return 0;
    },
  },
  methods: {
    formatValue(valor) {
      const appStore = useAppStore();
      return appStore.formatValue(valor);
    },
  },
};
</script>

<style lang="scss" scoped>
.total-box {
  background: #171d30 0% 0% no-repeat padding-box;
  border-radius: 7px;
  padding: 20px;
  h2 {
    font-size: 15px;
    font-weight: 400;
    margin: 0;
    color: #fff;
  }
  h3 {
    color: #fff;
    font-size: 17px;
    font-weight: 300;
    margin: 0;
  }
}
.field {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.up {
  display: flex;
  align-items: center;

  i {
    font-size: 12px;
    color: green !important;
    margin-right: 10px;
  }
}

.down {
  display: flex;
  align-items: center;

  i {
    font-size: 12px;
    color: red !important;
    margin-right: 10px;
  }
}

.investment {
  display: flex;
  align-items: center;

  i {
    font-size: 12px;
    color: #56b0ee !important;
    margin-right: 10px;
  }
}

/* Estilos específicos do componente TotalBox.vue */
</style>
