import { createApp } from "vue";
import { createPinia } from "pinia";
import axios from "axios";
import router from "./router";

import { VMoney } from "v-money3";
import PrimeVue from "primevue/config";

import App from "./App.vue";

import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import InputSwitch from "primevue/inputswitch";
import InputNumber from "primevue/inputnumber";
import Slider from "primevue/slider";
import Sidebar from "primevue/sidebar";
import DataTable from "primevue/datatable";
import Dropdown from "primevue/dropdown";
import Column from "primevue/column";
import FileUpload from "primevue/fileupload";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmDialog from "primevue/confirmdialog";
import OrderList from "primevue/orderlist";
import Timeline from "primevue/timeline";
import Message from "primevue/message";
import OverlayPanel from "primevue/overlaypanel";
import VueTelInput from "vue-tel-input";

// ImportCSS
import "./assets/main.scss";
import "./assets/bootstrap4-dark-blue.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/vue-tel-input.scss";

// App Vue
const app = createApp(App);
const pinia = createPinia();

// Plugins  usando o método 'use'
app.use(PrimeVue);
app.use(router);
app.use(pinia);
app.use(ToastService);
app.use(ConfirmationService);
app.use(VueTelInput, {
  defaultCountry: "br",
  autoDefaultCountry: false,
  mode: "international",
  validCharactersOnly: true,
  inputOptions: {
    placeholder: "Informe o celular",
  },
});

// Global Components
app.component("Toast", Toast);
app.component("InputText", InputText);
app.component("InputSwitch", InputSwitch);
app.component("Slider", Slider);
app.component("Sidebar", Sidebar);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("Button", Button);
app.component("Dropdown", Dropdown);
app.component("Dialog", Dialog);
app.component("FileUpload", FileUpload);
app.component("ConfirmDialog", ConfirmDialog);
app.component("OrderList", OrderList);
app.component("Timeline", Timeline);
app.component("Message", Message);
app.component("OverlayPanel", OverlayPanel);
app.component("InputNumber", InputNumber);

// Global Directives
app.directive("money", VMoney);

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
// Axios global interceptor for 401 and 403 status codes
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      router.push("/login");
    }
    return Promise.reject(error);
  }
);

app.mount("#app");
