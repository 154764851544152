<template>
  <div>
    <div class="row">
      <FreemiumDashboard />
    </div>
    <div class="row">
      <div class="col-lg-4">
        <div class="box-no-padding user-box">
          <div class="user-header">
            <div>
              <img
                v-if="profileImage"
                :src="profileImage"
                alt="Imagem do Perfil"
              />

              <!-- Se não houver URL de imagem, exiba um ícone de usuário padrão -->
              <div v-else class="user-image">
                <i class="fa-solid fa-user"></i>
              </div>
            </div>
            <div @click="showDialog" class="edit-image">
              <i class="fa-solid fa-pen"></i>
            </div>
            <div class="user-name-box">
              <h5>Seja bem vindo(a),</h5>
              <h2>{{ user ? user.name : "Usuário" }}</h2>
            </div>
          </div>
          <div class="consultant-box">
            <div class="consultant-separator pt-3">
              <div class="consultant-title">
                <i class="fa-solid fa-user-tie" style="color: #8500cb"></i>
                <p>Dados do seu consultor</p>
              </div>
            </div>
            <div v-if="loading.userInfo" class="text-center">
              <div class="spinner-border" role="status" />
            </div>
            <div v-else class="info-user-box pt-3">
              <div class="row consultant-info mb-4">
                <div class="col-3">
                  <div class="consultant-image" />
                </div>
                <div class="col">
                  <div class="consultant-info-text">
                    <h4>{{ consultantInfo.name }}</h4>
                    <p>{{ consultantInfo.email }}</p>
                    <p>{{ consultantInfo.cell_phone }}</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <button
                  class="btn-default-transparent"
                  :disabled="loading.customerInterviewToken"
                  @click="goToCustomerInterview"
                >
                  <span>{{
                    loading.customerInterviewToken
                      ? "Aguarde..."
                      : "Visualizar entrevista"
                  }}</span>
                </button>
              </div>
              <div class="consultant-contact">
                <p>Entre em contato:</p>
                <div
                  class="consultant-contact-icon"
                  @click.prevent="openWhatsappLink"
                >
                  <i
                    class="fa-brands fa-whatsapp"
                    style="color: #4caf50; border: 1px solid #4caf50"
                  />
                </div>
              </div>
            </div>

            <div v-if="!user?.cpf" class="no-goals-message">
              <p>Dados indisponíveis</p>
            </div>
          </div>
          <div class="goal-box">
            <div class="consultant-separator pt-3">
              <div class="consultant-title">
                <i class="fa-solid fa-bullseye" style="color: #cc27ad"></i>
                <p>Principais objetivos</p>
              </div>
            </div>
            <div
              v-if="goals && goals.length > 0"
              class="goal-container"
              v-for="goal in goals"
            >
              <div class="icon-container">
                <i class="fa-solid fa-bullseye" style="color: #cc27ad"></i>
              </div>
              <div class="goal-info">
                <h1>{{ goal.title }}</h1>
                <div class="info-user">
                  <p>Meta:</p>
                  <p>{{ formatValue(goal.how_much_to_invest) }}</p>
                </div>
                <div class="info-user">
                  <p>Prazo:</p>
                  <p>{{ getFormattedDate(goal.when) }}</p>
                </div>
                <div class="info-user">
                  <p>Prioridade:</p>
                  <p>{{ goal.priority }}</p>
                </div>
              </div>
            </div>

            <div v-else class="no-goals-message">
              <p>Dados indisponíveis</p>
            </div>
          </div>

          <div class="info-box">
            <div class="consultant-separator pt-3">
              <div class="consultant-title">
                <i class="fa-solid fa-user" style="color: #0d82de"></i>
                <p>Detalhes pessoais</p>
              </div>

              <div @click="openModalOff"><i class="fa-solid fa-pen"></i></div>
            </div>
            <div v-if="!user?.cpf" class="no-goals-message">
              <p>Dados indisponíveis</p>
            </div>
            <div v-if="loading.userInfo" class="text-center my-5">
              <div class="spinner-border" role="status" />
            </div>
            <div v-else class="info-user-box">
              <div class="info-user">
                <p>CPF</p>
                <h4>{{ formatarCPF(userInfo?.cpf) }}</h4>
              </div>
              <div class="info-user">
                <p>RG</p>
                <h4>{{ userInfo.rg }}</h4>
              </div>
              <div class="info-user">
                <p>Data de nascimento</p>
                <h4>{{ getFormattedDate(userInfo.birth_date) }}</h4>
              </div>
              <div class="info-user">
                <p>Profissão:</p>
                <h4>
                  {{
                    userInfo.occupation == null || userInfo.occupation == ""
                      ? "-"
                      : userInfo.occupation
                  }}
                </h4>
              </div>
              <div class="info-user">
                <p>Estado civil:</p>
                <h4>
                  {{
                    userInfo.marital_status == null ||
                    userInfo.marital_status == ""
                      ? "-"
                      : translateMaritalStatus(userInfo.marital_status)
                  }}
                </h4>
              </div>
              <div class="info-user">
                <p>Faixa de renda:</p>
                <h4>--</h4>
              </div>
            </div>
          </div>
          <div class="info-box">
            <div class="consultant-separator pt-3">
              <div class="consultant-title">
                <i class="fa-solid fa-location-dot" style="color: #61b6aa"></i>
                <p>Localização</p>
              </div>
            </div>
            <div v-if="!user?.cpf" class="no-goals-message">
              <p>Dados indisponíveis</p>
            </div>
            <div v-if="loading.userInfo" class="text-center my-5">
              <div class="spinner-border" role="status" />
            </div>
            <div v-else class="info-user-box">
              <div class="info-user-address">
                <p>Endereço:</p>

                <h4>
                  {{
                    userInfo.address_street == null ||
                    userInfo.address_street == ""
                      ? "-"
                      : userInfo.address_street
                  }}
                  ,
                  {{
                    userInfo.address_number == null ||
                    userInfo.address_number == ""
                      ? "-"
                      : userInfo.address_number
                  }}
                </h4>
              </div>
              <div class="info-user">
                <p>CEP:</p>
                <h4>
                  {{
                    userInfo.address_cep == null || userInfo.address_cep == ""
                      ? "-"
                      : formatCep(userInfo.address_cep)
                  }}
                </h4>
              </div>
              <div class="info-user-address">
                <p>Bairro:</p>
                <h4>
                  {{
                    userInfo.address_district == null ||
                    userInfo.address_district == ""
                      ? "-"
                      : userInfo.address_district
                  }}
                </h4>
              </div>
              <div class="info-user">
                <p>Estado:</p>
                <h4>
                  {{
                    userInfo.address_state == null ||
                    userInfo.address_state == ""
                      ? "-"
                      : userInfo.address_state
                  }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8 px-3">
        <div class="options-box">
          <h1>Ferramentas do cliente</h1>
          <h5>
            Explore as diferentes ferramentas e descubra como elas podem ajudar
            você!
          </h5>
          <div class="row mt-4">
            <div class="col-lg-4">
              <div
                class="box"
                @click.prevent="() => $router.push({ name: 'dashboard' })"
              >
                <div class="icon-box" style="background-color: #2476d3">
                  <i class="fa-solid fa-dollar-sign" />
                </div>
                <h4>Gestão financeira</h4>
                <p>
                  O primeiro passo para a sua independência financeira começa
                  aqui!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Sidebar v-model:visible="isModalOffOpen" position="right">
      <FormEditUser @close="closeWithToast" :data="userInfo"></FormEditUser>
    </Sidebar>
    <Dialog
      v-model:visible="dialog"
      :style="{ width: '450px' }"
      header="Alterar imagem do perfil"
      :modal="true"
    >
      <div class="flex align-items-center justify-content-center">
        <FileUpload
          chooseLabel="Selecione"
          mode="basic"
          name="picture"
          :url="`${apiUrl}/updatePicture/${user?.cpf}`"
          accept="image/*"
          :maxFileSize="1000000"
          @upload="onUpload($event)"
        />
      </div>
      <template #footer> </template>
    </Dialog>
  </div>
  <Toast />
</template>

<script>
import ModalOffCanva from "../components/shared/ModalOffCanva.vue";
import { useAppStore } from "@/store/store.js";
import FormEditUser from "../components/user/FormEditUser.vue";
import { useToast } from "primevue/usetoast";
import axios from "axios";
import FreemiumDashboard from "./FreemiumDashboard.vue";

export default {
  components: { ModalOffCanva, FormEditUser, FreemiumDashboard },

  data() {
    return {
      userInfo: [],
      consultantInfo: [],
      goals: [],
      loading: {
        userInfo: true,
        customerInterviewToken: false,
      },
      dialog: false,
      isModalOffOpen: false,
      toast: useToast(),
      interviewFrontEndUrl: process.env.VUE_APP_INTERVIEW_FRONTEND_URL,
      apiUrl: process.env.VUE_APP_API_URL,
    };
  },
  computed: {
    user() {
      const store = useAppStore();
      const user = store.userResponse.data;
      if (user) {
        this.loading.userInfo = false;
        this.userInfo = user.userInfo;
        this.consultantInfo = user.userConsultant;
        this.goals = user.userGoals;
        return user.userFastapp;
      } else {
        return {};
      }
    },
    token() {
      const store = useAppStore();
      const token = store.token;
      return token;
    },
    profileImage() {
      const store = useAppStore();
      const profileImage = store.profileImage;

      return profileImage;
    },
  },
  methods: {
    formatValue(valor) {
      const appStore = useAppStore();
      return appStore.formatValue(valor);
    },
    showDialog() {
      this.dialog = !this.dialog;
    },
    openModalOff(value) {
      this.isModalOffOpen = true;
    },
    closeModalOff() {
      this.isModalOffOpen = false;
    },
    closeWithToast() {
      this.toast.add({
        severity: "success",
        summary: "Sucesso",
        detail: `Usuário editado com sucesso!`,
        life: 3000,
      });

      this.closeModalOff();
    },
    translateMaritalStatus(status) {
      const translations = {
        single: "Solteiro",
        married: "Casado",
        divorced: "Divorciado",
        widowed: "Viúvo",
      };
      return translations[status] || "Desconhecido";
    },
    formatarCPF(cpf) {
      if (cpf) {
        cpf = cpf.replace(/\D/g, "");
        cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
        return cpf;
      }
      return "";
    },
    formatCep(cep) {
      if (/^\d{8}$/.test(cep)) {
        return cep.slice(0, 5) + "-" + cep.slice(5);
      } else {
        return "CEP Inválido";
      }
    },
    getFormattedDate(valor) {
      if (valor) {
        const appStore = useAppStore();
        return appStore.getFormattedDate(valor);
      }
      return "";
    },
    openWhatsappLink() {
      if (this.consultantInfo?.cell_phone) {
        setTimeout(() => {
          window.open(
            `https://api.whatsapp.com/send?phone=${this.consultantInfo?.cell_phone}`,
            "_blank"
          );
        });
      }
    },
    goToCustomerInterview() {
      this.loading.customerInterviewToken = true;
      axios
        .get("/user/interview_token", {
          headers: { Authorization: `Bearer ${this.token}` },
        })
        .then((response) => {
          const { authentication_token, customer_token } = response.data;
          setTimeout(() => {
            window.open(
              `${this.interviewFrontEndUrl}/autenticacao_cliente/${authentication_token}?back_url=minha_entrevista/${customer_token}`,
              "_blank"
            );
          });
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading.customerInterviewToken = false;
        });
    },
    onUpload($event) {
      this.$toast.add({
        severity: "info",
        summary: "Successo",
        detail: "Upload de arquivo com sucesso",
        life: 3000,
      });
      this.getUserInfo();
      this.showDialog();
    },
  },
};
</script>

<style lang="scss" scoped>
.user-header {
  padding: 20px;
  img {
    border: 5px solid #dbdbdb;
    border-radius: 100%;
    width: 90px;
    height: 90px;
  }
}

h1 {
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
}
h2 {
  font-size: 21px;
  font-weight: bold;
  color: #ffffff;
}
h3 {
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
}
h4 {
  font-size: 15px;
  font-weight: 600;
  color: #ffffff;
}
h5 {
  font-size: 13px;
  font-weight: 300;
  color: #e4e2e2;
}
p {
  font-size: 12px;
  font-weight: 500;
  color: #ebe7e7;
  margin: 0;
}

.box-no-padding {
  background: #283045 0% 0%;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  padding: 0;

  h3 {
    text-align: left;
    font-size: 18px;
    letter-spacing: -0.54px;
    color: #ffffff;
    margin: 0;
  }
}

.box {
  text-align: center !important;
  margin-right: 10px;
  p {
    font-size: 12px;
    font-weight: 500;
    color: #ebe7e7;
    margin: 0;
  }
}
.row {
  margin-top: 10px;
  --bs-gutter-x: 0 !important; // Define explicitamente o valor de --bs-gutter-x como 0
  --bs-gutter-y: 0 !important;
}
.no-goals-message {
  border-radius: 0;
  margin: 20px 0 !important;
}

.icon-container {
  i {
    padding: 10px;
    font-size: 24px;
    background-color: #b748ae67;
    border-radius: 100%;
    margin-right: 10px;
  }
}

.icon-box {
  display: inline-block;
  border-radius: 100px;
  padding: 10px 16px;
  margin-bottom: 10px;
  border: 10px solid #171d30;
  i {
    font-size: 20px;
  }
}
.info-user {
  display: flex;
  justify-content: space-between;
  p {
    font-size: 14px;
  }
}
.info-user-address {
  text-align: start;
  p {
    font-size: 14px;
  }
}
.info-user-box {
  padding: 0 17px;
}
.options {
  text-align: center;
}
.user-box {
  margin-right: 10px;
  text-align: center;
}
.user-name-box {
  margin-top: 10px;
}
.user-image {
  display: inline-block;
  background-color: rgb(177, 205, 230);
  padding: 20px;
  width: 89px;
  height: 89px;
  border: 5px solid #dbdbdb;
  border-radius: 100%;
  i {
    font-size: 30px;
  }
}

.goal-info {
  width: 100%;
  text-align: left;
  h1 {
    font-size: 14px;
    font-weight: 600;
  }
  p {
    font-size: 12px;
    font-weight: 300;
  }
}

.consultant-info {
  margin: 0;
}
.consultant-title {
  display: flex;
}

.consultant-info-text {
  h4 {
    font-size: 18px;
  }
  p {
    font-size: 14px;
  }
}
.consultant-image {
  width: 63px;
  height: 63px;
  background-color: rgb(45, 47, 49);
  padding: 30px;
  border: 5px solid #dbdbdb;
  border-radius: 100%;
}

.consultant-contact {
  margin: 30px 0;
  p {
    font-size: 12;
    color: #fff;
    margin-bottom: 10px;
  }
}
.consultant-contact-icon {
  i {
    cursor: pointer;
    user-select: none;
    padding: 5px;
    font-size: 24px;
    margin-right: 10px;

    border-radius: 4px;
    &:hover {
      transform: scale(1.05);
    }
  }
}

.goal-container {
  padding: 20px;
  display: flex;
  margin-bottom: 10px;
}
.edit-image {
  position: relative;
  top: -20px;
  left: 30px;
  display: inline-block;
  border-radius: 100%;
  background-color: #fa6a35;
  i {
    cursor: pointer;
    color: aliceblue;
    padding: 5px;
  }
}

.consultant-box {
  margin-top: 10px;
  text-align: left;
}
.consultant-separator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: #181d2f;
  margin-bottom: 10px;

  i {
    margin-right: 10px;
    color: #dbdbdb;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  /* Coloque seus estilos para tablets aqui */
  .box {
    margin-bottom: 10px;
  }
}

@media (min-width: 1024px) {
  .about {
    min-height: 100vh;
    display: flex;
    align-items: center;
  }
}
</style>
