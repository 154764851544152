<template>
  <Toast />
  <div class="form-default container">
    <div class="btn-options-overview row mb-3">
      <div class="col" :class="{ selected: !is_withdrawal }">
        <h2 @click="is_withdrawal = false">Aporte</h2>
      </div>
      <div class="col" :class="{ selected: is_withdrawal }">
        <h2 class="col" @click="is_withdrawal = true">Resgate</h2>
      </div>
    </div>
    <div>
      <p v-if="successMessage" class="success-message">
        {{ successMessage }}
      </p>

      <p v-else-if="errorMessage" class="error-message">
        {{ errorMessage }}
      </p>
    </div>

    <div class="row">
      <div class="col">
        <label for="">Valor</label>
        <input
          id="amount"
          v-model="payload.amount"
          v-money="moneyConfig"
          type="text"
          name="amount"
          class="form-control"
          placeholder=""
          required
        />
      </div>
      <div class="col">
        <label for="">Data da transação</label>
        <input
          id="transaction_at"
          v-model="payload.transaction_at"
          type="date"
          name="transaction_at"
          class="form-control"
          placeholder="dd/mm/yyyy"
          required
        />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <label for="">Descrição</label>
        <input
          id="description"
          v-model="payload.description"
          type="text"
          name="description"
          class="form-control"
          placeholder=""
        />
      </div>
    </div>
    <div class="col-auto switch mt-3" v-if="!is_withdrawal">
      <label>Receita externa?</label>
      <div class="form-check form-switch">
        <input
          id="customSwitch"
          v-model="is_external"
          class="form-check-input"
          type="checkbox"
        />
      </div>
    </div>
    <div class="row mt-3" v-if="!is_external">
      <div class="col">
        <label>Conta de saída</label>
        <Dropdown
          id="investment-account-sending"
          v-model="payload.id_bank_account_sending"
          :options="originAccounts"
          class="custom-dropdown mb-1"
          option-label="name"
          option-value="id"
          placeholder="Selecione uma opção"
          emptyMessage="Nenhuma conta encontrada"
        />
        <h3 v-if="payload.id_bank_account_sending">
          Saldo da conta: {{ formatValue(originAccountBalance) }}
        </h3>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <label>Conta destino</label>
        <Dropdown
          id="investment-account-receiving"
          v-model="payload.id_bank_account_receiving"
          :options="destinyAccounts"
          class="custom-dropdown mb-1"
          option-label="name"
          option-value="id"
          placeholder="Selecione uma opção"
          emptyMessage="Nenhuma conta encontrada"
        />
        <h3 v-if="payload.id_bank_account_receiving">
          Saldo da conta: {{ formatValue(destinyAccountBalance) }}
        </h3>
      </div>
    </div>
    <div class="row" v-if="is_withdrawal == 'false'">
      <div class="col-12 mt-3">
        <label>Categoria</label>
        <Dropdown
          v-model="payload.id_transaction_category"
          :options="categories"
          class="custom-dropdown"
          option-label="name"
          option-value="id"
          placeholder="Selecione uma opção"
        >
          <template #value="slotProps">
            <div v-if="slotProps.value" class="flex align-items-center">
              <div class="custom-option">
                <i
                  :class="`${
                    categories.filter((item) => item.id == slotProps.value)[0]
                      .icon || 'fa-regular fa-image'
                  }`"
                  :style="{
                    color: `${
                      categories.filter((item) => item.id == slotProps.value)[0]
                        .color || 'white'
                    }`,
                  }"
                />
                {{
                  categories.filter((item) => item.id == slotProps.value)[0]
                    .name
                }}
              </div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="{ option }">
            <div class="custom-option">
              <i
                :class="option.icon ? option.icon : 'fa-regular fa-image'"
                :style="{ color: `${option.color ? option.color : 'white'}` }"
              />
              {{ option.name }}
            </div>
          </template>
        </Dropdown>
      </div>
    </div>
    <div class="row" v-if="is_external && is_withdrawal == 'false'">
      <div class="col-12 mt-3">
        <label>Forma de recebimento</label>
        <Dropdown
          v-model="payload.id_transaction_method"
          :options="transactionMethods"
          class="custom-dropdown"
          option-label="name"
          option-value="id"
          placeholder="Selecione uma opção"
        />
      </div>
    </div>
    <div class="row mt-3">
      <button @click="save">
        <span> Salvar </span>
      </button>
    </div>
  </div>
</template>

<script>
import * as luxon from "luxon";
import _ from "lodash";
import axios from "axios";
import { useAppStore } from "@/store/store.js";
import shared from "@/shared";
import { unformat } from "v-money3";
import Dropdown from "primevue/dropdown";
import { INVESTMENT } from "../../constants/account_types";

export default {
  components: {
    Dropdown,
  },
  emits: ["close", "reload"],
  props: {
    formatValue: Function,
    investment: Boolean,
  },
  watch: {
    is_external: function () {
      this.payload.id_bank_account_sending = null;
    },
    is_withdrawal: function () {
      this.payload.id_bank_account_sending = null;
      this.payload.id_bank_account_receiving = null;
      if (this.is_withdrawal) {
        this.payload.description = "Novo resgate";
      } else {
        this.payload.description = "Novo investimento";
      }
    },
  },
  data() {
    return {
      successMessage: "",
      errorMessage: "",
      fieldNames: {},
      requiredFields: [],
      payload: {
        id_bank_account_sending: null,
        id_bank_account_receiving: null,
        id_transaction_category: null,
        id_transaction_method: null,
        amount: null,
        description: "Novo investimento",
        transaction_at: luxon.DateTime.now().toFormat("yyyy-MM-dd"),
      },
      moneyConfig: shared.moneyConfig(),
      is_external: false,
      is_withdrawal: false,
    };
  },

  computed: {
    token() {
      const appStore = useAppStore();
      return appStore.token;
    },
    accounts() {
      const store = useAppStore();
      const accounts = store.accountsResponse;
      return accounts;
    },
    categories() {
      const store = useAppStore();
      const categories = store.categoriesResponse;

      return categories;
    },
    transactionMethods() {
      const store = useAppStore();
      const transactionMethodResponse = store.transactionMethodResponse;

      return transactionMethodResponse;
    },
    investmentAccounts() {
      const investmentAccount = this.accounts.filter(
        (acc) => acc.id_account_type == "3"
      );

      return investmentAccount;
    },

    investmentAccounts() {
      const investmentAccount = this.accounts.filter(
        (acc) => acc.id_account_type == "3"
      );

      return investmentAccount;
    },
    accountReceivingAccounts() {
      const investmentAccount = this.accounts.filter(
        (acc) => acc.id_account_type == "1" || acc.id_account_type == "2"
      );

      return investmentAccount;
    },

    originAccounts() {
      const accounts = this.accounts || [];
      if (this.is_withdrawal) {
        return accounts.filter(
          (acc) =>
            acc.id_account_type == INVESTMENT &&
            acc.id != this.payload.id_bank_account_receiving
        );
      }
      return accounts.filter(
        (acc) =>
          acc.id != this.payload.id_bank_account_receiving &&
          acc.id_account_type != "4" &&
          acc.id_account_type != "3"
      );
    },
    destinyAccounts() {
      const accounts = this.accounts || []; // Fallback to an empty array if this.accounts is falsy
      if (this.is_withdrawal) {
        return accounts.filter(
          (acc) =>
            acc.id != this.payload.id_bank_account_sending &&
            acc.id_account_type != "4"
        );
      }
      return accounts.filter(
        (acc) =>
          acc.id_account_type == INVESTMENT &&
          acc.id != this.payload.id_bank_account_sending &&
          acc.id_account_type != "4"
      );
    },
    originAccountBalance() {
      if (this.payload.id_bank_account_sending) {
        const acc = _.find(this.accounts, {
          id: this.payload.id_bank_account_sending,
        });
        return acc ? acc.balance : 0;
      }
      return 0;
    },
    destinyAccountBalance() {
      if (this.payload.id_bank_account_receiving) {
        const acc = _.find(this.accounts, {
          id: this.payload.id_bank_account_receiving,
        });
        return acc ? acc.balance : 0;
      }
      return 0;
    },
  },
  methods: {
    validateForm(requiredFields) {
      this.errorMessage = "";

      for (const field of requiredFields) {
        if (!this.payload[field]) {
          this.errorMessage = `Por favor, preencha o campo '${field}'.`;
          return false;
        }
      }

      return true;
    },
    async save() {
      const store = useAppStore();

      // Mapeamento de nomes de campos em inglês para português

      this.fieldNames = {
        amount: "valor",
        id_bank_account_sending: "Conta de saída",
        id_bank_account_receiving: "Conta de Destino",
        transaction_at: "Data da transação",
      };

      this.requiredFields = [
        "amount",
        "transaction_at",
        "id_bank_account_receiving",
        "description",
      ];
      if (!this.is_external || this.is_withdrawal) {
        this.requiredFields.push("id_bank_account_sending");
      }

      const isFormValid = this.validate();
      if (!isFormValid) return;

      const missingFields = [];

      for (const field of this.requiredFields) {
        if (!this.payload[field]) {
          missingFields.push(this.fieldNames[field]);
        }
      }

      if (missingFields.length > 0) {
        const missingFieldsString = missingFields.join(", ");
        this.payload.amount = null;
        this.errorMessage = `Os seguintes campos são obrigatórios: ${missingFieldsString}.`;
        return; // Abortar a função caso o formulário não seja válido
      }

      this.payload.amount = unformat(this.payload.amount, this.moneyConfig);
      this.payload.is_withdrawal = this.is_withdrawal;
      try {
        const response = await axios.post(`/investment`, this.payload, {
          headers: { Authorization: `Bearer ${this.token}` },
        });
        //this.getBills(this.account.id);
        const store = useAppStore();
        store.updateData();
        this.successMessage = "Investimento feito com sucesso!";
        this.$emit("reload");
        setTimeout(() => {
          this.closeModal();
        }, 1500);
      } catch (error) {
        this.payload.amount = null;
        console.error(error);
        this.$toast.add({
          severity: "error",
          summary: "Desculpe mas não podemos continuar",
          detail:
            "Erro ao realizar o investimento. Por favor, tente novamente.",
          life: 5000,
        });
      }
    },
    closeModal() {
      this.clear();
      this.$emit("close");
    },
    clear() {
      (this.successMessage = ""),
        (this.errorMessage = ""),
        (this.fieldNames = {}),
        (this.requiredFields = []),
        (this.payload = {
          id_bank_account_sending: null,
          id_bank_account_receiving: null,
          amount: null,
          description: "Novo investimento",
          transaction_at: null,
        }),
        (this.is_external = false);
      this.is_withdrawal = false;
    },
    validate() {
      try {
        if (!this.payload.amount) {
          this.$toast.add({
            severity: "error",
            summary: "Desculpe mas não podemos continuar",
            detail: "Informe o valor do investimento",
            life: 5000,
          });
          return false;
        }
        if (
          !this.payload.id_bank_account_sending &&
          this.is_external == false
        ) {
          this.$toast.add({
            severity: "error",
            summary: "Desculpe mas não podemos continuar",
            detail: "Selecione a conta de saída",
            life: 5000,
          });
          return false;
        }
        if (!this.payload.transaction_at) {
          this.$toast.add({
            severity: "error",
            summary: "Desculpe mas não podemos continuar",
            detail: "Informe a data da transação",
            life: 5000,
          });
          return false;
        }
        if (!this.payload.id_bank_account_receiving) {
          this.$toast.add({
            severity: "error",
            summary: "Desculpe mas não podemos continuar",
            detail: "Selecione a conta de destino",
            life: 5000,
          });
          return false;
        }
        return true;
      } catch (error) {
        console.error("An error occurred in the validate method: ", error);
        // Handle the error gracefully, possibly by showing a generic error message to the user.
        this.$toast.add({
          severity: "error",
          summary: "Erro inesperado",
          detail: "Ocorreu um erro inesperado. Tente novamente.",
          life: 5000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h4 {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  margin: 0;
  margin-bottom: 10px;
}
h3 {
  font-size: 12px;
  color: #fff;
  font-weight: 300;
  margin: 0;
  margin-bottom: 10px;
}

.selected {
  background: #cb5540;
  border-radius: 5px 0px 0px 5px;

  h2 {
    font-weight: bold !important;
  }
}

.btn-options-overview {
  border: 1px solid #cb5540;
  border-radius: 5px;
  text-align: center;
  margin: 1px;
  h2 {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    cursor: pointer;
    padding: 10px;
    margin: 0;
  }
  h2:hover {
    font-weight: bold;
  }
}
</style>
