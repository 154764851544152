<template>
  <div>
    <div v-if="typeButton == 'transactions'" class="transactions">
      <div class="icon">
        <i class="fa-solid fa-list-check" />
      </div>
      <div class="text-button">
        <span>Acessar <b>lançamento detalhado</b></span>
      </div>
      <div>
        <p>〉</p>
      </div>
    </div>
    <div v-if="typeButton == 'historic'" class="historic">
      <div class="icon">
        <i class="fa-solid fa-clock-rotate-left" />
      </div>
      <div class="text-button">
        <span>Acessar <b>meu histórico</b></span>
      </div>
      <div>
        <p>〉</p>
      </div>
    </div>
    <div v-if="typeButton == 'reports'" class="reports">
      <div class="icon">
        <i class="fa-solid fa-clock-rotate-left" />
      </div>
      <div class="text-button">
        <span>Ver relatórios <b>completos</b></span>
      </div>
      <div>
        <p>〉</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ButtonGradient",
  props: {
    typeButton: String,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
/* Seus estilos personalizados aqui */

.transactions {
  display: flex;
  height: 95px;
  color: #fff;
  cursor: pointer;
  //margin-left:  10px;
  user-select: none;
  justify-content: space-between !important;
  align-items: center;
  padding: 20px;
  background: transparent linear-gradient(180deg, #8416ac 0%, #330b56 100%) 0%
    0%;
  box-shadow: 0px 3px 6px #00000072;
  border-radius: 7px;
  opacity: 1;
  i {
    background-color: #ab0bff;
    padding: 10px;
    border-radius: 100%;
    font-size: 22px;
  }
  span {
    font-size: 16px;
  }
  p {
    font-weight: bolder;
    margin: 0;
  }
}

.text-button {
  margin: 0 10px;
  text-align: center;
}

.historic {
  width: auto !important;
}

.reports,
.historic {
  width: fit-content;
  display: flex;
  color: #fff;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  justify-content: space-between !important;
  align-items: center;
  padding: 10px 20px;
  background: transparent linear-gradient(180deg, #1650ac 0%, #0b2856 100%) 0%
    0%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4470588235);
  border-radius: 7px;
  opacity: 1;

  span {
    font-size: 16px;
  }
  p {
    font-weight: bolder;
    margin: 0;
  }
  i {
    background-color: #ffa40b;
    padding: 6px;
    border-radius: 100%;
    font-size: 20px;
    color: #fff;
  }
}
</style>
