<template>
  <div class="main-box">
    <table
      v-if="data.length > 0"
      class="table custom-table"
    >
      <thead>
        <tr>
          <th scope="col">
            Forma de Pagamento
          </th>

          <th scope="col">
            Receitas
          </th>
          <th scope="col">
            Despesas.
          </th>
          <th scope="col">
            Meta de gastos
          </th>
          <th scope="col">
            Saldo
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(transaction, index) in transactionsPaginated"
          :key="index"
          :class="index % 2 === 0 ? 'row-even' : 'row-odd'"
        >
          <td class="category">
            {{
              transaction.transaction_method_name
                ? transaction.transaction_method_name
                : "Forma de Pagamento sem Nome"
            }}
          </td>
  
      
          <td>
            {{ formatValue(Math.abs(transaction.credit)) }}
          </td>
          <td>
            {{ formatValue(Math.abs(transaction.debit)) }}
          </td>
          <td>
            {{ formatValue(Math.abs(transaction.goal_amount)) }}
          </td>
          <td>
            {{ formatValue(Math.abs(transaction.balance)) }}
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-else
      class="no-goals-message"
    >
      <p>Você ainda não possui histórico para este mês.</p>
    </div>
    <div class="separator" />
    <nav
      v-if="totalPages > 1"
      class="pagination-nav"
    >
      <div
        class="pagination-link-arrow"
        :class="{ disabled: currentPage === 1 }"
        @click="previousPage"
      >
        <i class="fa-solid fa-chevron-left" />
      </div>
      <div class="pagination-box-pages">
        <div
          v-for="page in totalPages"
          :key="page"
          :class="{ active: currentPage === page }"
          class="pagination-pages"
        >
          <span @click="goToPage(page)">
            {{ page }}
          </span>
        </div>
      </div>
  
      <div
        class="pagination-link-arrow"
        :class="{ disabled: currentPage === totalPages }"
        @click="nextPage"
      >
        <i class="fa-solid fa-chevron-right" />
      </div>
    </nav>
  </div>
</template>
  
  <script>
  import { useAppStore } from "@/store/store.js";
  import ImageContainer from "@/components/shared/ImageContainer.vue";
  
  export default {
    components: { ImageContainer },
    props: {
      data: {
        type: Array,
        required: true,
      },
      perPage: {
        type: Number,
        default: 10,
      },
      type: {
        type: String,
        default: "credit",
      },
    },
    data() {
      return {
        currentPage: 1,
      };
    },
    computed: {
      totalPages() {
        return Math.floor(Object.values(this.data).length / this.perPage);
      },
  
      transactionsPaginated() {
        // if (!this.transactions || typeof this.transactions !== "object") {
  
        // 	return [];
        // }
  
        // Flatten the data into an array of transactions
        const allTransactions = Object.values(this.data).flat();
        const startIndex = (this.currentPage - 1) * this.perPage;
        const endIndex = startIndex + this.perPage;
  
        // Slice the sorted transactions to get the desired page
        const paginatedTransactions = allTransactions.slice(startIndex, endIndex);
  
        return paginatedTransactions;
      },
    },
    methods: {
      previousPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      },
  
      nextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
        }
      },
      goToPage(page) {
        this.currentPage = page;
      },
      progress(valor_atual, valor_meta) {
                  let progresso = 0;
                  progresso = (valor_atual / valor_meta) * 100;
                  return progresso.toFixed(1);
              },
  
      formatValue(valor) {
        const appStore = useAppStore();
        return appStore.formatValue(valor);
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .category {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    i {
      // width: 10%;
      font-size: 10px;
      padding: 8px;
      border-radius: 100px;
      background-color: #00ad00;
      margin-right: 10px;
    }
  }
  .payment {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    i {
      // width: 10%;
      font-size: 10px;
      padding: 8px;
      border-radius: 100px;
      background-color: #e67308;
      margin-right: 10px;
    }
  }
  
  .day-label {
    font-size: 16px;
    text-align: left;
    font-weight: 600;
    color: #f26236;
  }
  </style>
  