<template>
  <nav>
    <ul class="web-menu" v-if="isWeb">
      <!-- Menu para web -->
      <li :class="{ active: isRouteActive('/planning/future') }">
        <router-link :to="{ name: 'future' }">
          <span>Linha do futuro</span>
        </router-link>
      </li>
      <li
        @click="checkFeatureDisabled"
        :class="{
          active: isRouteActive('/planning/retirement'),
          disabled: featureDisabled,
        }"
      >
        <router-link
          :to="{ name: 'retirement' }"
          :class="{ 'link-disabled': featureDisabled }"
        >
          <span>Projeção de aposentadoria</span>
        </router-link>
      </li>
      <li
        @click="checkFeatureDisabled"
        :class="{
          active: isRouteActive('/planning/dreams'),
          disabled: featureDisabled,
        }"
      >
        <router-link
          :to="{ name: 'dreams' }"
          :class="{ 'link-disabled': featureDisabled }"
        >
          <span>Mural dos sonhos</span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import { useAppStore } from "@/store/store.js";
export default {
  name: "NavMenu",
  data() {
    return {
      isMenuOpen: false,
      isWeb: false,
      selectedMobileRoute: "",
      planningRoutes: [
        { path: "/planning/future", title: "Planejamento Futuro" },
        { path: "/planning/retirement", title: "Aposentadoria" },
        { path: "/planning/dreams", title: "Sonhos" },
      ],
    };
  },
  watch: {
    // Observa a mudança da rota atual
    $route(to, from) {
      this.selectedMobileRoute = to.path;
    },
  },
  computed: {
    featureDisabled() {
      const store = useAppStore();
      return !store.hasPlanningAccess();
    },
  },
  methods: {
    isRouteActive(route) {
      return this.$route.path === route;
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    checkScreenSize() {
      this.isWeb = window.innerWidth > 767;
    },
    getPageTitle(route) {
      const foundRoute = this.mobileRoutes.find((r) => r.path === route);
      return foundRoute ? foundRoute.title : "";
    },
    setCurrentRoute() {
      this.selectedMobileRoute = this.$route.path;
    },
    checkFeatureDisabled() {
      if (this.featureDisabled) {
        this.$toast.add({
          severity: "warn",
          summary: "Recurso indisponível",
          detail: "Essa ferramenta não está incluída no plano contratado.",
          life: 5000,
        });
      }
    },
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
    this.setCurrentRoute();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
};
</script>

<style lang="scss" scoped>
nav {
  background: #283045 0% 0% no-repeat padding-box;
  border-radius: 7px;
  opacity: 1;
  margin: 20px 0;
  padding: 0 20px;
}
.web-menu {
  padding: 20px 10px;
  display: flex;
  justify-content: flex-start;
  gap: 60px;

  li {
    display: inline-block;
    font-size: 14px;
    color: #fff;
  }

  a {
    color: #fff;
    padding: 10px;
    text-decoration: none;
  }

  li.active {
    font-weight: bold;
  }
  .router-link-active {
    background: #f2623669 0% 0% no-repeat padding-box;
    border-radius: 4px;
    color: #fff;

    border-bottom: 4px solid #c65440;
  }
}

.mobile-menu {
  display: none;

  ul {
    background-color: #181d2f;
    border: none;
    box-shadow: 2px 2px 4px 2px rgba(133, 131, 131, 0.2);
  }

  li {
    margin-bottom: 5px;
  }

  a {
    color: #fff;
    padding: 10px;
    text-decoration: none;
  }

  router-link-active {
    background: #f2623669 0% 0% no-repeat padding-box;
    border-radius: 4px;
    color: #fff;
  }
}

.show-menu {
  display: block;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #fff;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  p {
    padding: 10px;
    background: #f2623669 0% 0% no-repeat padding-box;
    border-radius: 4px;
    color: #fff;
    font-weight: bold;
    margin: 0;
  }
}

.arrow-down {
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #fff transparent transparent transparent;
  margin-left: 5px;
}

@media (max-width: 767px) {
  .web-menu {
    display: none;
  }

  .mobile-menu {
    display: block;
  }
}

.disabled {
  opacity: 0.4;
}
.link-disabled {
  pointer-events: none;
}
</style>
