<template>
  <Toast />
  <template v-if="!loading">
    <div v-if="!isDeleting && !loading" class="form-default main-content">
      <div>
        <p v-if="successMessage" class="success-message">
          {{ successMessage }}
        </p>

        <p v-else-if="errorMessage" class="error-message">
          {{ errorMessage }}
        </p>
      </div>
      <p>Descrição</p>
      <input
        v-if="isEditing"
        id="description"
        v-model="transaction.description"
        type="text"
        name="description"
        class="form-control"
        placeholder=""
      />
      <h3 v-else>
        {{ transaction.description }}
      </h3>
      <p class="mt-3">Valor</p>
      <input
        v-if="isEditing"
        id="amount"
        v-model.lazy="transaction.amount"
        v-money="moneyConfig"
        name="amount"
        class="form-control"
        placeholder=""
        required
      />
      <h3 v-else>
        {{ formatValue(transaction.amount) }}
      </h3>
      <div class="separator" />
      <div class="row">
        <div class="col-lg">
          <p>Data</p>
          <input
            v-if="isEditing"
            id="transaction_at"
            v-model="transaction.transaction_at"
            type="datetime-local"
            name="transaction_at"
            class="form-control"
            placeholder="dd/mm/yyyy"
            required
          />
          <h3 v-else>
            {{ formatDate(transaction.transaction_at) }}
          </h3>
        </div>
      </div>
      <div v-if="isEditing">
        <div class="row mt-3" v-if="!is_external">
          <div class="col">
            <p>Conta de saída</p>
            <Dropdown
              v-model="transaction.sending_bank_account_id"
              :options="originAccounts"
              class="custom-dropdown mb-1"
              option-label="name"
              option-value="id"
              placeholder="Selecione uma opção"
              emptyMessage="Nenhuma conta encontrada"
            />
            <p v-if="transaction.sending_bank_account_id">
              Saldo da conta: {{ formatValue(originAccountBalance) }}
            </p>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <p>Conta destino</p>
            <Dropdown
              v-model="transaction.id_bank_account"
              :options="destinyAccounts"
              class="custom-dropdown mb-1"
              option-label="name"
              option-value="id"
              placeholder="Selecione uma opção"
              emptyMessage="Nenhuma conta encontrada"
            />
            <p v-if="transaction.id_bank_account">
              Saldo da conta: {{ formatValue(destinyAccountBalance) }}
            </p>
          </div>
        </div>
        <div class="row mt-3">
          <!-- <div class="col">
            <p>Categoria</p>
            <Dropdown v-model="transaction.id_transaction_category" :options="categories" class="custom-dropdown"
              option-label="name" option-value="id" placeholder="Selecione uma opção" />
          </div> -->
        </div>
        <div class="row" v-if="is_external">
          <div class="col-12 mt-3">
            <p>Forma de recebimento</p>
            <Dropdown
              v-model="transaction.id_transaction_method"
              :options="transactionMethods"
              class="custom-dropdown"
              option-label="name"
              option-value="id"
              placeholder="Selecione uma opção"
            />
          </div>
        </div>
      </div>
      <div v-else class="img-container">
        <ImageContainer
          v-if="transaction.id_transaction_category_data"
          :icon="transaction.id_transaction_category_data.icon"
          :color="transaction.id_transaction_category_data.color"
        />
        <div>
          <p>Categoria</p>
          <h3 v-if="transaction.id_transaction_category_data">
            {{ transaction.id_transaction_category_data.name }}
          </h3>
          <h3 v-else>Sem Categoria</h3>
        </div>
      </div>

      <div v-else>
        <div class="row mt-3">
          <div v-if="transaction.sending_bank_name" class="col-6 img-container">
            <ImageContainer :icon="null" :color="null" />
            <div>
              <p>Conta de saída:</p>
              <h3>
                {{ transaction.sending_bank_name }}
              </h3>
            </div>
          </div>

          <div class="col-6 img-container">
            <ImageContainer :icon="null" :color="null" />
            <div>
              <p>Conta destino:</p>
              <h3 v-if="transaction.id_bank_account_data">
                {{ transaction.id_bank_account_data.name }}
              </h3>
              <h3 v-else>-</h3>
            </div>
          </div>
          <div
            v-if="transaction.id_transaction_method_data"
            class="col-6 img-container"
          >
            <div>
              <p>Forma de recebimento:</p>
              <h3>
                {{ transaction.id_transaction_method_data.name }}
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div class="separator" />
      <div class="row">
        <div class="col-lg">
          <button
            v-if="!isEditing"
            @click="
              () => {
                isDeleting = true;
              }
            "
          >
            <i class="fa-solid fa-trash" />
            Excluir
          </button>
          <button v-if="isEditing" @click="editeTransaction">
            <i class="fa-solid fa-xmark" />
            Cancelar edição
          </button>
        </div>

        <div
          class="col-lg"
          v-if="
            transaction.type != 'investment' &&
            transaction.type &&
            'investment_transfer' &&
            transaction.type != 'investment_withdrawal'
          "
        >
          <button v-if="!isEditing" @click="editeTransaction">
            <i class="fa-solid fa-pen" />
            Editar
          </button>
          <button v-if="isEditing" @click="sendEditTransaction">
            <i class="fa-solid fa-pen" />
            Salvar
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="isDeleting" class="form-default main-content">
        <div class="separator" />
        <div class="row">
          <div class="col-lg">
            <button v-if="!isEditing" @click="deleteTransaction">
              <i class="fa-solid fa-trash" />
              Confirmar
            </button>
            <button
              class="mt-2"
              v-if="!isEditing"
              @click="() => (isDeleting = false)"
            >
              <i class="fa-solid fa-xmark" />
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  <div v-else class="loading-min total-box">
    <i class="fas fa-spinner fa-spin" />
  </div>
</template>

<script>
import axios from "axios";
import { useAppStore } from "@/store/store.js";
import ImageContainer from "@/components/shared/ImageContainer.vue";
import shared from "@/shared";
import { format, unformat } from "v-money3";
import { useToast } from "primevue/usetoast";
import { INVESTMENT } from "../../constants/account_types";
import { INVESTMENT_WITHDRAWAL } from "../../constants/transaction_types";

export default {
  components: { ImageContainer },
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      selected: "current",
      isEditing: false,
      isDeleting: false,
      successMessage: "",
      errorMessage: "",
      fieldNames: {},
      requiredFields: [],
      moneyConfig: shared.moneyConfig(),
      toast: useToast(),
      transaction: {
        id: null,
        description: "",
        transaction_at: "",
        amount: "",
        id_bank_account: null,
        sending_bank_account_id: null,
        id_transaction_category: 3,
        id_transaction_method: null,
        is_withdrawal: false,
      },
    };
  },
  computed: {
    categories() {
      const store = useAppStore();
      const categories = store.categoriesResponse;
      return categories;
    },

    transactionMethods() {
      const store = useAppStore();
      const transactionMethodResponse = store.transactionMethodResponse;
      return transactionMethodResponse;
    },
    transactionType() {
      const store = useAppStore();
      const transactionTypeResponse = store.transactionTypeResponse;

      return transactionTypeResponse;
    },
    token() {
      const appStore = useAppStore();
      return appStore.token;
    },
    accounts() {
      const store = useAppStore();
      const accounts = store.accountsResponse;
      return accounts;
    },
    originAccounts() {
      if (this.is_withdrawal) {
        return this.accounts.filter(
          (acc) =>
            acc.id_account_type == INVESTMENT &&
            acc.id != this.transaction.id_bank_account
        );
      }
      return this.accounts.filter(
        (acc) => acc.id != this.transaction.id_bank_account
      );
    },
    destinyAccounts() {
      if (this.is_withdrawal) {
        return this.accounts.filter(
          (acc) => acc.id != this.transaction.sending_bank_account_id
        );
      }
      return this.accounts.filter(
        (acc) =>
          acc.id_account_type == INVESTMENT &&
          acc.id != this.transaction.sending_bank_account_id
      );
    },
    originAccountBalance() {
      if (this.transaction.sending_bank_account_id) {
        const acc = _.find(this.accounts, {
          id: this.transaction.sending_bank_account_id,
        });
        return acc ? acc.balance : 0;
      }
    },
    destinyAccountBalance() {
      if (this.transaction.id_bank_account) {
        const acc = _.find(this.accounts, {
          id: this.transaction.id_bank_account,
        });
        return acc ? acc.balance : 0;
      }
    },
    is_external() {
      return !this.transaction.sending_bank_account_id;
    },
    is_withdrawal() {
      return this.transaction.type === INVESTMENT_WITHDRAWAL;
    },
  },
  mounted() {
    this.getInvestmentTransaction(this.record.id);
  },
  watch: {
    isEditing: function () {
      if (!this.isEditing) {
        this.getInvestmentTransaction(this.record.id);
      }
    },
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Os meses começam do 0 em JavaScript
      const day = String(date.getDate()).padStart(2, "0");

      return `${day}/${month}/${year}`;
    },
    getInvestmentTransaction(id) {
      this.loading = true;
      axios
        .get(`/investment/${id}`, {
          headers: { Authorization: `Bearer ${this.token}` },
        })
        .then((response) => {
          this.transaction = response.data;
        })
        .catch((error) => {
          console.error("Erro ao recuperar investimento:", error);
          this.errorMessage =
            "Erro ao recuperar investimento. Por favor, tente novamente.";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getFormattedDate(valor) {
      const dateAndTime = valor.split(" ");
      const dateStr = dateAndTime[0].split("-");
      return dateStr[2] + "/" + dateStr[1] + "/" + dateStr[0];
    },
    duplicateTransaction() {
      const payload = {
        description: `${this.transaction.description} (2)`,
        transaction_at: this.transaction.transaction_at,
        amount: this.transaction.amount,
        id_bank_account_sending: this.transaction.sending_bank_account_id,
        id_bank_account_receiving: this.transaction.id_bank_account,
        id_transaction_category: this.transaction.id_transaction_category,
        id_transaction_method: this.transaction.id_transaction_method,
        is_withdrawal: this.is_withdrawal,
      };
      axios
        .post(`/investment`, payload, {
          headers: { Authorization: `Bearer ${this.token}` },
        })
        .then((response) => {
          this.successMessage = "Transação duplicada com sucesso!";
          this.$emit("reload");
          const store = useAppStore();
          store.updateData();
          setTimeout(() => {
            this.closeModal();
          }, 1500);
        })
        .catch((error) => {
          console.error("Erro ao duplicar transação:", error);
          this.errorMessage =
            "Erro ao duplicar transação. Por favor, tente novamente.";
        });
    },
    editeTransaction() {
      this.isEditing = !this.isEditing;
    },
    sendEditTransaction() {
      const payload = {
        description: this.transaction.description,
        transaction_at: this.transaction.transaction_at,
        amount: unformat(this.transaction.amount, this.moneyConfig),
        id_bank_account_sending: this.transaction.sending_bank_account_id,
        id_bank_account_receiving: this.transaction.id_bank_account,
        id_transaction_category: this.transaction.id_transaction_category,
        id_transaction_method: this.transaction.id_transaction_method,
        is_withdrawal: this.is_withdrawal,
      };
      axios
        .put(`/investment/${this.transaction.id}`, payload, {
          headers: { Authorization: `Bearer ${this.token}` },
        })
        .then((response) => {
          this.successMessage = "Transação editada com sucesso!";
          this.$emit("reload");
          const store = useAppStore();
          store.updateData();
          this.toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: `Transação editada com sucesso!`,
            life: 3000,
          });
          setTimeout(() => {
            this.closeModal();
          }, 1500);
        })
        .catch((error) => {
          console.error("Erro ao editar transação:", error);
          this.errorMessage =
            "Erro ao editar transação. Por favor, tente novamente.";
        });
    },
    async deleteTransaction() {
      try {
        await axios.delete(`/investment/${this.transaction.id}`, {
          headers: { Authorization: `Bearer ${this.token}` },
        });
        this.toast.add({
          severity: "success",
          summary: "Sucesso",
          detail: `Transação excluída com sucesso!`,
          life: 3000,
        });
        const store = useAppStore();
        store.updateData();
        this.$emit("reload");
        setTimeout(() => {
          this.closeModal();
        }, 1500);

        this.successMessage = "Transação excluida com sucesso!";
      } catch (error) {
        console.error("Erro ao excluir a transação:", error);
        this.errorMessage =
          "Erro ao excluir transação. Por favor, tente novamente.";
      }
    },
    getTransactionType(valor) {
      const appStore = useAppStore();
      return appStore.getTransactionType(valor);
    },
    select(value) {
      this.selected = value;
    },
    formatValue(valor) {
      const appStore = useAppStore();
      return appStore.formatValue(valor);
    },

    toggleReload(valor) {
      const appStore = useAppStore();
      return appStore.toggleReload();
    },
    validateForm(requiredFields) {
      this.errorMessage = "";

      for (const field of requiredFields) {
        if (!this.goal[field]) {
          this.errorMessage = `Por favor, preencha o campo '${field}'.`;
          return false;
        }
      }

      return true;
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
button {
  border: 1px solid #e54f00;
  background-color: transparent;
  color: #e54f00;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  i {
    margin-right: 5px;
  }
}
h3 {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

p {
  font-size: 14px;
  font-weight: 300;
  color: #7c87a7;
  margin: 0;
}
.img-container {
  display: flex;
  margin-bottom: 10px;
}
.main-content {
  text-align: left;
}
.calc-credit {
  margin: 20px;
  text-align: center;
  h3 {
    color: #fff;
    font-size: 16px;
  }
  span {
    color: rgb(0, 255, 13);
    font-weight: bold;
  }
}
.calc-debit {
  margin: 20px;
  text-align: center;
  h3 {
    color: #fff;
    font-size: 16px;
  }
  span {
    color: red;
    font-weight: bold;
  }
}
</style>
