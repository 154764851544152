<template>
  <div>
    <div class="row">
      <div v-if="account.id_bank_data" class="col-lg-6">
        <h5>Instituição</h5>
        <p>{{ account.id_bank_data.name }}</p>
      </div>
      <div v-if="account.id_account_type_data" class="col-lg-6">
        <h5>Tipo de conta</h5>
        <p>{{ account.id_account_type_data.label }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <h5>Agência</h5>
        <p>{{ account.account_agency }}</p>
      </div>
      <div class="col-lg-6">
        <h5>Número da conta</h5>
        <p>{{ account.account_number }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md pay-box">
        <button class="btn-pay" @click="emitModal('Transferência')">
          Transferência
        </button>
      </div>
      <div class="col-md pay-box">
        <button class="btn-pay" @click="emitModal('Upload')">
          Upload de extrato
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    account: Object,
    formatValue: Function,
  },
  data() {
    return {};
  },
  computed: {},
  created() {},

  methods: {
    emitModal(value) {
      this.$emit("openModal", value);
    },
  },
};
</script>
<style lang="scss" scoped>
.pay-box {
  text-align: center;

  .btn-pay {
    background-color: transparent;
    width: auto;
    padding: 10px;
    border: 1px solid #cb5540;
    border-radius: 4px;
    color: #cb5540;
    &:hover {
      transform: scale(1.05);
      font-weight: bold;
    }
  }
}
.bills {
  h2 {
    color: #fff;
    font-size: 18px;
    margin: 0;
  }
  h3 {
    color: #fff;
    font-size: 15px;
    margin: 0;
  }
  h4 {
    color: #fff;
    font-size: 13px;
    margin: 0;
  }
}

.title-account {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  h1 {
    color: #fff;
    font-size: 18px;
    margin: 0;
  }
}
h5 {
  color: #bdbaba;
  font-size: 12px;
  margin: 0;
  font-weight: 300;
}
.header-account {
  display: flex;
  justify-content: space-between;
}

.return-icon {
  cursor: pointer;
  user-select: none;

  &:hover {
    transform: scale(1.2);
  }
}

.edit-btn {
  display: flex;
  align-items: center;
  color: #fff;
  p {
    margin: 0;
  }
  i {
    margin-right: 5px;
  }
}
</style>
