<template>
  <div>
    <form class="form-default" @submit.prevent="updateCustomer">
      <label for="name">Nome</label>
      <input type="text" id="name" v-model="data.name" />

      <label for="marital_status">Estado Civil</label>
      <select id="marital_status" v-model="data.marital_status">
        <option value="single">Solteiro</option>
        <option value="married">Casado</option>
        <option value="divorced">Divorciado</option>
        <option value="widowed">Viúvo</option>
        <option value="other">Outro</option>
      </select>

      <label for="birth_date">Data de Nacimento</label>
      <input type="date" id="birth_date" :value="formatDate(data.birth_date)" @input="updateDate" />
      <label for="cell_phone">Celular</label>
      <input type="tel" id="cell_phone" v-model="data.cell_phone" />

      <label for="rg">RG</label>
      <input type="text" id="rg" v-model="data.rg" />

      <button type="submit">Salvar Alterações</button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { useAppStore } from "@/store/store.js";
import ImageContainer from "@/components/shared/ImageContainer.vue";
import shared from "@/shared";
import { unformat } from "v-money3";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selected: "current",
      isEditing: false,
      successMessage: "",
      errorMessage: "",
      fieldNames: {},
      requiredFields: [],
      successMessage: "",
      errorMessage: "",
      moneyConfig: shared.moneyConfig(),
    };
  },
  created() {},
  components: { ImageContainer },

  mounted() {},
  computed: {
    token() {
      const appStore = useAppStore();
      return appStore.token;
    },
  },
  methods: {
    formatDate(isoDate) {
      const formattedDate = new Date(isoDate).toISOString().split("T")[0];
      return formattedDate;
    },
    updateDate(event) {
      this.data.birth_date = event.target.value;
    },

    updateCustomer() {
      axios
        .put(`/updateCustomer/${this.data.cpf}`, this.data, {
          headers: { Authorization: `Bearer ${this.token}` },
        })
        .then((response) => {
          this.$emit("close");
          this.loadData();
        })
        .catch((error) => {
          console.error("Erro ao editar meta:", error);
          this.errorMessage = "Erro ao editar meta. Por favor, tente novamente.";
        });
    },
    loadData() {
      const store = useAppStore();
      store.getUser();
    },
  },
};
</script>
<style lang="scss" scoped>
input {
  padding: 0.5rem;
}

label {
  margin-top: 0.5rem;
}

button {
  margin-top: 0.5rem;
}
</style>
