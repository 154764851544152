<template>
  <div class="loading-container">
    <img class="loading-logo" src="~@/assets/LOGOCOMPLETO.png" alt="Loading" />
  </div>
  <div class="content main-login d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <div class="left-login col-lg-9">
            <div class="card-login">
              <img
                class="animation__shake"
                src="~@/assets/LOGOCOMPLETO.png"
                alt="AdminLTELogo"
              />
              <h3>Cadastre uma nova senha</h3>
              <h5>
                <a>Insira sua nova senha nos campos abaixo e confirme-a</a>
              </h5>
              <div v-if="apiValidationError !== ''" id="responseMessage">
                <div class="alert alert-danger">
                  {{ apiValidationError }}
                </div>
              </div>
              <div v-if="passwordReseted" id="responseMessage">
                <div class="alert alert-success">Nova senha confirmada</div>
              </div>

              <div class="textfield">
                <label for="password">Senha</label>
                <input
                  id="password"
                  v-model="password"
                  type="password"
                  name="password"
                  required
                  class="form-control"
                />
              </div>
              <div class="textfield">
                <label for="senha">Confirme a senha</label>
                <input
                  id="confirmationPassword"
                  v-model="passwordConfirmation"
                  type="password"
                  name="confirmationPassword"
                  required
                  class="form-control"
                />
              </div>
              <input
                type="submit"
                value="Confirmar"
                class="btn-login"
                @click.prevent="resetPassword"
              />
              <hr />
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="rigth-login">
            <div class="quote">
              <span>ʻ</span>
              <h4>
                Aproveite ao máximo todas as ferramentas que oferecemos e
                descubra como elas podem tornar seu
                <strong>Futuro</strong> ainda mais fácil e prático!
              </h4>
              <span />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      token: this.extractTokenFromURL(),
      password: "",
      passwordConfirmation: "",
      passwordReseted: false,
      apiValidationError: "",
    };
  },
  created() {
    setTimeout(function () {
      const loadingContainer = document.querySelector(".loading-container");
      const content = document.querySelector(".content");

      loadingContainer.style.opacity = "0";
      loadingContainer.style.pointerEvents = "none";
      content.style.display = "block";
    }, 2500);
  },
  methods: {
    resetPassword() {
      this.passwordReseted = false;
      const { password, passwordConfirmation, token } = this;
      const data = {
        token: token,
        password: password,
        password_confirmation: passwordConfirmation,
      };
      axios
        .post(`/user/reset_password`, data)
        .then((response) => {
          if (response.status === 200) {
            this.passwordReseted = true;
            this.apiValidationError = "";
            this.$router.push("/login");
          }
        })
        .catch((error) => {
          this.apiValidationError = "";
          this.passwordReseted = false;
          const error_message = error.response?.data?.errors;
          if (error_message) {
            if (error_message.password) {
              if (
                error_message.password[0] === "The password field is required."
              ) {
                this.apiValidationError = "Digite uma senha";
                return;
              } else if (
                error_message.password[0] ===
                "The password field must be at least 8 characters."
              ) {
                this.apiValidationError =
                  "A senha deve conter pelo menos 8 caracteres";
                return;
              } else if (
                error_message.password[0] ===
                "The password field confirmation does not match."
              ) {
                this.apiValidationError = "As senhas não coincidem";
                return;
              }
            } else if (error_message.token) {
              this.apiValidationError = "Ocorreu um erro";
            }
          }
        });
    },
    extractTokenFromURL() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      return urlSearchParams.get("token") || "";
    },
  },
};
</script>

<style lang="css" scoped>
.row {
  --bs-gutter-x: 0 !important;
  --bs-gutter-y: 0 !important;
}
body {
  font-family: "Montserrat", sans-serif;
  margin: 0;
}

.main-login {
  background-image: url("~@/assets/background-login.png");
  /* background-image: url("~@/assets/bg-login.jpg"); */
  background-size: cover;
  background-position: center;
  padding: 40px 0;
  min-height: 100vh;
}

.rigth-login {
  padding-top: 40px;
  color: aliceblue;
}
h4 {
  font-size: 20px;
}
.separator {
  width: 100%;
  border: 1px solid #b9b9b9;
  margin: 10px 0;
}
.quote {
  display: none;
  margin-left: 200px;
}
.quote > span {
  color: #d75413;
  font-size: 100px;
  font-weight: bold;
  margin: 0;
}
.quote > h4 {
  text-align: center;
  font-size: 24px;
  margin: 0;
}

.card-login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #ffffff10;
  padding: 40px;
  border-radius: 10px;
  border: 2px solid #ffffff30;
  backdrop-filter: blur(10px);
  box-shadow: 0px 10px 40px #00000056;
}

.card-login > img {
  width: 30%;
  height: auto;
  margin-bottom: 30px;
}

.card-login > h3 {
  color: aliceblue;
  font-size: 22px;
  font-weight: 800;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.card-login > h5 {
  color: aliceblue;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.textfield {
  width: 100%;
}
a {
  font-size: 14px;
}

.textfield > input {
  width: 100%;
  height: 38px;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  background: #ffffff;
  font-size: 12pt;
  box-shadow: 0px 10px 40px #00000056;
  outline: none;
  box-sizing: border-box;
}

label {
  color: aliceblue;
  font-size: 14px;
  margin-bottom: 5px;
}

.textfield > input::placeholder {
  color: gray;
}

.checkbox {
  color: aliceblue;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;
}

.btn-login {
  width: 100%;
  height: 38px;
  margin-top: 38px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  outline: none;
  font-weight: bold;
  letter-spacing: 2px;
  color: aliceblue;
  background: #d75413;
  cursor: pointer;
}

.esqueci_senha {
  text-decoration: none;
  color: aliceblue;
}

.cadastro {
  color: aliceblue;
  text-decoration: none;
}

.Consultor {
  color: #d75413;
  font-weight: bold;
  text-decoration: none;
}
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #181d2f;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 1;
  transition: opacity 0.5s ease-in-out; /* Transição suave da opacidade */
  pointer-events: all; /* Permite interação com o loading */
}

.loading-logo {
  width: 100px; /* Largura inicial da logo */
  height: auto;
  animation: pulse 1.5s infinite alternate; /* Animação de pulsação */
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2); /* Aumenta a logo em 20% */
  }
}

.content {
  display: none;
}
@media only screen and (max-width: 990px) {
  .quote {
    display: flex;
    margin-left: 10px;
  }

  .main-login {
    background-image: url("~@/assets/bg-login.jpg");
  }
}
</style>
