<template>
  <div class="form-default container">
    <div>
      <p v-if="successMessage" class="success-message">
        {{ successMessage }}
      </p>

      <p v-else-if="errorMessage" class="error-message">
        {{ errorMessage }}
      </p>
    </div>
    <div class="row">
      <div v-if="typeGoal == 'category'" class="col">
        <label>Categoria</label>
        <select id="type" v-model="goal.id_transaction_category">
          <option
            v-for="(type, index) in filteredCategoriesDespesas"
            :key="index"
            :value="type.id"
          >
            {{ type.name }}
          </option>
        </select>
      </div>
      <div v-if="typeGoal == 'payment'" class="col">
        <label>Forma de Pagamento</label>
        <select id="type" v-model="goal.id_transaction_method">
          <option
            v-for="(type, index) in transactionMethod"
            :key="index"
            :value="type.id"
          >
            {{ type.name }}
          </option>
        </select>
      </div>
      <div v-if="typeGoal == 'debit'" class="col-12 mb-3">
        <label>Tipo de Gasto</label>
        <Dropdown
          v-model="id_transaction_type"
          :options="transactionType"
          class="custom-dropdown"
          option-label="name"
          option-value="id"
          placeholder="Selecione uma opção"
        />
      </div>
      <div v-if="typeGoal == 'investment'" class="col">
        <label>Investimento</label>
        <select id="type" v-model="goal.id_transaction_category">
          <option
            v-for="(type, index) in filteredCategoriesInvestiment"
            :key="index"
            :value="type.id"
          >
            {{ type.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="row" style="margin-bottom: 10px">
      <div class="col">
        <label for="">Valor</label>
        <input
          id="amount"
          v-model="goal.amount"
          v-money="moneyConfig"
          type="text"
          name="amount"
          class="form-control"
          placeholder=""
          required
        />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <label for="">Salvar para os demais meses</label>
        <select v-model="selected" @change="select">
          <option value="current">Exclusivo para o mês selecionado</option>
          <option value="repeat" selected>
            Replicar esta meta para o restante do ano
          </option>
        </select>
      </div>
    </div>

    <button class="mt-2" @click="save">
      <span> Salvar </span>
    </button>
  </div>
</template>

<script>
import axios from "axios";
import { useAppStore } from "@/store/store.js";
import shared from "@/shared";
import { unformat } from "v-money3";

export default {
  components: {},
  props: {
    typeGoal: String,
    selectedYear: Number,
    selectedMonth: Number,
  },
  data() {
    return {
      selected: "repeat",
      successMessage: "",
      errorMessage: "",
      fieldNames: {},
      requiredFields: [],
      id_transaction_type: 2,
      goal: {
        id_transaction_category: null,
        id_transaction_method: null,
        amount: null,
        year: this.selectedYear,
        month: this.selectedMonth,
      },
      moneyConfig: shared.moneyConfig(),
    };
  },
  computed: {
    categories() {
      const store = useAppStore();
      const categories = store.categoriesResponse;
      return categories;
    },
    filteredCategoriesDespesas() {
      return this.categories
        .filter((category) =>
          [1, 2, 4, 5].includes(category.id_transaction_type_data.id)
        )
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    filteredCategoriesInvestiment() {
      return this.categories
        .filter((category) =>
          [3].includes(category.id_transaction_type_data.id)
        )
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    transactionType() {
      const store = useAppStore();
      const transactionTypeResponse = store.transactionTypeResponse;
      if (!transactionTypeResponse) {
        return [];
      }
      const t = transactionTypeResponse.filter((item) =>
        [1, 2].includes(item.id)
      );
      return t;
    },
    transactionMethod() {
      const store = useAppStore();
      const transactionMethodResponse = store.transactionMethodResponse;

      return transactionMethodResponse;
    },
    token() {
      const appStore = useAppStore();
      return appStore.token;
    },
  },
  methods: {
    select(value) {
      this.selected = value;
    },
    select(event) {
      this.selected = event.target.value;
    },
    toggleReload(valor) {
      const appStore = useAppStore();
      return appStore.toggleReload();
    },
    save() {
      const store = useAppStore();
      for (const key in this.goal) {
        if (this.goal[key] !== null) {
          this.goal[key] = String(this.goal[key]);
        }
      }
      if (typeof this.goal.amount === "string") {
        this.goal.amount = unformat(this.goal.amount, this.moneyConfig);
      }
      const isFormValid = this.validate();
      if (!isFormValid) return;
      else if (this.typeGoal == "debit") {
        this.fieldNames = {
          amount: "valor",
        };
        this.requiredFields = ["amount"];
      } else if (this.typeGoal == "investiment") {
        this.fieldNames = {
          amount: "valor",
          id_transaction_category: "Investimento",
        };
        this.requiredFields = ["amount", "id_transaction_category"];
      }
      const missingFields = [];
      this.goal.repeat = this.selected;
      if (this.typeGoal == "debit") {
        this.goal.id_transaction_type = this.id_transaction_type;
      }
      axios
        .post(`/financial_goal`, this.goal, {
          headers: { Authorization: `Bearer ${this.token}` },
        })
        .then((response) => {
          this.successMessage = "Meta salva com sucesso!";
          this.$emit("reload");
          setTimeout(() => {
            this.closeModal();
          }, 1500);
        })
        .catch((error) => {
          console.error("Erro ao salvar Meta:", error);
          this.goal.amount = null;
          this.errorMessage =
            "Erro ao salvar Meta. Por favor, tente novamente.";
        });
    },
    closeModal() {
      this.$emit("close");
    },
    validate() {
      if (!this.goal.id_transaction_category && this.typeGoal == "category") {
        this.$toast.add({
          severity: "error",
          summary: "Desculpe mas não podemos continuar",
          detail: "Selecione uma categoria para a meta",
          life: 5000,
        });
        return false;
      }
      if (!this.goal.id_transaction_method && this.typeGoal == "payment") {
        this.$toast.add({
          severity: "error",
          summary: "Desculpe mas não podemos continuar",
          detail: "Selecione uma forma de pagamento para a meta",
          life: 5000,
        });
        return false;
      }
      if (!this.goal.id_transaction_category && this.typeGoal == "investment") {
        this.$toast.add({
          severity: "error",
          summary: "Desculpe mas não podemos continuar",
          detail: "Selecione um investimento para a meta",
          life: 5000,
        });
        return false;
      }
      if (!this.goal.amount) {
        this.$toast.add({
          severity: "error",
          summary: "Desculpe mas não podemos continuar",
          detail: "Informe um valor válido",
          life: 5000,
        });
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-options {
  border: 1px solid #cb5540;
  border-radius: 5px;
  text-align: center;
  margin: 10px 0;

  h2 {
    margin: 0;
  }
}

.btn-options-overview {
  border: 1px solid #cb5540;
  border-radius: 5px;
  text-align: center;
  margin: 1px;
  h2 {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    cursor: pointer;
    padding: 10px;
    margin: 0;
  }
  h2:hover {
    font-weight: bold;
  }
}

.selected {
  background: #cb5540;
  border-radius: 5px 0px 0px 5px;

  h2 {
    font-weight: bold !important;
  }
}
</style>
