<template>
  <div style="height: 350px">
    <Bar
      v-if="chartData.datasets.length"
      id="my-chart-id"
      :options="chartOptions"
      :data="chartData"
    />
  </div>
</template>

<script>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  components: { Bar },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "top",
            labels: {
              color: "white",
            },
          },
        },
        scales: {
          y: {
            ticks: {
              beginAtZero: true,
              color: "white",
            },
          },
          x: {
            ticks: {
              color: "white",
            },
          },
        },
      },
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.renderChart();
  },
  methods: {
    renderChart() {
      const monthNames = [
        "JAN",
        "FEV",
        "MAR",
        "ABR",
        "MAI",
        "JUN",
        "JUL",
        "AGO",
        "SET",
        "OUT",
        "NOV",
        "DEZ",
      ];

      this.chartData.labels = monthNames.map((month) => month);

      // Initialize data arrays for debit, credit, and saldo
      const entradas = Array(12).fill(0);
      const saidas = Array(12).fill(0);
      const saldos = Array(12).fill(0);
      const fixed = Array(12).fill(0);
      const variable = Array(12).fill(0);
      const investment = Array(12).fill(0);

      // Loop through the keys of this.dados (representing "M-A" format)
      for (const key in this.data) {
        const [month, year] = key.split("-");
        const index = parseInt(month, 10) - 1;

        // Populate data arrays with debit, credit, and saldo for the corresponding month
        entradas[index] = this.data[key].credit || 0;
        saidas[index] = this.data[key].debit || 0;
        saldos[index] = this.data[key].credit - this.data[key].debit || 0;
        fixed[index] = this.data[key].fixed || 0;
        variable[index] = this.data[key].variable || 0;
        investment[index] = this.data[key].investment || 0;
      }

      this.chartData.datasets = [
        {
          label: "Entrada",
          data: entradas,
          backgroundColor: "rgba(58,125,68, 1)",
          borderRadius: 10,
          borderWidth: 1,
        },
        {
          label: "Gasto fixo",
          data: fixed,
          backgroundColor: "rgba(217, 83, 79, 1)",
          color: "#fff",
          borderRadius: 10,
          borderWidth: 1,
        },
        {
          label: "Gasto variável",
          data: variable,
          backgroundColor: "rgba(255, 170, 90, 1)",
          borderRadius: 10,
          borderWidth: 1,
        },
        {
          label: "Investimento",
          data: investment,
          backgroundColor: "rgba(201,160,220, 1)",
          borderRadius: 10,
          borderWidth: 1,
        },
        {
          label: "Saldo",
          data: saldos,
          backgroundColor: "rgba(37,110,255, 1)",
          color: "#fff",
          borderRadius: 10,
          borderWidth: 1,
        },

      ];
    },
  },
};
</script>
