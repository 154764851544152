<template>
  <div class="container-terms">
    <header>
      <h1>TERMO DE ADESÃO E CONDIÇÕES DE USO</h1>
    </header>
    <main>
      <section>
        <p>
          <b>FUTURO GESTÃO FINANCEIRA PESSOAL LTDA</b>, sociedade empresária
          limitada, regularmente estabelecida na avenida Dom Pedro I, S/N, Vila
          Monumento, São Paulo SP, CEP 01.552-001 inscrita no CNPJ nº.
          48.312.560/0001-08, com base neste Termo de Adesão e Condições de Uso,
          está disponibilizado ao <b>usuário</b> da internet (“<b>usuário</b>”)
          o presente, mediante aceitação das condições a seguir estabelecidas.
        </p>
        <p>
          LEIA ATENTAMENTE OS TERMOS E CONDIÇÕES DE USO APRESENTADOS. AO ACESSAR
          E UTILIZAR OS SERVIÇOS AQUI DESCRITOS, O <b>USUÁRIO</b> TEM CIÊNCIA E
          CONCORDA COM OS PRESENTES TERMOS E CONDIÇÕES DE USO.
        </p>
      </section>

      <section>
        <h2>REQUISITOS DE IDADE E ELEGIBILIDADE</h2>
        <p>
          Para usar o Serviço da <b>Futuro Gestão Financeira</b> e acessar
          qualquer Conteúdo, o <b>usuário</b> precisa:
        </p>
        <ul>
          <li>
            Ter 16 anos de idade (ou a idade mínima equivalente em seu país de
            origem) ou mais;
          </li>
          <li>
            Ter consentimento dos pais ou responsáveis se você for menor de
            idade em seu país de origem;
          </li>
          <li>
            Ter capacidade civil de firmar um contrato vinculativo conosco e não
            ser impedido de fazê-lo sob quaisquer leis aplicáveis.
          </li>
        </ul>
        <p>
          O <b>usuário</b> também confirma que todas as informações de registro
          enviadas a <b>Futuro</b> são verdadeiras e completas, concordando em
          mantê-las assim todo o tempo de uso do Serviço.
        </p>
        <p>
          Se o <b>usuário</b> for menor de idade em seu país de origem,
          familiares ou responsáveis precisarão aceitar estes Termos em seu
          nome.
        </p>
      </section>

      <section>
        <h2>CLÁUSULA PRIMEIRA - DEFINIÇÕES</h2>
        <p>
          <b>1.1</b> As definições grafadas, no singular ou no plural, neste
          Termo terão o seguinte sentido:
        </p>
        <ul>
          <li>
            <b>Aplicativo:</b> softwares gratuitos para web, utilizados para
            disponibilização de serviços oferecidos pela
            <b>Futuro Gestão Financeira Pessoal LTDA</b>, a ser utilizado pelo
            browser.
          </li>
          <li>
            <b>Assinaturas Pagas:</b> Pagamento
            mensal/trimestral/semestral/anual de uma taxa para acesso a versão
            premium do Aplicativo.
          </li>
          <li>
            <b>Central de Atendimento:</b> São os canais de comunicação oficiais
            da <b>Futuro Gestão Financeira Pessoal</b>, disponibilizada ao
            <b>usuário</b>, cujos canais são indicados ao final deste Termo.
          </li>
          <li>
            <b>Disponibilidade:</b> capacidade de acesso à informação gerada ou
            adquirida para qualquer finalidade, no momento em que for
            necessário.
          </li>
          <li>
            <b>Senha:</b> meio de validação da identidade do <b>usuário</b> para
            fins de acesso aos recursos ou serviços de processamento da
            informação dentro de um sistema ou ambiente eletrônico, representado
            por um conjunto de caracteres, de conhecimento único do
            <b>usuário</b>.
          </li>
          <li>
            <b>Serviços:</b> compreendem os serviços oferecidos por meio do
            Aplicativo Meu Futuro e relacionados na <b>CLÁUSULA 2</b> deste
            Termo.
          </li>
          <li>
            <b>Usuário:</b> pessoa física que realizou o download, por meio da
            loja de aplicativos do seu aparelho smartphone e realizou o
            respectivo cadastro.
          </li>
        </ul>
      </section>

      <section>
        <h2>CLÁUSULA SEGUNDA - SERVIÇOS DO APLICATIVO</h2>
        <p>
          <b>2.1</b> A <b>Futuro</b> disponibiliza ao <b>usuário</b>, por meio
          do Aplicativo, os seguintes serviços:
        </p>
        <ul>
          <li>
            <b>Fluxo de Caixa:</b> O Fluxo de Caixa é uma funcionalidade
            essencial que permite aos <b>usuários</b> acompanhar, controlar e
            projetar suas movimentações financeiras em um determinado período de
            tempo, fornecendo uma visão clara das entradas, saídas de dinheiro e
            investimentos, através de classificações por categorias, tipos de
            gastos e formatos de pagamento diversos, ajudando o <b>usuário</b> a
            tomar decisões financeiras mais acertadas.
          </li>
          <li>
            <b>Linha do Futuro:</b> A funcionalidade de Linha de Futuro é uma
            ferramenta inovadora que permite ao <b>usuário</b> projetar seus
            investimentos e objetivos de vida ao longo do tempo, incluindo metas
            financeiras de curto, médio e longo prazo, como aposentadoria e
            outras conquistas pessoais. Essa funcionalidade oferece uma visão
            clara e personalizada do caminho financeiro para cada
            <b>usuário</b>, ajudando a planejar e alcançar seus objetivos de
            forma eficaz.
          </li>
          <li>
            <b>Diagnóstico Financeiro Gratuito:</b> Oferece aos
            <b>usuários</b> uma análise completa e sem custos de sua vida
            financeira através do Aplicativo. Com essa avaliação, é possível
            identificar oportunidades de otimização, potenciais riscos e traçar
            um plano personalizado para melhorar a saúde financeira. Essa
            ferramenta ajuda a orientar decisões mais conscientes e a promover
            uma gestão financeira mais eficiente, visando o bem-estar econômico
            dos <b>usuários</b>.
          </li>
        </ul>
        <p>
          <b>2.2.</b> A <b>Futuro</b> oferece opções ao <b>usuário</b> referente
          aos serviços prestados. Algumas opções do serviço serão fornecidas
          gratuitamente, enquanto outras exigem pagamento antes de serem
          acessadas (<b>"Assinaturas pagas"</b>). Dentro da Modalidade de
          <b>Assinaturas pagas</b>, será devidamente explicado ao
          <b>usuário</b> quais planos serão disponibilizados e quais serviços
          serão prestados, respectivamente.
        </p>
      </section>

      <section>
        <h2>CLÁUSULA TERCEIRA - DO PERÍODO DE TESTES</h2>
        <p>
          <b>3.1.</b> A <b>Futuro</b> pode oferecer testes de assinaturas pagas
          por um período específico sem pagamento ou com taxa reduzida.
        </p>
        <p>
          <b>3.2.</b> Cada oferta promocional fornece acesso a um Serviço
          Premium anunciado.
        </p>
        <p><b>3.3.</b> Ao enviar os detalhes de pagamento o <b>usuário</b>:</p>
        <ul>
          <li>Concorda com a Oferta Promocional anunciada;</li>
          <li>Reconhece e concorda com os Termos de Uso do Aplicativo.</li>
        </ul>
        <p>
          <b>3.4.</b> O <b>usuário</b> qualificado pode aceitar a oferta
          promocional apenas uma vez. <b>Usuários</b> que já assinaram algum
          serviço promocional anteriormente, não podem resgatar a oferta
          novamente.
        </p>
        <p>
          <b>3.5.</b> No caso de qualquer oferta promocional, o Período
          Promocional correspondente continuará pelo período, conforme
          anunciado.
        </p>
        <p>
          <b>3.6.</b> A menos que o <b>usuário</b> cancele uma Oferta
          Promocional antes do final do Período Promocional,
          <b
            >ele se tornará automaticamente um assinante recorrente do tipo de
            Serviço que escolheu para se inscrever na Oferta Promocional e, a
            forma de pagamento que fornecida será cobrada automaticamente pelo
            preço mensal atual</b
          >.
        </p>
        <p>
          <b>3.7.</b> Se o <b>usuário</b> cancelar uma Oferta Promocional
          durante o Período Promocional, perderá o acesso aos Serviços Premium;
          sua conta será alterada para uma conta gratuita.
        </p>
        <p>
          <b>3.8.</b> Para cancelar a assinatura, o <b>usuário</b> deve acessar
          a sua conta e seguir as instruções contidas na página.
        </p>
      </section>

      <section>
        <h2>CLÁUSULA QUARTA – LIMITAÇÕES DOS SERVIÇOS FORNECIDOS</h2>
        <p>
          <b>4.1.</b> Todas as medidas de cuidado e habilidade são realizadas
          pela <b>Futuro</b> para manter o Aplicativo operacional, trazendo ao
          <b>usuário</b>
          a melhor experiência na utilização de ferramentas que o auxiliam a
          conseguir a tão sonhada independência financeira. No entanto, nossas
          ofertas de serviços e a disponibilidade delas podem mudar
          eventualmente e estar sujeitas às leis aplicáveis.
        </p>
        <p>
          <b>4.2.</b> Os Serviços podem sofrer interrupções temporárias devido a
          dificuldades técnicas, manutenção, testes ou atualizações.
        </p>
        <p>
          <b>4.3.</b> A <b>Futuro Gestão Financeira</b> não tem qualquer
          responsabilidade, nem qualquer obrigação de fornecer reembolso ao
          <b>usuário</b> por interrupções ou falhas no serviço de internet ou em
          outros serviços causados por ações de autoridades governamentais,
          outros terceiros ou eventos naturais.
        </p>
      </section>

      <section>
        <h2>CLÁUSULA QUINTA – DIREITOS DE USO DO APLICATIVO</h2>
        <p>
          <b>5.1.</b> O nome de <b>usuário</b> e senha são apenas para uso
          pessoal e devem ser mantidos em sigilo. O <b>usuário</b> entende que é
          responsável por todo o uso (incluindo qualquer uso não autorizado) do
          seu nome de <b>usuário</b> e senha.
        </p>
        <p>
          <b>5.2.</b> Em caso de perda ou roubo da Conta, notifique nossa equipe
          de Atendimento ao Cliente imediatamente. A <b>Futuro</b> pode
          recuperar ou exigir que você altere seu nome de <b>usuário</b> por
          qualquer motivo.
        </p>
        <p>
          <b>5.3.</b> A <b>Futuro</b> concede ao <b>usuário</b> permissão
          limitada, exclusiva e revogável para utilização do aplicativo de forma
          pessoal e não comercial.
        </p>
        <p>
          <b>5.4.</b> Os aplicativos de software e o conteúdo são licenciados,
          não vendidos ou transferidos, e a <b>Futuro</b> e seus licenciadores
          mantêm a propriedade de todas as cópias dos aplicativos de software e
          do Conteúdo, mesmo após a instalação nos dispositivos do
          <b>usuário</b>.
        </p>
      </section>

      <section>
        <h2>CLÁUSULA SEXTA – DIREITOS DE PROPRIEDADE DA FUTURO</h2>
        <p>
          <b>6.1.</b> O Aplicativo e o conteúdo são propriedades da
          <b>Futuro</b> ou de seus licenciantes.
        </p>
        <p>
          <b>6.2.</b> Todas as marcas comerciais, marcas de serviço, nomes
          comerciais, logotipos, nomes de domínio e quaisquer outros recursos da
          marca <b>Futuro</b> são propriedade exclusiva da empresa ou de seus
          licenciadores.
        </p>
        <p>
          <b>6.3.</b> Estes Termos não concedem ao <b>usuário</b> nenhum direito
          de usar qualquer recurso da marca, seja para uso comercial ou não
          comercial.
        </p>
        <p>
          <b>6.4.</b> O <b>usuário</b> concorda em cumprir nossas Diretrizes do
          <b>usuário</b>
          e em não usar o Serviço, o Conteúdo ou qualquer parte dele de qualquer
          maneira não expressamente permitida por estes Termos.
        </p>
      </section>

      <section>
        <h2>CLÁUSULA SÉTIMA – PAGAMENTO/CANCELAMENTO</h2>
        <p>
          <b>7.1.</b> O <b>usuário</b> pode adquirir uma assinatura paga
          diretamente com a <b>Futuro</b>, pagando uma taxa de assinatura com
          antecedência mensal ou em algum outro intervalo divulgado antes da
          realização da compra.
        </p>
        <p>
          <b>7.2.</b> Os tributos serão calculados com base nas informações
          fornecidas e na taxa aplicável no momento da cobrança.
        </p>
        <p>
          <b>7.3.</b> A <b>Futuro</b> pode alterar o preço das assinaturas
          pagas, incluindo eventuais taxas de assinatura recorrentes e,
          comunicará quaisquer alterações de preço ao <b>usuário</b> com
          antecedência mínima de 02 (dois) meses. As alterações de preço serão
          aplicadas no início do próximo período de assinatura, após a data da
          alteração do preço.
        </p>
        <p>
          <b>7.4.</b> Nos termos da legislação aplicável, o <b>usuário</b>, ao
          continuar a usar os serviços de assinatura mensal após a vigência da
          alteração dos preços, aceita as condições e alterações e,
          consequentemente o novo valor de assinatura.
        </p>
      </section>

      <section>
        <h2>CLÁUSULA OITAVA – RENOVAÇÃO/CANCELAMENTO DE ASSINATURA PAGA</h2>
        <p>
          <b>8.1.</b> A assinatura paga será automaticamente renovada no final
          do período de assinatura aplicável, a menos que o
          <b>usuário</b> cancele sua assinatura antes do final do período de
          assinatura atual, ou que o plano selecionado tenha prazo determinado.
        </p>
        <p>
          <b>8.2.</b> O cancelamento entrará em vigor no dia seguinte ao último
          dia do período de assinatura atual, e a conta do
          <b>usuário</b> regredirá para a versão gratuita do aplicativo.
        </p>
        <p>
          <b>8.3.</b> A <b>Futuro</b> não realiza reembolsos ou créditos por
          períodos parciais de assinatura.
        </p>
      </section>

      <section>
        <h2>CLÁUSULA NONA – DIREITO DE ARREPENDIMENTO</h2>
        <p>
          <b>9.1.</b> Nos termos da legislação em vigor, o <b>usuário</b> ao se
          inscrever para um teste, concorda que o direito de arrependimento da
          Assinatura paga para a qual ele está recebendo um Teste termina 14
          (quatorze) dias após o início do Teste. Se o <b>usuário</b> não
          cancelar a assinatura paga antes do final do período de Teste, perderá
          este direito e autorizará a <b>Futuro</b> a cobrar automaticamente o
          preço acordado todos os meses até que ocorra o cancelamento da
          Assinatura paga ou, que vença o período do plano escolhido.
        </p>
        <p>
          <b>9.2.</b> O <b>usuário</b> consente expressamente que a
          <b>Futuro</b>
          forneça o serviço imediatamente após a confirmação da compra.
        </p>
      </section>

      <section>
        <h2>
          CLÁUSULA DÉCIMA – DIREITOS DE PROPRIEDADE INTELECTUAL E DE CONTEÚDO
        </h2>
        <p>
          <b>10.1.</b> O <b>usuário</b> pode editar e utilizar-se das
          ferramentas fornecidas pelo aplicativo, respeitando os planos
          selecionados e/ou a versão gratuita. Todo o material fornecido pelo
          <b>usuário</b> não será divulgado e ficará disponível para
          visualização apenas em sua conta, respeitando toda legislação em
          vigor.
        </p>
        <p>
          <b>10.2.</b> O <b>usuário</b> mantém a propriedade do seu Conteúdo
          quando o lança no Aplicativo. No entanto, para que a
          <b>Futuro</b> possa disponibilizar seus treinamentos e ferramentas
          para obtenção de sua liberdade financeira, é necessária uma licença
          limitada concedida pelo <b>usuário</b> referente aos dados fornecidos
          ao Aplicativo.
        </p>
        <p>
          <b>10.3.</b> Desta forma, o <b>usuário</b>, por meio do presente
          instrumento, concede a <b>Futuro</b> uma licença não exclusiva,
          transferível, sublicenciável, sem royalties e irrevogável para,
          reproduzir, disponibilizar, executar e exibir, traduzir, modificar,
          criar trabalhos derivados, distribuir e usar de outra forma qualquer
          Conteúdo do <b>usuário</b> por qualquer meio, seja sozinho ou em
          combinação com outro Conteúdo ou material, de qualquer maneira e por
          qualquer meio, método ou tecnologia, já conhecido em conexão com o
          Serviço do Aplicativo, respeitando, obviamente, os dados sensíveis
          fornecidos pelo <b>usuário</b>.
        </p>
        <p>
          <b>10.4.</b> Quando aplicável e no limite permitido pela lei, o
          <b>usuário</b> também concorda em renunciar e não impor nenhum
          "direito moral" ou direito equivalente, como seu direito de ser
          identificado como autor de qualquer Conteúdo do <b>usuário</b>.
        </p>
      </section>

      <section>
        <h2>
          CLÁUSULA DÉCIMA PRIMEIRA – DA EXPERIÊNCIA DO USUÁRIO NA UTILIZAÇÃO DO
          APLICATIVO E DO FEEDBACK
        </h2>
        <p>
          <b>11.1.</b> O conteúdo acessado pelo <b>usuário</b> pode ser
          influenciado por considerações comerciais, incluindo contratos da
          <b>Futuro</b> com terceiros.
        </p>
        <p>
          <b>11.2.</b> Algum dos conteúdos fornecidos, podem incorporar
          publicidade e a <b>Futuro</b> não é responsável pela publicidade
          apresentada.
        </p>
        <p>
          <b>11.3.</b> Se o <b>usuário</b> fornecer ideias, reclamações,
          melhorias ou qualquer outro tipo de feedback à <b>Futuro</b> em
          relação ao uso do Aplicativo, tais sugestões não serão confidenciais e
          pode ser utilizado a qualquer momento pela <b>Futuro</b> sem
          restrições ou qualquer tipo de pagamento ao <b>usuário</b>.
        </p>
      </section>

      <section>
        <h2>CLÁUSULA DÉCIMA SEGUNDA – DO SUPORTE AO CLIENTE</h2>
        <p>
          <b>12.1.</b> Se o <b>usuário</b> tiver alguma dúvida sobre os Serviços
          do Aplicativo ou sobre estes Termos, entre em contato com o Serviço de
          Atendimento ao Cliente na seção “Sobre nós” no site oficial da
          empresa.
        </p>
      </section>

      <section>
        <h2>
          CLÁUSULA DÉCIMA TERCEIRA – DO ENCERRAMENTO/SUSPENSÃO DOS SERVIÇOS DO
          APLICATIVO
        </h2>
        <p>
          <b>13.1.</b> Os termos incluídos neste presente instrumento particular
          continuarão sendo aplicáveis até que sejam rescindidos pelo
          <b>usuário</b> ou pela <b>Futuro</b>.
        </p>
        <p>
          <b>13.2.</b> A <b>Futuro</b> poderá rescindir TODOS OS TERMOS
          PRESENTES NO INSTRUMENTO ou SUSPENDER O ACESSO aos serviços do
          Aplicativo a qualquer momento, embasando-se em quaisquer
          descumprimentos do presente Termo.
        </p>
        <p>
          <b>13.3.</b> O <b>usuário</b> também pode rescindir os TERMOS a
          qualquer momento, todavia, seu acesso ficará limitado à versão
          gratuita do aplicativo.
        </p>
      </section>

      <section>
        <h2>CLÁUSULA DÉCIMA QUARTA – DA LIMITAÇÃO DE RESPONSABILIDADE</h2>
        <p>
          <b>14.1.</b> A <b>Futuro</b>, compromete-se a disponibilizar aos
          usuários uma série de ferramentas e serviços que visam auxiliá-los na
          busca pela independência financeira. No entanto, o
          <b>usuário</b> reconhece e concorda que a prestação dos serviços
          constitui uma obrigação de meio, e não de resultado.
        </p>
        <p>
          <b>14.2.</b> O sucesso ou obtenção de resultados financeiros
          positivos, incluindo, mas não se limitando, à independência
          financeira, depende de uma série de fatores que estão fora do controle
          da empresa, como decisões individuais do <b>usuário</b>, condições de
          mercado, comportamento financeiro pessoal e outras circunstâncias
          imprevistas. Com efeito, a <b>Futuro</b> não garante, assegura ou
          promete quaisquer resultados específicos relacionados ao uso das
          ferramentas e serviços disponibilizados pelo aplicativo.
        </p>
        <p>
          <b>14.3.</b> Por tais razões, a <b>Futuro</b> não será
          responsabilizada por quaisquer perdas, danos ou eventuais prejuízos
          sofridos pelo
          <b>usuário</b>
          que decorram da utilização das ferramentas e serviços do aplicativo,
          incluindo, mas não se limitando a, perdas financeiras, diminuição de
          patrimônio, falhas na execução de investimentos, ou qualquer outro
          resultado adverso.
        </p>
        <p>
          <b>14.4.</b> As decisões financeiras realizadas pelo <b>usuário</b>,
          com base nas ferramentas e treinamentos fornecidos pela
          <b>Futuro</b> são de exclusiva responsabilidade do <b>usuário</b>.
        </p>
      </section>

      <section>
        <h2>CLÁUSULA DÉCIMA QUINTA – DISPOSIÇÕES FINAIS</h2>
        <p>
          <b>15.1.</b> As obrigações constantes em contrato referentes à
          propriedade intelectual e confidencialidade, permanecem válidas de
          forma que o seu descumprimento pode vir a acarretar nas cominações
          legais, civis ou criminais.
        </p>
        <p>
          <b>15.2.</b> As partes elegem o foro de São Paulo para resolução de
          eventuais conflitos que venham a ser originados pelo presente
          instrumento.
        </p>
        <p>
          <b>15.3.</b> Além do que está declarado nesta seção ou conforme
          explicitamente acordado por escrito entre as partes, estes Termos
          constituem todos os termos e condições acordados e substituem
          quaisquer acordos anteriores em relação ao assunto destes Termos,
          sejam eles escritos ou orais. Conforme observado acima, outros termos
          e condições que regem o uso do Serviço são incorporados neste
          documento como referência, incluindo os seguintes termos e condições:
          Política de Armazenamento de Dados e Diretrizes do <b>usuário</b>, que
          podem ser acessados a qualquer momento no site oficial da
          <b>Futuro</b>.
        </p>
      </section>
    </main>
  </div>
</template>

<style lang="scss" scoped>
.container-terms {
  height: 85dvh;
  padding: 1.5rem;
  margin-top: 1rem;
  background-color: aliceblue;
  overflow: scroll;

  header {
    h1 {
      font-size: 24px;
      font-weight: bold;
    }
  }

  section {
    h2 {
      font-size: 16px;
      font-weight: bold;
    }

    p,
    ul,
    li {
      font-size: 14px;
    }
  }
}
</style>
