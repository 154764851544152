<template>
  <div class="box">
    <p>Últimos lançamentos</p>
    <div v-if="transactions">
      <div v-for="(transaction, index) in lastThreeTransactions">
        <div class="transaction-list">
          <ImageContainer
            v-if="transaction.id_transaction_category_data"
            :icon="transaction.id_transaction_category_data.icon"
            :color="transaction.id_transaction_category_data.color"
          />
          <div v-else>
            <ImageContainer :icon="null" :color="null" />
          </div>
          <div class="item-transaction">
            <h1>{{ transaction.description }}</h1>
            <h4>
              {{ transaction.categoryName }} -
              {{ transaction.methodName }}
            </h4>
          </div>
          <div class="item-transaction-amount">
            <span
              :class="{
                'green-cell': transaction.type === 'credit',
                'red-cell': transaction.type === 'debit',
                'blue-cell': transaction.type === 'investment',
                'yellow-cell': transaction.type === 'investment_withdrawal',
              }"
            >
              {{ getTransactionType(transaction.type) }}
            </span>
            <h3
              :class="hideCurrency ? 'blur-effect' : ''"
              style="text-align: right"
            >
              {{ formatValue(transaction.amount) }}
            </h3>
          </div>
        </div>
        <div class="separator" />
      </div>
    </div>
    <div v-else class="text-center my-5">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <div>
      <button class="btn-default" @click="goTo('/cashflow/transactions')">
        Ver todos <span>&#10095;</span>
      </button>
    </div>
  </div>
</template>

<script>
import { useAppStore } from "@/store/store.js";
import { mapActions, mapState } from "pinia";
import ImageContainer from "@/components/shared/ImageContainer.vue";

export default {
  name: "InvestmentBox",
  components: {
    ImageContainer,
  },
  props: {
    transactions: Array,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(useAppStore, ["hideCurrency"]),

    lastThreeTransactions() {
      let last3 = [];
      if (this.transactions != null) {
        last3 = this.transactions.slice(-3);
      }
      return last3;
    },
  },
  methods: {
    formatValue(valor) {
      const appStore = useAppStore();
      return appStore.formatValue(valor);
    },
    getTransactionType(valor) {
      const appStore = useAppStore();
      return appStore.getTransactionType(valor);
    },
    goTo(route) {
      this.$router.push(route);
      this.currentRoute = route;
    },
  },
};
</script>

<style lang="scss" scoped>
/* Seus estilos personalizados aqui */
.box {
  p {
    color: #fff;
    font-weight: bold;
    font-size: 16px;
  }
  .row {
    --bs-gutter-x: 0 !important; // Define explicitamente o valor de --bs-gutter-x como 0
    --bs-gutter-y: 0 !important;
  }
}

.transaction-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  color: #fff;

  i {
    // width: 10%;
    padding: 15px;
    border-radius: 100px;
    background-color: #00ad00;
    margin-right: 10px;
  }
  p {
    margin: 0;
    font-size: 12px;
  }
  h1 {
    font-size: 16px;
    margin: 0;
  }
  h3 {
    margin: 0;
    font-size: 16px;
  }
  h4 {
    font-size: 12px;
    font-weight: 300;
    margin: 0;
  }
}
.item-transaction {
  flex-grow: 1;
  width: 100%;
}

.item-transaction-amount {
  text-align: right;
  span {
    font-size: 13px;
  }
  h3 {
    font-size: 14px;
  }
}
.green-cell {
  color: green !important;
  span {
    color: green !important;
  }
}

.red-cell {
  color: red !important;
  span {
    color: red !important;
  }
}

.blue-cell {
  color: rgb(0, 119, 255) !important;
  span {
    color: rgb(0, 119, 255) !important;
  }
}
</style>
