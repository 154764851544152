<template>
  <div>
    <div class="row row-margin-bottom">
      <div class="header-dash col-lg-10">
        <h1>Categorias</h1>
        <!-- <i class="fas fa-info-circle" /> -->
      </div>
      <div class="col-lg-2">
        <button class="btn-default-bigger" @click="openModal(null)">
          <i class="fa-solid fa-circle-plus" />
          <span> Categoria</span>
        </button>
      </div>
    </div>
    <div class="row">
      <TableCategory :data="categories" :openModal="openModal" />
    </div>
    <!-- <Modal :title="'Nova categoria'" :is-open="isModalOpen" @close="closeModal"> -->
    <Modal :title="(category && category.id) ? 'Editar categoria' : 'Nova categoria'" :is-open="isModalOpen"
      @close="closeModal">
      <div class="form-category form-default container">



        <div class="row">
          <div class="col-2">
            <ImageContainer :icon="category.icon" :color="category.color" />
          </div>
          <div class="col">
            <label for="">Nome</label>
            <input id="name" v-model="category.name" type="text" name="name" class="form-control form-control-sm"
              placeholder="" />
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <label for="">Tipo de gasto</label>
            <select id="type" v-model="category.id_transaction_type" name="id_transaction_type">
              <option v-for="(type, index) in filteredTransactionType" :key="index" :value="type.id">
                {{ type.name }}
              </option>
            </select>

          </div>
        </div>
        <div class="row">
          <label for="">Selecione um ícone</label>
          <div class="icon-container">
            <div v-for="(icon, index) in icons" :key="index" class="icon-item"
              :class="{ selected: icon === selectedIcon }">
              <i :class="icon" @click="selectIcon(icon)" />
            </div>
          </div>
          <label for="">Selecione uma cor</label>
          <div class="icon-container">
            <div v-for="(cor, index) in colors" :key="index" class="cor-box"
              :class="{ selected: cor === selectedColor }" :style="{ backgroundColor: cor }"
              @click="selectColor(cor)" />
          </div>
        </div>
        <button class="mt-3" @click="save">
          <span> {{ (category && category.id) ? 'Editar' : 'Salvar' }} </span>
        </button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "../shared/Modal.vue";
import ImageContainer from "@/components/shared/ImageContainer.vue";
import axios from "axios";
import { useAppStore } from "@/store/store.js";
import TableCategory from "../transaction_category/TableCategory.vue";

export default {
  components: { Modal, TableCategory, ImageContainer },
  props: {},
  data() {
    return {
      plan: "",
      accounts: [],
      isModalOpen: false,
      category: {
        name: "",
        color: null,
        icon: null,
        id_transaction_type: null
      },
      selectedIcon: null,
      selectedColor: null,
      icons: [
        "fas fa-shopping-cart",  // carinho
        "fas fa-utensils", // garfo e faca
        "fas fa-home",  // casa
        "fas fa-car",  //carro
        "fas fa-book", // livro
        "fas fa-medkit", // kit de primeiros socorros
        "fas fa-money-bill", // dinheiro
        "fas fa-plane", // avião
        "fas fa-laptop", // notebook
        "fas fa-gamepad", // controle de videogame
        "fas fa-tshirt", // camiseta
        "fas fa-briefcase", // maleta
        "fas fa-heart", // coração
        "fas fa-graduation-cap",  // capelo
        "fas fa-camera", // câmera
        "fas fa-music", // nota musical
        "fas fa-paint-brush", // pincel
        "fas fa-birthday-cake", // bolo
        "fas fa-tools", // ferramentas
        "fas fa-tree", // árvore
        "fas fa-lightbulb", // lâmpada
        "fas fa-solid fa-money-bill-trend-up", // investimento
        "fas fa-question", // investimento
        // Add more icons as needed
      ],
      colors: [
        "#FF5733", // Vermelho
        "#37BC9B", // Verde
        "#3498DB", // Azul
        "#F39C12", // Laranja
        "#9B59B6", // Roxo
        "#E74C3C", // Vermelho escuro
        "#1ABC9C", // Verde claro
        "#2980B9", // Azul claro
        "#F1C40F", // Amarelo
        "#8E44AD", // Roxo escuro
      ],
    };
  },
  computed: {
    token() {
      const store = useAppStore();
      const token = store.token;

      return token;
    },
    filteredTransactionType() {
      const store = useAppStore();
      const transactionTypeResponse = store.transactionTypeResponse;

      if (!transactionTypeResponse) {
        return [];
      }
      const t = transactionTypeResponse.filter((item) =>![1, 2].includes(item.id)
      );
      return t;
      },
    categories() {
      const store = useAppStore();
      const categories = store.categoriesResponse;

      return categories;
    }
  },
  mounted() {},
  methods: {
    openModal(id = null) {
      if (id) {
      this.fetchCategory(id);
    } else {
      this.resetForm();
    }
      this.isModalOpen = true;
    },
    fetchCategory(id) {
      axios
        .get(`/transaction_category/${id}`, {
          headers: { Authorization: `Bearer ${this.token}` },
        })
        .then((response) => {
          const categoryData = response.data;
          this.category = {
            id: categoryData.id,
            name: categoryData.name,
            color: categoryData.color,
            icon: categoryData.icon,
            id_transaction_type: categoryData.id_transaction_type
          };

          this.selectedIcon = categoryData.icon;
          this.selectedColor = categoryData.color;
        })
        .catch((error) => {
          console.error("Erro ao buscar categoria:", error);
        });
    },
    resetForm() {
      this.category = {
        id: null,
        name: "",
        color: null,
        icon: null
      };
    },
    closeModal() {
      this.isModalOpen = false;
    },
    selectIcon(icon) {
      this.category.icon = icon;
      this.selectedIcon = icon;
    },
    async reloadData() {
      const store = useAppStore();
      await store.getCategories();
    },
    selectColor(cor) {
      this.category.color = cor;
      this.selectedColor = cor;
    },
    save() {
      if (this.category.name === "") {
        this.$toast.add({
          severity: 'error',
          summary: 'Desculpe mas não podemos continuar',
          detail: 'Informe o nome da categoria',
          life: 5000
        });
        return false;
      }

      if (!this.category.id_transaction_type) {
        this.$toast.add({
          severity: 'error',
          summary: 'Desculpe mas não podemos continuar',
          detail: 'Selecione uma tipo de gasto',
          life: 5000
        });
        return false;
      }
      if (this.category.icon === null){
        this.$toast.add({
          severity: 'error',
          summary: 'Desculpe mas não podemos continuar',
          detail: 'Selecione um icone',
          life: 5000
        });
        return false;
      }
      
      if(this.category.color === null) {
        this.$toast.add({
          severity: 'error',
          summary: 'Desculpe mas não podemos continuar',
          detail: 'Selecione uma cor',
          life: 5000
        });
         return false;
      }
      
      


      const method = this.category.id ? 'put' : 'post';
      const url = `/transaction_category${this.category.id ? `/${this.category.id}` : ''}`;

      axios[method](url, this.category, {
        headers: { Authorization: `Bearer ${this.token}` },
      })
      .then((response) => {
        this.resetForm();
        this.reloadData();
        this.closeModal();
      })
      .catch((error) => {
        console.error("Erro ao salvar categoria:", error);
      });
    },

  resetForm() {
    this.category = {
      id: null,
      name: "",
      color: null,
      icon: null
    };
    this.selectedIcon = null;
    this.selectedColor = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.icon-item.selected {
  background-color: #e54f00;
}
.icon-item {
  flex: 0 0 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}
.cor-box {
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  user-select: none;
}

.cor-box.selected {
  border: 2px solid white;
}

.icon-item i {
  color: aliceblue;
  font-size: 24px;
}


</style>