<template>
  <div v-if="goalsBalance && !goalsBalance.loading" class="box">
    <p>Tipos de pagamentos</p>
    <div
      v-if="filteredGoals && filteredGoals.length === 0"
      class="no-goals-message"
    >
      <p>Nenhuma meta encontrada para a opção selecionada</p>
    </div>
    <div v-for="(goal, index) in filteredGoals" class="goal-list">
      <div class="item-goal">
        <h3 v-if="selected == 'category'">
          {{ goal.transaction_category_name }}
        </h3>
        <h3 v-if="selected == 'payment'">
          {{ goal.transaction_method_name }}
        </h3>
        <div class="progress">
          <div
            class="progress-bar"
            role="progressbar"
            :style="{
              width: progress(Math.abs(goal.balance), goal.goal_amount) + '%',
            }"
            :aria-valuenow="Math.abs(goal.balance)"
            aria-valuemin="0"
            :aria-valuemax="goal.goal_amount"
          />
        </div>
        <p>
          {{
            ` ${formatValue(Math.abs(goal.balance))}  de  ${formatValue(
              goal.goal_amount
            )} `
          }}
        </p>
      </div>
    </div>

    <div class="separator" />
    <div class="btn-box-goals">
      <button class="btn-default" @click="goTo('/cashflow/goals')">
        Ver todos <span>&#10095;</span>
      </button>
    </div>
  </div>
</template>

<script>
import ImageContainer from "@/components/shared/ImageContainer.vue";
import { useAppStore } from "@/store/store.js";
export default {
  components: { ImageContainer },

  props: {
    goalsBalance: Object,
  },
  data() {
    return {
      selected: "payment",
      month: null,
      year: null,
    };
  },
  computed: {
    filteredGoals() {
      if (this.selected === "payment") {
        return this.goalsBalance.data.balanceByMethod;
      } else if (this.selected === "category") {
        return this.goalsBalance.data.balanceByCategory;
      }
    },
  },
  created() {},
  methods: {
    progress(currentAmount, amount) {
      let progresso = 0;
      progresso = (currentAmount / amount) * 100;
      return progresso.toFixed(1);
    },
    formatValue(valor) {
      const appStore = useAppStore();
      return appStore.formatValue(valor);
    },
    select(value) {
      this.selected = value;
    },
    goTo(route) {
      this.$router.push(route);
      this.currentRoute = route;
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  flex-direction: column;
  p {
    color: #fff;
    font-weight: bold;
    font-size: 16px;
  }
}

.goal-list {
  display: flex;
  justify-content: center;
  margin: 10px 0;

  p {
    margin: 0;
    font-size: 12px;
  }
  h3 {
    margin: 0;
    font-size: 16px;
  }
}
.item-goal {
  width: 100%;
}
.btn-box-goals {
  text-align: right;
}

.btn-options-overview {
  align-self: center;
  width: 100%;
  border: 1px solid #cb5540;
  border-radius: 5px;
  text-align: center;
  h2 {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    cursor: pointer;
    padding: 10px;
    margin: 0;
  }
  h2:hover {
    font-weight: bold;
  }
}

.selected {
  background: #cb5540;
  border-radius: 5px 0px 0px 5px;

  h2 {
    font-weight: bold !important;
  }
}
.payment-type {
  h4 {
    text-align: left;
    font-size: 14px;
    margin: 0;
  }
  p {
    text-align: left;
    color: #7c87a7;
    font-size: 10px;
  }
}

.progress {
  height: 8px !important;
  background: #ffffff46 !important;
  border-radius: 6px;
  margin: 5px 0;
  --bs-progress-bar-bg: #00ad00 !important;
}

.flex {
  display: flex !important;
}
/* Adicione estilos adicionais conforme necessário */
</style>
