<template>
  <div class="quick-transactions-button">
    <h2>Fazer um lançamento</h2>

    <div class="content">
      <div
        class="btn-box revenue"
        @click="emitModalOpen(true, 'credit')"
      >
        <i class="fas fa-solid fa-circle-plus" />
        <span>Receita </span>
      </div>
      <div
        class="btn-box expenses"
        @click="emitModalOpen(true, 'debit')"
      >
        <i class="fas fa-solid fa-circle-minus" />
        <span>Despesa </span>
      </div>
      <div
        class="btn-box investment"
        @click="emitModalOpen(true, 'investment')"
      >
        <i class="fas fa-solid fa-chart-column" />
        <span>Investimento</span>
      </div>
    </div>
  </div>
</template>

<script>
	export default {
		name: "TransactionsButton",
		data() {
			return {};
		},
		methods:{
			emitModalOpen(value,type) {
				this.$emit("modal-open", value,type);
			},
		}
	};
</script>

<style lang="scss" scoped>
	/* Seus estilos personalizados aqui */
	.quick-transactions-button {
		color: #fff;
		margin-top: 10px;
		border-radius: 7px;
		opacity: 1;
		h2 {
			font-size: 16px;
			margin-bottom: 10px;
		}
	}

	hr {
		border: none; /* Remove a borda padrão */
		height: 4px; /* Define a altura da borda */
		background-color: #181d2f; /* Define a cor da linha */
	}

	.content {
		display: block-inline;
	}

	.btn-box {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 8px;
		margin: 15px 0;
		width: 100%;
		text-align: center;
		color: #fff;
		border-radius: 10px;
		cursor: pointer;
		user-select: none;
		font-size: 12px;

		i {
			font-size: 16px !important;
			margin-right: 10px;
		}
		span {
			font-size: 15px;
			font-weight: 600;
		}
		&:hover {
			transform: scale(
				1.05
			);
		}
	}

	.revenue {
		background-color: #00ad00;
	}
	.expenses {
		background-color: #c62525;
	}
	.investment {
		background-color: #56b0ee;
	}
	p {
		text-align: center;
		font-size: 14px;
		font-weight: 00;
		letter-spacing: 0px;
		color: #f26236;
		opacity: 1;
	}
</style>
