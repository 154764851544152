<template>
  <div class="form-default container">
    <div>
      <p v-if="successMessage" class="success-message">
        {{ successMessage }}
      </p>

      <p v-if="errorMessage" class="error-message">
        {{ errorMessage }}
      </p>
    </div>

    <label class="mt-3">Banco</label>
    <select id="type" v-model="bank_account.id_bank">
      <option v-for="(type, index) in banks" :key="index" :value="type.id">
        {{ type.name }}
      </option>
    </select>


    <label for="">Nome</label>
    <input id="name" v-model="bank_account.name" type="text" name="name" class="form-control form-control-sm"
      placeholder="" />

    <label class="mt-3">Tipo de conta</label>
    <select id="type" v-model="bank_account.id_account_type" class="mb-0">
      <option v-for="(type, index) in type_account" :key="index" :value="type.id" style="color: white;">
        {{ type.label }}
      </option>
    </select>

    <div v-if="bank_account.id_account_type == 3" class="mt-3">
      <label>Saldo atual</label>
      <input id="amount" v-model="bank_account.amount" v-money="moneyConfig" type="text" name="amount"
        class="form-control" placeholder="" />
    </div>
    <div v-if="showDiv" class="row mt-3">
      <div v-if="!isCardAccount" class="row mt-3">
        <div class="col-6">
          <label for="">Agência</label>
          <input id="account_agency" v-model="bank_account.account_agency" type="text" name="account_agency"
            class="form-control form-control-sm" placeholder="" />
        </div>
        <div class="col-6">
          <label for="">Número de conta</label>
          <input id="account_number" v-model="bank_account.account_number" type="text" name="account_number"
            class="form-control form-control-sm" placeholder="" />
        </div>
      </div>
    </div>

    <div v-if="isCardAccount" class="row mt-3">
      <div class="col-6">
        <label for="">Limite do cartão</label>
        <input id="limit" v-model="bank_account.limit" type="text" v-money="moneyConfig" name="limit"
          class="form-control form-control-sm" placeholder="" />
      </div>
      <div class="col-6">
        <label for="">Dia de fechamento</label>
        <input id="closing_day" v-model="bank_account.closing_day" type="text" name="closing_day"
          class="form-control form-control-sm" placeholder="" />
      </div>

    </div>


    <div style="margin-bottom: 15px;" </div>
      <!-- <bank_account-bank v-model="bank_account.id_bank"></bank_account-bank> -->

      <button @click="saveBankAccount">
        <span> Salvar </span>
      </button>
    </div>
</template>

<script>
import axios from "axios";
import { useAppStore } from "@/store/store.js";
import shared from "@/shared";
import { unformat } from "v-money3";

export default {
  props: {
    type_form: {
      type: String, // ou o tipo do seu parâmetro
      required: false, // ou true, se for obrigatório
    },
  },
  watch: {
    'bank_account.id_bank': function (newVal, oldVal) {
      newVal = parseInt(newVal);
      if (newVal == 20) {
        this.showDiv = false;
      } else {
        this.showDiv = true;
      }
    }
  },
  data() {
    return {
      showDiv: true,
      type_form: this.type_form,
      successMessage: "",
      errorMessage: "",
      bank_account: {
        name: "",
        id_account_type: this.type_form,
        account_agency: "",
        account_number: "",
        id_bank: "",
        closing_day: "",
        limit: "",
        amount: ""
      },
      moneyConfig: shared.moneyConfig(),
    };
  },
  computed: {
    isCardAccount() {
      if (this.bank_account.id_account_type == "4") {
        return true;
      } else {
        return false;
      }
    },
    banks() {
      const store = useAppStore();
      const banks = store.bankResponse;

      return banks;
    },
    type_account() {
      const store = useAppStore();
      const acc = store.accountTypeResponse;

      return acc;
    },
    token() {
      const store = useAppStore();
      const token = store.token;

      return token;
    },
  },
  mounted() { },
  methods: {
    async reloadData() {
      const store = useAppStore();
      await store.getBalanceByAccount();
      await store.getAccounts();
    },
    saveBankAccount() {
      for (const key in this.bank_account) {
        this.bank_account[key] = String(this.bank_account[key]);
      }

      this.bank_account.amount = unformat(
        this.bank_account.amount,
        this.moneyConfig
      );

      const saveData = { ...this.bank_account };

      const isFormValid = this.validate()

      if (!isFormValid) {
        return
      }
      // Convert 'limit' to a backend-acceptable format
      if (saveData.limit) {
        saveData.limit = unformat(
          saveData.limit,
          this.moneyConfig
        );
      }

      axios
        .post(`/bank_account`, saveData, {
          headers: { Authorization: `Bearer ${this.token}` },
        })

        .then((response) => {
          this.successMessage = "Conta criada  com sucesso!";
          // Limpar o formulário após o sucesso da postagem
          this.bank_account = {
            name: "",
            id_user: "1",
            id_account_type: "",
            account_agency: "",
            account_number: "",
            id_bank: "",
            closing_day: "",
            limit: "",
            amount: ""
          };
          this.reloadData();
          this.closeModal();
        })
        .catch((error) => {
          this.errorMessage = "Erro ao salvar conta bancária.";
          console.error("Erro ao salvar conta bancária:", error);
        });
    },

    closeModal() {
      this.$emit("close");
    },
    apply() {
      this.closeModal();
    },
    validate() {
      if (!this.bank_account.name) {
        this.$toast.add({
          severity: 'error',
          summary: 'Desculpe mas não podemos continuar',
          detail: 'Informe um nome',
          life: 5000
        });
        return false;
      }
      if (!this.isCardAccount && !this.bank_account.account_agency && this.showDiv == true) {
        this.$toast.add({
          severity: 'error',
          summary: 'Desculpe mas não podemos continuar',
          detail: 'Informe o número da agência',
          life: 5000
        });
        return false;
      }
      if (!this.isCardAccount && !this.bank_account.account_number && this.showDiv == true) {
        this.$toast.add({
          severity: 'error',
          summary: 'Desculpe mas não podemos continuar',
          detail: 'Informe o número da conta',
          life: 5000
        });
        return false;
      }
      if (!this.bank_account.id_bank) {
        this.$toast.add({
          severity: 'error',
          summary: 'Desculpe mas não podemos continuar',
          detail: 'Selecione o banco',
          life: 5000
        })
        return false
      }
      if (this.isCardAccount && !this.bank_account.limit) {
        this.$toast.add({
          severity: 'error',
          summary: 'Desculpe mas não podemos continuar',
          detail: 'Informe o limite do cartão',
          life: 5000
        })
        return false
      }
      if (this.isCardAccount && !this.bank_account.closing_day) {
        this.$toast.add({
          severity: 'error',
          summary: 'Desculpe mas não podemos continuar',
          detail: 'Informe um dia de fechamento',
          life: 5000
        })
        return false
      }
      return true
    }

  },
};
</script>

<style lang="scss" scoped>
/* Adicione estilos conforme necessário */
</style>
