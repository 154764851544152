<template>
  <nav>
    <ul v-if="isWeb" class="web-menu">
      <!-- Menu para web -->
      <li
        @click="checkFeatureDisabled"
        :class="{
          active: isRouteActive('/dashboard'),
        }"
      >
        <router-link :to="{ name: 'dashboard' }">
          <span>Dashboard</span>
        </router-link>
      </li>
      <li
        @click="checkFeatureDisabled"
        :class="{
          active: isRouteActive('/transactions'),
          disabled: featureDisabled,
        }"
      >
        <router-link
          :to="{ name: 'transactions' }"
          :class="{ 'link-disabled': featureDisabled }"
        >
          <span>Lançamentos</span>
        </router-link>
      </li>
      <li
        @click="checkFeatureDisabled"
        :class="{
          active: isRouteActive('/goals'),
          disabled: featureDisabled,
        }"
      >
        <router-link
          :to="{ name: 'goals' }"
          :class="{ 'link-disabled': featureDisabled }"
        >
          <span>Minhas metas</span>
        </router-link>
      </li>
      <li
        @click="checkFeatureDisabled"
        :class="{
          active: isRouteActive('/historic'),
          disabled: featureDisabled,
        }"
      >
        <router-link
          :to="{ name: 'historic' }"
          :class="{ 'link-disabled': featureDisabled }"
        >
          <span>Meu histórico</span>
        </router-link>
      </li>
      <li
        @click="checkFeatureDisabled"
        :class="{
          active: isRouteActive('/settings'),
          disabled: featureDisabled,
        }"
      >
        <router-link
          :to="{ name: 'settings' }"
          :class="{ 'link-disabled': featureDisabled }"
        >
          <span>Contas, cartões e categorias</span>
        </router-link>
      </li>
      <li class="d-md-flex align-items-center d-none">
        <span class="eye-icon" @click="showCurrency()">
          <i
            :class="
              hideCurrency ? 'fa-regular fa-eye-slash' : 'fa-regular fa-eye'
            "
          ></i>
        </span>
      </li>
    </ul>

    <div v-else class="mobile-menu">
      <!-- Menu para dispositivos móveis -->
      <div class="dropdown d-flex justify-content-between align-items-center">
        <button class="dropdown-toggle" @click="toggleMenu">
          <p>
            {{
              selectedMobileRoute
                ? getPageTitle(selectedMobileRoute)
                : "Selecione uma opção"
            }}
          </p>
        </button>
        <ul class="dropdown-menu" :class="{ 'show-menu': isMenuOpen }">
          <li
            v-for="route in mobileRoutes"
            :key="route.path"
            :class="{ active: isRouteActive(route.path) }"
          >
            <router-link :to="route.path" @click="toggleMenu">
              <span>{{ getPageTitle(route.path) }}</span>
            </router-link>
          </li>
        </ul>
        <span class="eye-icon text-white" @click="showCurrency()">
          <i
            :class="
              hideCurrency ? 'fa-regular fa-eye-slash' : 'fa-regular fa-eye'
            "
          ></i>
        </span>
      </div>
    </div>
  </nav>
</template>

<script>
import { useAppStore } from "@/store/store.js";
import { mapActions, mapState } from "pinia";
export default {
  name: "NavMenu",
  data() {
    return {
      isMenuOpen: false,
      isWeb: false,
      selectedMobileRoute: "",
      mobileRoutes: [
        { path: "/cashflow/dashboard", title: "Dashboard" },
        { path: "/cashflow/transactions", title: "Lançamentos" },
        { path: "/cashflow/goals", title: "Minhas metas" },
        { path: "/cashflow/historic", title: "Meu histórico" },
        { path: "/cashflow/settings", title: "Contas e categorias" },
      ],
    };
  },
  watch: {
    // Observa a mudança da rota atual
    $route(to, from) {
      this.selectedMobileRoute = to.path;
    },
  },
  computed: {
    ...mapState(useAppStore, ["hideCurrency"]),

    featureDisabled() {
      const store = useAppStore();
      return !store.hasCashFlowAccess();
    },
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
    this.setCurrentRoute();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
  methods: {
    ...mapActions(useAppStore, ["showCurrency"]),

    isRouteActive(route) {
      return this.$route.path === route;
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    checkScreenSize() {
      this.isWeb = window.innerWidth > 767;
    },
    getPageTitle(route) {
      const foundRoute = this.mobileRoutes.find((r) => r.path === route);
      return foundRoute ? foundRoute.title : "";
    },
    setCurrentRoute() {
      this.selectedMobileRoute = this.$route.path;
    },
    checkFeatureDisabled() {
      if (this.featureDisabled) {
        this.$toast.add({
          severity: "warn",
          summary: "Recurso indisponível",
          detail: "Essa ferramenta não está incluída no plano contratado.",
          life: 5000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  background: #283045 0% 0% no-repeat padding-box;
  border-radius: 7px;
  opacity: 1;
  margin: 20px 0;
  padding: 0 20px;
}
.web-menu {
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;

  li {
    display: inline-block;
    font-size: 14px;
    color: #fff;
  }

  a {
    color: #fff;
    padding: 10px;
    text-decoration: none;
  }

  li.active {
    font-weight: bold;
  }
  .router-link-active {
    background: #f2623669 0% 0% no-repeat padding-box;
    border-radius: 4px;
    color: #fff;

    border-bottom: 4px solid #c65440;
  }
}

.mobile-menu {
  display: none;

  ul {
    background-color: #181d2f;
    border: none;
    box-shadow: 2px 2px 4px 2px rgba(133, 131, 131, 0.2);
  }

  li {
    margin-bottom: 5px;
  }

  a {
    color: #fff;
    padding: 10px;
    text-decoration: none;
  }

  router-link-active {
    background: #f2623669 0% 0% no-repeat padding-box;
    border-radius: 4px;
    color: #fff;
  }
}

.show-menu {
  display: block;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #fff;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  p {
    padding: 10px;
    background: #f2623669 0% 0% no-repeat padding-box;
    border-radius: 4px;
    color: #fff;
    font-weight: bold;
    margin: 0;
  }
}

.arrow-down {
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #fff transparent transparent transparent;
  margin-left: 5px;
}

@media (max-width: 767px) {
  .web-menu {
    display: none;
  }

  .mobile-menu {
    display: block;
  }
}

.disabled {
  opacity: 0.4;
}
.link-disabled {
  pointer-events: none;
}

.eye-icon {
  font-size: 21px;
  cursor: pointer;
}
</style>
