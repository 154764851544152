<template>
  <div class="header-dash">
    <h1>Histórico</h1>
    <!-- <i class="fas fa-info-circle" /> -->
  </div>
  <div class="box content-main">
    <YearFilter
      class="mb-3 mt-2"
      v-if="selected == 'transaction'"
      @selectedYear="selectedYear"
    />
    <MonthFilter
      v-if="selected == 'category' || selected == 'payment'"
      class="mb-3 mt-2"
      :years="months"
      @selectedMonth="selectedMonth"
    />

    <div class="separator" />
    <div class="btn-options">
      <h2
        :class="{ selected: selected == 'transaction' }"
        @click="select('transaction')"
      >
        Gastos
      </h2>
      <h2
        :class="{ selected: selected == 'payment' }"
        @click="select('payment')"
      >
        Formas de pagamento
      </h2>
      <h2
        :class="{ selected: selected == 'category' }"
        @click="select('category')"
      >
        Categorias
      </h2>
      <!--	<h2
					@click="select('investment')"
					:class="{ selected: selected == 'investment' }"
				>
					Investimento
				</h2> -->
    </div>
    <div class="separator" />
    <div v-if="selected == 'transaction'" class="container">
      <TransactionMode
        v-if="historicBalance && !historicBalance.loading"
        :data="historicBalance.data.data"
      />
      <div v-else class="loading">
        <i class="fas fa-spinner fa-spin" /> Loading...
      </div>
    </div>
    <MethodMode
      v-if="selected == 'payment' && !loadingBalanceGoals"
      :data="balanceMethodsResponse"
      :financial_goal="methodFinancialGoalsResponse"
    />
    <CategoryMode
      v-else-if="selected == 'category' && !loadingBalanceGoals"
      :data="balanceCategoriesResponse"
      :financial_goal="categoryFinancialGoalsResponse"
    />

    <div v-else-if="selected !== 'transaction'" class="loading-min">
      <i class="fas fa-spinner fa-spin" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useAppStore } from "@/store/store.js";
import YearFilter from "@/components/transaction/YearFilter.vue";
import CategoryMode from "@/components/transaction_category/CategoryMode.vue";
import TransactionMode from "@/components/transaction/TransactionMode.vue";
import MonthFilter from "@/components/shared/MonthFilter.vue";
import MethodMode from "@/components/transaction_method/MethodMode.vue";

export default {
  components: {
    YearFilter,
    TransactionMode,
    CategoryMode,
    MonthFilter,
    MethodMode,
  },
  props: {
    balanceAcc2: Object,
    financial_goals: Object,
    goalByCategories: Object,
    balanceByCategory: Object,
  },
  data() {
    return {
      historicCategory: [],
      yearSelected: new Date().getFullYear(),
      selected: "transaction",
    };
  },
  computed: {
    loadingBalanceGoals() {
      const store = useAppStore();
      const loadingBalanceGoals = store.loadingBalanceGoals;

      return loadingBalanceGoals;
    },
    totalDebitBalanceByGoal() {
      let totalDebit = 0;

      this.financial_goals.forEach((transaction) => {
        // Verifica se o id_transaction_type é igual a 1 ou 2
        if (
          transaction.id_transaction_category_data != null &&
          (transaction.id_transaction_category_data.id_transaction_type ==
            "1" ||
            transaction.id_transaction_category_data.id_transaction_type == "2")
        ) {
          const debit = parseFloat(transaction.amount);
          if (!isNaN(debit)) {
            totalDebit += debit;
          }
        }
      });

      return totalDebit;
    },
    historic() {
      return this.balanceAcc2;
    },
    historicBalance() {
      const store = useAppStore();
      const historicBalance = store.historicBalance;
      return historicBalance;
    },
    categories() {
      const store = useAppStore();
      const categories = store.categoriesResponse;

      return categories;
    },
    balanceCategoriesResponse() {
      const store = useAppStore();
      const balanceCategoriesResponse = store.balanceCategoriesResponse;

      return balanceCategoriesResponse;
    },
    balanceMethodsResponse() {
      const store = useAppStore();
      const balanceMethodsResponse = store.balanceMethodsResponse;

      return balanceMethodsResponse;
    },
    categoryFinancialGoalsResponse() {
      const store = useAppStore();
      const categoryFinancialGoalsResponse =
        store.categoryFinancialGoalsResponse;

      return categoryFinancialGoalsResponse;
    },
    methodFinancialGoalsResponse() {
      const store = useAppStore();
      const methodFinancialGoalsResponse = store.methodFinancialGoalsResponse;

      return methodFinancialGoalsResponse;
    },
    balanceByMonth() {
      const store = useAppStore();
      const balanceByMonth = store.balanceByMonth;

      return balanceByMonth;
    },
    goalByMonth() {
      const store = useAppStore();
      const goalByMonth = store.goalByMonth;

      return goalByMonth;
    },
    token() {
      const store = useAppStore();
      const token = store.token;

      return token;
    },
    months() {
      const months = [
        "jan",
        "fev",
        "mar",
        "abr",
        "mai",
        "jun",
        "jul",
        "ago",
        "set",
        "out",
        "nov",
        "dez",
      ];

      const today = new Date();
      const todayYear = today.getFullYear();

      const years = [];
      for (let i = todayYear - 10; i <= todayYear + 10; i++) {
        years.push(i);
      }

      let monthsAndYears2 = [];
      years.map((year) => {
        for (let month = 0; month < 12; month++) {
          monthsAndYears2.push(`${months[month]}/${year}`);
        }
      });

      return monthsAndYears2;
    },
    Years() {
      let years = this.historic.map((data) => data.year);
      return years;
    },
    filtredHistoric() {
      if (!this.yearSelected) {
        return this.historic;
      }

      return this.historic.filter((data) => data.year == this.yearSelected);
    },
    filtredHistoricCategory() {
      if (!this.yearSelected) {
        return this.balanceByCategory;
      }

      return this.balanceByCategory.filter(
        (data) => data.year == this.yearSelected
      );
    },
    totalArray() {
      // Função para somar os débitos e créditos por mês e ano
      function sumDebitsAndCreditsByMonthAndYear(array) {
        return array.reduce((totals, obj) => {
          const key = `${obj.month}-${obj.year}`;
          const debit = parseFloat(obj.debit);
          const credit = parseFloat(obj.credit);

          if (!totals[key]) {
            totals[key] = { debit: 0, credit: 0, month: obj.month };
          }

          totals[key].debit += debit;
          totals[key].credit += credit;

          return totals;
        }, {});
      }

      const totalsByMonthAndYear = sumDebitsAndCreditsByMonthAndYear(
        this.filtredHistoric
      );

      // Converter o objeto em um array de pares chave-valor e ordená-lo pelo mês
      const sortedArray = Object.entries(totalsByMonthAndYear).sort((a, b) => {
        const [monthA, yearA] = a[0].split("-");
        const [monthB, yearB] = b[0].split("-");

        // Compara primeiro os anos e, em seguida, os meses
        return yearA - yearB || monthA - monthB;
      });

      // Criar um novo objeto a partir do array ordenado
      const sortedTotalsByMonthAndYear = Object.fromEntries(sortedArray);

      return sortedTotalsByMonthAndYear;
    },
    totalArrayCategory() {
      // Função para somar os débitos e créditos por mês e ano
      function sumDebitsAndCreditsByMonthAndYear(array) {
        return array.reduce((totals, obj) => {
          const key = `${obj.id_transaction_category}`;
          const debit = parseFloat(obj.debit);
          const credit = parseFloat(obj.credit);
          const balance = parseFloat(obj.balance);

          if (!totals[key]) {
            totals[key] = {
              debit: 0,
              credit: 0,
              balance: 0,
              transaction_category_name: obj.transaction_category_name,
              id_bank_account: obj.id_bank_account,
              id_transaction_type: obj.id_transaction_type,
              transaction_category_color: obj.transaction_category_color,
              transaction_category_icon: obj.transaction_category_icon,
              id_transaction_category: obj.id_transaction_category,
              amountByGoal: 0, // Inicialmente definido como 0
            };
          }

          totals[key].debit += debit;
          totals[key].credit += credit;
          totals[key].balance += balance;

          return totals;
        }, {});
      }

      const totalsByMonthAndYear = sumDebitsAndCreditsByMonthAndYear(
        this.filtredHistoricCategory
      );

      // Converter o objeto em um array de pares chave-valor e ordená-lo pelo mês
      const sortedArray = Object.entries(totalsByMonthAndYear).sort((a, b) => {
        const [monthA, yearA] = a[0].split("-");
        const [monthB, yearB] = b[0].split("-");

        // Compara primeiro os anos e, em seguida, os meses
        return yearA - yearB || monthA - monthB;
      });

      // Criar um novo objeto a partir do array ordenado
      const sortedTotalsByMonthAndYear = Object.fromEntries(sortedArray);

      // Relacionar com goalByCategories utilizando o id_transaction_category
      const finalArray = Object.values(sortedTotalsByMonthAndYear).map(
        (values) => {
          // Encontrar o objetivo correspondente em goalByCategories usando o id_transaction_category
          const goal = this.goalByCategories.find(
            (goal) =>
              goal.id_transaction_category === values.id_transaction_category
          );

          return {
            ...values,
            balance: Math.abs(values.balance),
            amountByGoal: goal ? parseFloat(goal.amount) : 0,
          };
        }
      );

      return finalArray;
    },
  },
  created() {
    this.getFinancialGoals();
    this.loadData();
  },
  methods: {
    loadData() {
      const store = useAppStore();

      if (!store.historicBalance.loaded) {
        store.getHistoricBalance({ year: new Date().getFullYear() });
      }
    },

    selectedMonth(month) {
      const mes = month[0];

      const meses = {
        jan: 1,
        fev: 2,
        mar: 3,
        abr: 4,
        mai: 5,
        jun: 6,
        jul: 7,
        ago: 8,
        set: 9,
        out: 10,
        nov: 11,
        dez: 12,
      };
      this.month = meses[mes.toLowerCase()];
      this.year = month[1];

      this.getFinancialGoals();
    },
    async getFinancialGoals() {
      const store = useAppStore();
      await store.getFinancialGoals(this.month, this.year);
    },
    getHistoryCategory() {
      axios
        .get(`/balance_by_category`, {
          headers: { Authorization: `Bearer ${this.token}` },
        })
        .then((response) => {
          this.historicCategory = response.data;
        });
    },
    select(value) {
      this.selected = value;
    },

    selectedYear(year) {
      this.yearSelected = year;
      const store = useAppStore();

      store.getHistoricBalance({ year: this.yearSelected });
    },
  },
};
</script>

<style scoped>
/* Estilos específicos do HistoricView */
.container {
  --bs-gutter-x: 0 !important;
  --bs-gutter-y: 0 !important;
}

.content-main {
  padding: 10px;
}
.selected {
  background: #cb5540 0% 0% no-repeat padding-box;
  border-radius: 4px;

  h2 {
    font-weight: bold !important;
  }
}
.loading-min {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
  font-size: 24px;
  color: #f3eff1;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  color: #283045;
}
</style>
