<template>
  <div class="container">
    <table class="table custom-table categories">
      <thead>
        <tr>
          <th>#</th>
          <th>Ícone</th>
          <th>Nome</th>
          <th>Tipo de gasto</th>
          <th>Ações</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(dado, index) in data" :key="index" :class="[index % 2 == 0 ? 'row-even' : 'row-odd']">
          <!--index % 2 == 0 ? 'row-even' : 'row-odd'-->
          <td>{{ index + 1 }}</td>
          <td>
            <ImageContainer :icon="dado.icon" :color="dado.color" />
          </td>
          <td>{{ dado.name || '-' }}</td>
          <td>
            <span :class="['badge', getColorClass(dado)]">
              {{ dado?.id_transaction_type_data?.name || '-' }}
            </span>
          </td>
          <td>
            <i v-if="dado.id_user != 0" class="fa-solid fa-pen edit-icon" @click="openModal(dado.id)" />
            <i v-if="dado.id_user != 0" class="fa-solid fa-trash delete-icon" @click="confirmDelete(dado.id)" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <Toast />
</template>


<script>
import { useAppStore } from "@/store/store.js";
import ImageContainer from "@/components/shared/ImageContainer.vue";
import axios from "axios";
import { useConfirm } from 'primevue/useconfirm';

export default {
  components: { ImageContainer },
  props: {
    data: {
      type: Object,
      required: true
    },
    openModal: {
      type: Function,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    token() {
      const store = useAppStore();
      const token = store.token;

      return token;
    },
  },
  setup() {
    return { confirm: useConfirm() }
  },
  methods: {
    async reloadData() {
      const store = useAppStore();
      await store.getCategories();
    },
    getColorClass(dado) {
      const name = dado?.id_transaction_type_data?.name;
      if (name === 'Entrada') {
        return 'text-bg-success';
      } else if (name === 'Despesas') {
        return 'text-bg-danger';
      } else if (name === 'Investimento') {
        return 'text-bg-info';
      }
      return '';
    },
    confirmDelete(id) {
      this.confirm.require({
        message: 'Você tem certeza que deseja excluir a categoria?',
        header: 'Deletar',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Deletar',
        rejectLabel: 'Voltar',
        accept: () => {
          this.deleteCategory(id);
        }
      });
    },
    deleteCategory(id) {
      axios
        .delete(`/transaction_category/${id}`, {
          headers: { Authorization: `Bearer ${this.token}` }
        })
        .then(() => {
          this.$toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Categoria deletada com sucesso',
            life: 3000
          });
          this.reloadData();
        })
        .catch(error => {
          console.error('Error deleting category:', error.response);
          if (error.response.status == 400) {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Erro ao deletar. A catergoria não esta vazia.',
              life: 5000
            });
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Houve um erro ao deletar a categoria',
              life: 5000
            });
          }
        });
    },
    formatValue(valor) {
      const appStore = useAppStore();
      return appStore.formatValue(valor);
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-icon {
  margin-right: 10px;
  cursor: pointer;
}

.delete-icon {
  cursor: pointer;
}


.categories tr{
  border:1px solid #000;
}

.green {
  background-color: rgb(120, 175, 120);
}

.red {
  background-color: #cc7a82;

}

.blue {
  background-color: rgb(131, 131, 206);
}


.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}
.cell {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}
</style>
