<template>
  <div>
    <div class="chart-box-historic row">
      <DoughnutHistoricChart
        class="col-lg"
        :type="'credit'"
        :total-goal="creditTotalGoal"
        :amount="totalCredit"
      />
      <DoughnutHistoricChart
        :type="'debit'"
        class="col-lg"
        :total-goal="debitTotalGoal"
        :amount="totalDebit"
      />
      <DoughnutHistoricChart
        :type="'investment'"
        class="col-lg"
        :total-goal="investmentTotalGoal"
        :amount="totalBalanceInvestment"
      />
    </div>

    <h2 class="green-cell">
      Receitas
    </h2>

    <TableHistoricCategory :data="creditCategories" />
    <h2 class="red-cell">
      Despesas
    </h2>
    <TableHistoricCategory
      :type="'debit'"
      :data="debitCategories"
    />
    <h2 class="blue-cell">
      Investimentos
    </h2>
    <TableHistoricCategory
      :type="'investment'"
      :data="investmentCategories"
    />
    <div class="row">
      <!-- <TotalBox
				:type="'historic'"
				:transactions="data"
				class="col-lg-4"
			></TotalBox> -->
    </div>
  </div>
</template>

<script>
import { useAppStore } from "@/store/store.js";
import TableHistoricCategory from "../transaction_category/TableHistoricCategory.vue";
import DoughnutHistoricChart from "../transaction_category/DoughnutHistoricChart.vue";
import { INVESTMENT } from "../../constants/account_types"; 
export default {
  components: {
    TableHistoricCategory,
    DoughnutHistoricChart,
  },
  props: {
    data: Object,
    financial_goal: Object,
  },
  data() {
    return {};
  },
  computed: {
    categoriesWithGoals() {
      if (this.financial_goal) {
        const categoriasComGoals = this.data.map((categoria) => {
          const goal = this.financial_goal.find(
            (goal) =>
              goal.id_transaction_category === categoria.id_transaction_category
          );

          return {
            ...categoria,
            goal_amount: goal ? goal.goal_amount : null,
          };
        });
        return categoriasComGoals;
      } else {
        return [];
      }
    },
    debitTotalGoal() {
      const total = this.debitCategories.reduce((total, category) => {
        const goalAmount =
          category.goal_amount !== null ? parseFloat(category.goal_amount) : 0;
        return total + goalAmount;
      }, 0);
      return isNaN(total) ? 0 : total;
    },

    creditTotalGoal() {
      const total = this.creditCategories.reduce((total, category) => {
        const goalAmount =
          category.goal_amount !== null ? parseFloat(category.goal_amount) : 0;
        return total + goalAmount;
      }, 0);
      // Verificar se total é NaN e retornar 0 se for
      return isNaN(total) ? 0 : total;
    },

    investmentTotalGoal() {
      const total = this.investmentCategories.reduce((total, category) => {
        return total + parseFloat(category.goal_amount);
      }, 0);

      // Verificar se total é NaN e retornar 0 se for
      return isNaN(total) ? 0 : total;
    },

    debitCategories() {
      const filteredCategories = Object.values(this.categoriesWithGoals).filter(
        (category) => category.debit > 0 && category.id_transaction_type !== INVESTMENT
      );

      const adjustedCategories = filteredCategories.map((category) => ({
        ...category,
        balance: category.debit,
      }));

      return adjustedCategories;
    },

    creditCategories() {
      const filteredCategories = Object.values(this.categoriesWithGoals).filter(
        (category) => category.credit > 0 && category.id_transaction_type !== INVESTMENT
      );

      const adjustedCategories = filteredCategories.map((category) => ({
        ...category,
        balance: category.credit,
      }));
      return adjustedCategories;
    },
    investmentCategories() {
      const cat = Object.values(this.categoriesWithGoals).filter(
        (acc) => acc.id_transaction_type == INVESTMENT
      );

      return cat;
    },
    totalDebit() {
      // Calculate the total debit by summing the debit amounts of each category
      return Object.values(this.debitCategories).reduce(
        (total, category) => total + parseFloat(Math.abs(category.balance)),
        0
      );
    },
    totalBalanceInvestment() {
      // Calculate the total debit by summing the debit amounts of each category
      return Object.values(this.investmentCategories).reduce(
        (total, category) => total + parseFloat(category.balance),
        0
      );
    },

    totalCredit() {
      // Calculate the total credit by summing the credit amounts of each category
      return Object.values(this.creditCategories).reduce(
        (total, category) => total + parseFloat(category.balance),
        0
      );
    },
  },
  methods: {},
};
</script>

<style scoped>
/* Estilos específicos do HistoricView */
h2 {
  font-size: 18px;
  font-weight: bold;
}
</style>
