<template>
  <div class="btn-options position-relative">
    <h2
      class=""
      :class="{ selected: selected == 'accounts' }"
      @click="select('accounts')"
    >
      Contas e Cartões
    </h2>
    <h2
      class=""
      :class="{ selected: selected == 'category' }"
      @click="select('category')"
    >
      Categorias
    </h2>

    <div class="brl-to-usd position-absolute d-flex">
      <button
        :class="['brazil', !isBrlToUsd && !isBrlToEur ? 'active-coin' : '']"
        @click="openModal('brl')"
      >
        <img src="../../assets/brazil-brl.svg" alt="brl" />
      </button>
      <button
        :class="['usa', isBrlToUsd ? 'active-coin' : '']"
        @click="openModal('usd')"
      >
        <img src="../../assets/usa-usd.svg" alt="dolar" />
      </button>
      <button
        :class="['ue', isBrlToEur ? 'active-coin' : '']"
        @click="openModal('eur')"
      >
        <img src="../../assets/ue-eur.svg" alt="euro" />
      </button>
    </div>
  </div>

  <AccountMode v-if="selected == 'accounts'" />
  <CategorySetMode v-if="selected == 'category'" :categories="categories" />
  <Modal title="Escolha a moeda" :is-open="isModalOpen" @close="closeModal">
    <div class="d-flex align-items-center justify-content-center flex-column">
      <img
        :src="modalConditions.img"
        :alt="modalConditions.coin"
        style="width: 100px; height: 100px"
        loading="lazy"
      />
      <h3 class="text-white text-center mt-3 mb-4">
        Deseja ter o <b>{{ modalConditions.coin }}</b> como moeda padrão?
      </h3>
    </div>

    <button class="my-2 button-modal" @click="saveCoin()">
      <span> Salvar </span>
    </button>
  </Modal>
</template>

<script>
import AccountMode from "@/components/bank_account/AccountMode.vue";
import CategorySetMode from "@/components/transaction_category/CategorySetMode.vue";
import { useAppStore } from "@/store/store.js";
import { mapActions, mapState } from "pinia";
import Modal from "@/components/shared/Modal.vue";
export default {
  components: { AccountMode, CategorySetMode, Modal },
  props: {
    categories: Object,
  },
  data() {
    return {
      plan: "",
      selected: "accounts",
      isModalOpen: false,
      coin: "",
    };
  },
  computed: {
    isBrlToUsd() {
      const store = useAppStore();
      const coin = store.isBrlToUsd;
      return coin;
    },

    isBrlToEur() {
      const store = useAppStore();
      const coin = store.isBrlToEur;
      return coin;
    },

    currencyType() {
      const store = useAppStore();
      const currency = store.userResponse?.data?.userFastapp?.currency_type;
      return currency;
    },

    modalConditions() {
      const coinMap = {
        brl: { img: "../../assets/brazil-brl.svg", coin: "real" },
        usd: { img: "../../assets/usa-usd.svg", coin: "dólar" },
        eur: { img: "../../assets/ue-eur.svg", coin: "euro" },
      };
      return coinMap[this.coin] || { img: "", coin: "" };
    },
  },
  mounted() {},
  methods: {
    ...mapActions(useAppStore, ["changeRate", "updateCurrencyType"]),

    select(value) {
      this.selected = value;
    },

    saveCoin() {
      this.changeRate(this.coin);
      this.closeModal();
    },

    openModal(value) {
      const isSelected = {
        usd: this.isBrlToUsd,
        eur: this.isBrlToEur,
        brl: !this.isBrlToEur && !this.isBrlToUsd,
      };

      if (isSelected[value]) {
        this.$toast.add({
          severity: "warn",
          summary: "Moeda já selecionada",
          detail: `O ${
            value === "usd" ? "dólar" : value === "eur" ? "euro" : "real"
          } já está selecionado como moeda padrão`,
          life: 5000,
        });
      } else {
        this.isModalOpen = true;
        this.coin = value;
      }
    },

    closeModal() {
      this.isModalOpen = false;
    },
  },
};
</script>

<style lang="scss">
.brl-to-usd {
  cursor: pointer;
  right: 3%;
  .brazil,
  .usa,
  .ue {
    border: none;
    background-color: transparent;
    padding: 0.25rem 0.5rem;
    img {
      width: 30px;
      height: 30px;
    }
  }

  .brazil {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .ue {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.active-coin {
  background-color: #cb5540 !important;
}

.button-modal {
  background-color: #e54f00;
  border-radius: 5px;
  border: none;
  color: #fff;
  padding: 5px;
  font-size: 14px;
}
</style>
