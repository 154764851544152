<template>
  <div class="custom-container">
    <div class="header-dash">
      <h1>Lançamentos</h1>
    </div>

    <div class="row main-content">
      <div class="col-lg-8">
        <div class="table-box">
          <MonthFilter
            class="mt-0"
            :years="Years"
            @selectedMonth="selectedMonth"
          />
          <div class="separator" />
          <div v-if="!loadingTransactions">
            <TransactionsFilter
              :transactions="transactions"
              @input-change="handleInput"
              @tags-change="handleTags"
              :tags="tags"
            />
            <TableTransactions
              :transactions="filteredTransactions"
              :visualization="viewSelected"
              @open="openModalOff"
            />
          </div>
          <div v-else class="loading-min">
            <i class="fas fa-spinner fa-spin" />
          </div>

          <div
            v-if="!loadingTransactions && filteredTransactions.length == 0"
            class="no-goals-message"
          >
            <p>Você ainda não possui transações cadastradas neste mês.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="side-box">
          <TotalBox
            :loading="loadingTransactions"
            :transactions="filteredTransactions"
          />
          <div class="separator" />
          <TransactionButtons @modal-open="openModal" />
          <!-- <div class="separator" /> -->
          <!-- <VisualizationMethod @option-selected="handleOptionSelected" /> -->
        </div>
      </div>
    </div>
    <ModalOffCanva
      id="ModalFormFilter"
      :title="'Detalhes do lançamento'"
      :is-open="isModalOffOpen"
      @close="closeModalOff"
    >
      <DetailTransaction
        :record="transactionDetail"
        @close="closeModalOff"
        @reload="getTransactions"
      />
    </ModalOffCanva>
    <ModalOffCanva
      id="ModalFormFilter"
      :title="'Detalhes do lançamento'"
      :is-open="isModalEditInvestimentOpen"
      @close="closeModalOff"
    >
      <DetailInvestment
        :record="transactionDetail"
        @close="closeModalOff"
        @reload="getTransactions"
      />
    </ModalOffCanva>
    <Modal
      :title="`Novo lançamento - ${getTransactionType(typeForm)}`"
      :is-open="isModalOpen"
      @close="closeModal"
    >
      <FormTransaction
        :type-form="typeForm"
        :quick="isQuick"
        @close="closeModal"
        @reload="getTransactions"
      />
    </Modal>
  </div>
</template>

<script>
import VisualizationMethod from "@/components/transaction/VisualizationMethod.vue";
import TableTransactions from "@/components/transaction/TableTransactions.vue";
import TotalBox from "@/components/transaction/TotalBox.vue";
import MonthFilter from "@/components/shared/MonthFilter.vue";
import TransactionsFilter from "@/components/transaction/TransactionsFilter.vue";
import TransactionButtons from "@/components/transaction/TransactionButtons.vue";
import Modal from "@/components/shared/Modal.vue";
import ModalOffCanva from "@/components/shared/ModalOffCanva.vue";
import DetailTransaction from "@/components/transaction/DetailTransaction.vue";
import DetailInvestment from "../../components/transaction/DetailInvestment.vue";
import { useAppStore } from "@/store/store.js";
import FormTransaction from "@/components/transaction/FormTransaction.vue";
import * as transactionTypes from "@/constants/transaction_types.js";

export default {
  components: {
    TableTransactions,
    TotalBox,
    MonthFilter,
    DetailTransaction,
    TransactionButtons,
    ModalOffCanva,
    Modal,
    FormTransaction,
    VisualizationMethod,
    TransactionsFilter,
    DetailInvestment,
  },
  props: {},
  data() {
    return {
      viewSelected: "category",
      searchQuery: "",
      month: "",
      tags: [],
      year: null,
      monthNumber: null,
      isModalOpen: false,
      isQuick: true,
      isModalOffOpen: false,
      isModalEditInvestimentOpen: false,
      transactionDetail: {},
      typeForm: "",
    };
  },
  computed: {
    filteredTransactionsQuery() {
      const searchQuery = this.searchQuery.toLowerCase();
      if (!searchQuery) {
        return this.transactions;
      }
      const filtered = this.transactions.filter((transaction) => {
        const description = transaction.description.toLowerCase();
        const amount = transaction.amount.toString().toLowerCase();
        return (
          description.includes(searchQuery) || amount.includes(searchQuery)
        );
      });
      return filtered;
    },
    filteredTransactions() {
      if (this.tags.length === 0) {
        return this.filteredTransactionsQuery;
      }
      let filter = this.filteredTransactionsQuery;
      const tagsAux = this.tags;
      filter = filter.filter((transaction) => {
        return tagsAux.every((tag) => {
          if (tag === "Sem Categoria") {
            return (
              null === transaction.id_transaction_category ||
              tag === transaction.id_transaction_method_data?.name ||
              tag === transaction.type ||
              tag === transaction.id_bank_account_data?.name ||
              tag === transaction.id_transaction_type_data.name
            );
          } else {
            return (
              tag === transaction.id_transaction_category_data?.name ||
              tag === transaction.id_transaction_method_data?.name ||
              tag === transaction.type ||
              tag === transaction.id_bank_account_data?.name ||
              tag === transaction.id_transaction_type_data.name
            );
          }
        });
      });
      return filter;
    },

    loadingTransactions() {
      const store = useAppStore();
      const loadingTransactions = store.loadingTransactions;

      return loadingTransactions;
    },
    transactions() {
      const store = useAppStore();
      const transactionResponse = store.transactionResponse;

      return transactionResponse;
    },

    Years() {
      const months = [
        "jan",
        "fev",
        "mar",
        "abr",
        "mai",
        "jun",
        "jul",
        "ago",
        "set",
        "out",
        "nov",
        "dez",
      ];

      const today = new Date();
      const todayYear = today.getFullYear();

      const years = [];
      for (let i = todayYear - 10; i <= todayYear + 10; i++) {
        years.push(i);
      }

      let monthsAndYears2 = [];
      years.map((year) => {
        for (let month = 0; month < 12; month++) {
          monthsAndYears2.push(`${months[month]}/${year}`);
        }
      });

      return monthsAndYears2;
    },
  },
  created() {},
  methods: {
    handleInput(searchQuery) {
      this.searchQuery = searchQuery;
    },
    async getTransactions() {
      const store = useAppStore();
      await store.getTransactions(this.monthNumber, this.year);
    },
    openModalOff(value) {
      this.transactionDetail = value;
      if (
        value.type === transactionTypes.INVESTMENT ||
        value.type === transactionTypes.INVESTMENT_WITHDRAWAL
      ) {
        this.isModalEditInvestimentOpen = true;
      } else {
        this.isModalOffOpen = true;
      }
    },
    openModal(value, type) {
      this.typeForm = type;
      this.isQuick = true;
      this.isModalOpen = true;
    },
    closeModalOff() {
      this.isModalOffOpen = false;
      this.isModalEditInvestimentOpen = false;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    getTransactionType(valor) {
      const appStore = useAppStore();
      return appStore.getTransactionType(valor);
    },
    handleOptionSelected(selectedValue) {
      this.viewSelected = selectedValue;
    },
    monthNumberToString(number) {
      const months = [
        "jan",
        "fev",
        "mar",
        "abr",
        "mai",
        "jun",
        "jul",
        "ago",
        "set",
        "out",
        "nov",
        "dez",
      ];
      return months[parseInt(number, 10) - 1];
    },
    handleTags(tags) {
      this.tags = tags;
    },
    selectedMonth(month) {
      const mes = month[0];
      const meses = {
        jan: 1,
        fev: 2,
        mar: 3,
        abr: 4,
        mai: 5,
        jun: 6,
        jul: 7,
        ago: 8,
        set: 9,
        out: 10,
        nov: 11,
        dez: 12,
      };
      this.monthNumber = meses[mes.toLowerCase()];
      this.year = month[1];
      this.getTransactions();
    },
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 99.8%;
}
.row-one {
  width: 100%;
}
.side-box {
  padding: 20px;
  background-color: #283045;
  border-radius: 7px;
  /* margin-left: 10px; */
}
.table-box {
  padding: 20px 0;
  background-color: #283045;
  border-radius: 7px;
  overflow: auto;
  min-height: 400px;
}
</style>
