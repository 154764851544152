<template>
  <div class="header-dash d-flex justify-content-between align-items-start">
    <h1>Minhas metas</h1>
    <ButtonGradient :type-button="'reports'" @click="goTo('historic')" />
  </div>
  <div class="container">
    <div class="custom-container">
      <!-- Filtros -->
      <div class="row gap-3">
        <MonthFilter
          class="mt-0"
          :years="Years"
          @selectedMonth="selectedMonth"
        />
      </div>

      <!-- Tabela de metas -->
      <div class="row">
        <div class="col-12 col-md-3">
          <div class="col-md" style="margin-bottom: 10px">
            <div class="box">
              <i class="fa-regular fa-circle-down red" />
              <div v-if="dashboardTotal.data && !dashboardTotal.loading">
                <h3>Por categoria</h3>
                <h2 :class="hideCurrency ? 'blur-effect' : ''">
                  {{ totalBalanceByCategory }}
                </h2>
              </div>
              <div v-else class="loading-min-no-padding">
                <i class="fas fa-spinner fa-spin" />
              </div>
            </div>
          </div>

          <button
            class="btn-default col-12 col-md-12"
            @click="openModalcategory"
            style="margin-bottom: 10px"
          >
            <i class="fa-solid fa-circle-plus" />
            <span>Nova meta por categoria</span>
          </button>

          <TableGoals
            v-if="goalsBalance.data && !goalsBalance.loading"
            :category-goals="goalsBalance.data.balanceByCategory"
            :methods-goals="goalsBalance.data.balanceByMethod"
            :expense-goals="goalsBalance.data.balanceByExpense"
            :tableType="'category'"
            @getType="getType"
            @open="openModalOff"
          />
          <div v-else class="loading-min">
            <i class="fas fa-spinner fa-spin" />
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="col-md" style="margin-bottom: 10px">
            <div class="box">
              <i class="fa-regular fa-circle-down red" />
              <div v-if="dashboardTotal.data && !dashboardTotal.loading">
                <h3>Forma de pagamento</h3>
                <h2 :class="hideCurrency ? 'blur-effect' : ''">
                  {{ totalBalanceByMethod }}
                </h2>
              </div>
              <div v-else class="loading-min-no-padding">
                <i class="fas fa-spinner fa-spin" />
              </div>
            </div>
          </div>

          <button
            class="btn-default col-12 col-md-12"
            @click="openModalPayment"
            style="margin-bottom: 10px"
          >
            <i class="fa-solid fa-circle-plus" />
            <span>Nova meta de pagamento</span>
          </button>

          <TableGoals
            v-if="goalsBalance.data && !goalsBalance.loading"
            :category-goals="goalsBalance.data.balanceByCategory"
            :methods-goals="goalsBalance.data.balanceByMethod"
            :tableType="'payment'"
            :expense-goals="goalsBalance.data.balanceByExpense"
            @getType="getType"
            @open="openModalOff"
          />
          <div v-else class="loading-min">
            <i class="fas fa-spinner fa-spin" />
          </div>
        </div>

        <div class="col-12 col-md-3">
          <div class="col-md" style="margin-bottom: 10px">
            <div class="box">
              <i class="fa-regular fa-circle-down red" />
              <div v-if="dashboardTotal.data && !dashboardTotal.loading">
                <h3>Tipo de gastos</h3>
                <h2 :class="hideCurrency ? 'blur-effect' : ''">
                  {{ totalBalanceByExpense }}
                </h2>
              </div>
              <div v-else class="loading-min-no-padding">
                <i class="fas fa-spinner fa-spin" />
              </div>
            </div>
          </div>

          <button
            class="btn-default col-12 col-md-12"
            @click="openModalDebit"
            style="margin-bottom: 10px"
          >
            <i class="fa-solid fa-circle-plus" />
            <span>Nova meta de gastos</span>
          </button>

          <TableGoals
            v-if="goalsBalance.data && !goalsBalance.loading"
            :category-goals="goalsBalance.data.balanceByCategory"
            :methods-goals="goalsBalance.data.balanceByMethod"
            :tableType="'debit'"
            :expense-goals="goalsBalance.data.balanceByExpense"
            @getType="getType"
            @open="openModalOff"
          />
          <div v-else class="loading-min">
            <i class="fas fa-spinner fa-spin" />
          </div>
        </div>

        <div class="col-12 col-md-3">
          <div class="col-md" style="margin-bottom: 10px">
            <div class="box">
              <i class="fa-regular fa-circle-down red" />
              <div v-if="dashboardTotal.data && !dashboardTotal.loading">
                <h3>Investimentos</h3>
                <h2 :class="hideCurrency ? 'blur-effect' : ''">
                  {{ totalBalanceByInvestiment }}
                </h2>
              </div>
              <div v-else class="loading-min-no-padding">
                <i class="fas fa-spinner fa-spin" />
              </div>
            </div>
          </div>

          <button
            class="btn-default col-12 col-md-12"
            @click="openModalInvestment"
            style="margin-bottom: 10px"
          >
            <i class="fa-solid fa-circle-plus" />
            <span> Nova meta Investimento</span>
          </button>

          <TableGoals
            v-if="goalsBalance.data && !goalsBalance.loading"
            :category-goals="goalsBalance.data.balanceByCategory"
            :methods-goals="goalsBalance.data.balanceByMethod"
            :tableType="'investiment'"
            :expense-goals="goalsBalance.data.balanceByExpense"
            @getType="getType"
            @open="openModalOff"
          />
          <div v-else class="loading-min">
            <i class="fas fa-spinner fa-spin" />
          </div>
        </div>
      </div>
      <Modal
        title="Nova meta financeira"
        :is-open="isModalOpen"
        @close="closeModal"
      >
        <FormGoals
          :type-goal="typeGoal"
          :selected-year="year"
          :selected-month="month"
          @close="closeModal"
          @reload="loadData"
        />
      </Modal>

      <Modal
        id="ModalFormFilter"
        title="Editar meta"
        :is-open="isModalOffOpen"
        @close="closeModalOff"
      >
        <FormEditGoals
          :goal="editedGoal"
          @reload="loadData"
          @close="closeModalOff"
        />
      </Modal>
    </div>
  </div>
</template>

<script>
import ButtonGradient from "@/components/shared/ButtonGradient.vue";
import TableGoals from "@/components/financial_goal/TableGoals.vue";
import MonthFilter from "@/components/shared/MonthFilter.vue";

import Modal from "@/components/shared/Modal.vue";
import { useAppStore } from "@/store/store.js";
import FormGoals from "@/components/financial_goal/FormGoals.vue";
import FormEditGoals from "@/components/financial_goal/FormEditGoals.vue";
import { mapActions, mapState } from "pinia";

export default {
  components: {
    ButtonGradient,
    Modal,
    TableGoals,
    FormGoals,
    MonthFilter,
    FormEditGoals,
  },
  props: {},
  data() {
    return {
      goals: [],
      isModalOpen: false,
      typeGoal: "category",
      editedGoal: {},
      year: null,
      month: null,
      isModalOffOpen: false,
    };
  },
  computed: {
    ...mapState(useAppStore, ["hideCurrency"]),

    dashboardTotal() {
      const store = useAppStore();
      const dashboardTotal = store.dashboardTotal;
      return dashboardTotal;
    },
    goalsBalance() {
      const store = useAppStore();
      const goalsBalance = store.goalsBalance;
      return goalsBalance;
    },
    totalBalanceByCategory() {
      if (this.goalsBalance.data && this.goalsBalance.data.balanceByCategory) {
        const total = this.goalsBalance.data.balanceByCategory
          .filter((item) => [1, 2, 4, 5].includes(item.id_transaction_type)) // Filtra os itens
          .reduce((total, item) => total + item.goal_amount, 0); // Soma os valores de 'amount'

        return total.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      }
      return "R$ 0,00";
    },
    totalBalanceByInvestiment() {
      if (this.goalsBalance.data && this.goalsBalance.data.balanceByCategory) {
        const total = this.goalsBalance.data.balanceByCategory
          .filter((item) => [3].includes(item.id_transaction_type)) // Filtra os itens
          .reduce((total, item) => total + item.goal_amount, 0); // Soma os valores de 'amount'
        return total.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      }
      return "R$ 0,00";
    },

    totalBalanceByMethod() {
      if (this.goalsBalance.data && this.goalsBalance.data.balanceByMethod) {
        const total = this.goalsBalance.data.balanceByMethod.reduce(
          (total, item) => total + item.goal_amount,
          0
        ); // Soma os valores de 'amount'
        return total.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      }
      return "R$ 0,00";
    },

    totalBalanceByExpense() {
      if (this.goalsBalance.data && this.goalsBalance.data.balanceByExpense) {
        const total = this.goalsBalance.data.balanceByExpense.reduce(
          (total, item) => total + item.goal_amount,
          0
        ); // Soma os valores de 'amount'
        return total.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      }
      return "R$ 0,00";
    },

    Years() {
      const months = [
        "jan",
        "fev",
        "mar",
        "abr",
        "mai",
        "jun",
        "jul",
        "ago",
        "set",
        "out",
        "nov",
        "dez",
      ];

      const today = new Date();
      const todayYear = today.getFullYear();

      const years = [];
      for (let i = todayYear - 10; i <= todayYear + 10; i++) {
        years.push(i);
      }

      let monthsAndYears2 = [];
      years.map((year) => {
        for (let month = 0; month < 12; month++) {
          monthsAndYears2.push(`${months[month]}/${year}`);
        }
      });

      return monthsAndYears2;
    },
    token() {
      const store = useAppStore();
      const token = store.token;

      return token;
    },
  },
  methods: {
    openModalOff(value) {
      this.editedGoal = value;
      this.isModalOffOpen = true;
    },
    closeModalOff() {
      this.isModalOffOpen = false;
    },
    selectedMonth(month) {
      const mes = month[0];
      const meses = {
        jan: 1,
        fev: 2,
        mar: 3,
        abr: 4,
        mai: 5,
        jun: 6,
        jul: 7,
        ago: 8,
        set: 9,
        out: 10,
        nov: 11,
        dez: 12,
      };
      this.month = meses[mes.toLowerCase()];
      this.year = month[1];
      this.loadData();
    },
    loadData() {
      const store = useAppStore();

      store.getDashboardTotal({ year: this.year, month: this.month });
      store.getGoalsBalance({ year: this.year, month: this.month });
    },
    getType(value) {
      this.typeGoal = value;
    },
    openModalInvestment() {
      let value = null;
      this.typeGoal = "investment";
      this.openModal(value, "investiment");
    },
    openModalPayment() {
      let value = null;
      this.typeGoal = "payment";
      this.openModal(value, "payment");
    },

    openModalcategory() {
      let value = null;
      this.typeGoal = "category";
      this.openModal(value, "category");
    },
    openModalDebit(value) {
      this.typeGoal = "debit";
      this.openModal(value, "debit");
    },
    goTo(route) {
      this.$router.push(route);
      this.currentRoute = route;
    },
    formatValue(valor) {
      const appStore = useAppStore();
      return appStore.formatValue(valor);
    },
    openModal(value, type) {
      this.typeForm = type;
      this.isQuick = true;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-container {
  max-width: 99.8%;
}

.nav-pagination {
  margin-bottom: 1rem;
}

.btn-custom {
  margin-top: 1rem;
}

.box {
  display: flex;
  padding: 20px 20px;
  align-items: center;
  justify-content: flex-start;
  background: #283045 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 7px;
  color: #fff;

  h3 {
    font-size: 16px;
    font-weight: 300;
  }

  h2 {
    font-size: 18px;
    margin: 0;
  }

  i {
    padding: 10px;
    border-radius: 10px;
    font-size: 25px;
    margin-right: 20px;
  }
}

.red {
  background: #bf38384d;
  color: #bf3838;
}

.green {
  color: #24b361;
  background-color: #24b36244;
}

.blue {
  color: #0062ff;
  background-color: #0062ff3f;
}
.btn-default {
  width: 100% !important;
}
</style>
