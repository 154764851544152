import { defineStore } from "pinia";
import { useAppStore } from "@/store/store.js";

import axios from "axios";

export const useAppStoreFuture = defineStore("future", {
  state: () => ({
    componentKey: 0,
    planning_setting: {
      data: null,
      total: null,
      loading: false,
      loaded: false,
    },
    objective: {
      data: [],
      total: null,
      loading: false,
      loaded: false,
    },
    dream: {
      data: null,
      total: null,
      loading: false,
      loaded: false,
    },
    planning_setting_future: {
      total_savings: 1000,
      monthly_savings: 100,
      inflation_rate: 10,
      interest_rate: 11,
      retirement_age: 65,
      retirement_amount: 0,
      retirement_desired_income: 0,
    },
    acumulatedbyYearObject: {},
  }),
  getters: {
    userAge() {
      const store = useAppStore();

      const age = store.userAge;
      return age;
    },

    last_planning_setting: (state) => {
      const data = state.planning_setting.data;
      const arr = data || [];
      return arr.length > 0 ? arr[arr.length - 1] : null;
    },
    simpleProjection: (state) => {
      let acumulatedbyYear = [];

      let monthlyInterestRate = Math.pow(1 + Number(state.planning_setting_future.interest_rate) / 100, 1 / 12) - 1;
      let monthlyInflationRate = Math.pow(1 + Number(state.planning_setting_future.inflation_rate) / 100, 1 / 12) - 1;

      let totalAmount = Number(state.planning_setting_future.total_savings);
      for (let year = state.userAge; year <= state.planning_setting_future.retirement_age; year++) {
        for (let month = 1; month <= 12; month++) {
          totalAmount += Number(state.planning_setting_future.monthly_savings);
          totalAmount *= (1 + monthlyInterestRate) / (1 + monthlyInflationRate);
        }
        acumulatedbyYear.push({
          year: year,
          totalAmount: parseFloat(totalAmount.toFixed(2)),
        });
      }
      return acumulatedbyYear;
    },
    acumulatedbyYear: (state) => {
      let acumulatedbyYear = [];
      let monthlyInterestRate = Math.pow(1 + Number(state.planning_setting_future.interest_rate) / 100, 1 / 12) - 1;
      let monthlyInflationRate = Math.pow(1 + Number(state.planning_setting_future.inflation_rate) / 100, 1 / 12) - 1;
      let objectives = [];
      let objectivesAsap = [
        ...state.objective.data
          .filter((objective) => objective.deadline_type == "asap")
          .sort((a, b) => a.priority - b.priority),
      ];
      let objectivesFixed = [
        ...state.objective.data
          .filter((objective) => objective.deadline_type == "fixed")
          .sort((a, b) => new Date(a.deadline) - new Date(b.deadline)),
      ];
      objectives = [...state.objectivesSorted];

      let o = 0;

      let totalAmount = Number(state.planning_setting_future.total_savings);

      for (let year = state.userAge; year <= state.planning_setting_future.retirement_age; year++) {
        state.acumulatedbyYearObject[year] = { initial: parseFloat(totalAmount.toFixed(2)) };
        let maxAcumulatedYear = totalAmount;


        for (let month = 1; month <= 12; month++) {
          maxAcumulatedYear += Number(state.planning_setting_future.monthly_savings);
          maxAcumulatedYear *= (1 + monthlyInterestRate) / (1 + monthlyInflationRate);
        }
        acumulatedbyYear.push({
          year: year,
          totalAmount: parseFloat(totalAmount.toFixed(2)),
        });
        for (let month = 1; month <= 12; month++) {
          totalAmount += Number(state.planning_setting_future.monthly_savings);
          totalAmount *= (1 + monthlyInterestRate) / (1 + monthlyInflationRate);

          for (let index = 0; index < objectives.length; index++) {
            if (objectives && objectives[o] && index == o) {
              let objective = objectives[o];
              if (objective.deadline_type == "asap" && totalAmount >= objective.amount) {
                acumulatedbyYear.push({
                  year: year,
                  totalAmount: parseFloat(totalAmount.toFixed(2)),
                });
                totalAmount = totalAmount - objective.amount;
                acumulatedbyYear.push({
                  year: year,
                  totalAmount: parseFloat(totalAmount.toFixed(2)),
                });
                objectives[o].yearPlanning = year;
                objectives[o].monthPlanning = month;
                objectives[o].totalAmount = parseFloat(totalAmount.toFixed(2));

                o++;
              }
              if (objective.deadline_type == "fixed" && totalAmount >= objective.amount) {
                let currentYear = new Date().getFullYear();
                let expectedYear = new Date(objective.deadline).getFullYear() - currentYear + state.userAge;
                if (year >= expectedYear) {
                  acumulatedbyYear.push({
                    year: year,
                    totalAmount: parseFloat(totalAmount.toFixed(2)),
                  });
                  totalAmount = totalAmount - objective.amount;
                  acumulatedbyYear.push({
                    year: year,
                    totalAmount: parseFloat(totalAmount.toFixed(2)),
                  });
                  objectives[o].yearPlanning = year;
                  objectives[o].monthPlanning = month;
                  objectives[o].totalAmount = parseFloat(totalAmount.toFixed(2));

                  o++;
                }
              }
            }
          }
          state.acumulatedbyYearObject[year]["end"] = parseFloat(totalAmount.toFixed(2));
          state.acumulatedbyYearObject[year]["max"] = parseFloat(maxAcumulatedYear.toFixed(2));
        }
      }

      return acumulatedbyYear;
    },
    currentTotalAmount: (state) => {
      return state.acumulatedbyYear[0].totalAmount;
    },
    retirementProjection: (state) => {
      let acumulatedbyYear = [];

      let monthlyInterestRate = Math.pow(1 + Number(state.planning_setting_future.interest_rate) / 100, 1 / 12) - 1;
      let monthlyInflationRate = Math.pow(1 + Number(state.planning_setting_future.inflation_rate) / 100, 1 / 12) - 1;

      let totalAmount = Number(state.acumulatedbyYear[state.acumulatedbyYear.length - 1].totalAmount);

      for (
        let year = state.acumulatedbyYear[state.acumulatedbyYear.length - 1].year;
        year <= state.acumulatedbyYear[state.acumulatedbyYear.length - 1].year + 30;
        year++
      ) {
        for (let month = 1; month <= 12; month++) {
          totalAmount -= Number(state.planning_setting_future.retirement_desired_income);
          totalAmount *= (1 + monthlyInterestRate) / (1 + monthlyInflationRate);
        }
        acumulatedbyYear.push({
          year: year,
          totalAmount: parseFloat(totalAmount.toFixed(2)),
        });
      }

      return acumulatedbyYear.filter((item) => item.totalAmount >= 0);
    },
    objectivesAsap: (state) => {
      if (state.objective && state.objective.data) {
        let asapObjectives = state.objective.data.filter((objective) => objective.deadline_type == "asap");

        asapObjectives.sort((a, b) => a.priority - b.priority);

        return asapObjectives;
      }

      // Se state.objective ou state.objective.data for nulo, retornar um array vazio
      return [];
    },
    objectivesFixed: (state) => {
      if (state.objective && state.objective.data) {
        let fixedObjectives = state.objective.data.filter((objective) => objective.deadline_type == "fixed");

        fixedObjectives.sort((a, b) => new Date(a.deadline) - new Date(b.deadline));

        return fixedObjectives;
      }

      // Se state.objective ou state.objective.data for nulo, retornar um array vazio
      return [];
    },
    objectivesSorted: (state) => {
      if (state.objective && state.objective.data) {
        const objectiveArray = [...state.objectivesAsap, ...state.objectivesFixed];
        return objectiveArray.sort((a, b) => a.priority - b.priority);
        //return objectiveArray;
      }

      // Se state.objective ou state.objective.data for nulo, retornar um array vazio
      return [];
    },
  },

  actions: {
    get_planning_setting() {
      this.planning_setting.loading = true;
      const token = localStorage.getItem("token");

      axios
        .get(`/planning_setting?$sort[id]=-1&$limit=1`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const array = response.data.data;
          this.planning_setting.data = response.data.data;
          this.planning_setting.interview_data = response.data?.interview_data?.interview_data;
          this.planning_setting.loaded = true;
          if (array.length > 0) {
            this.planning_setting_future = array[0];
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.planning_setting.loading = false;
        });
    },
    get_objective() {
      this.objective.loading = true;
      const token = localStorage.getItem("token");

      axios
        .get(`/objective`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          this.objective.data = response.data.data
            .map((objective) => {
              return { ...objective, isChecked: true };
            })
            .sort((a, b) => a.priority - b.priority);

          this.objective.loaded = true;
          this.componentKey += 1;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.objective.loading = false;
        });
    },
    get_dream() {
      this.dream.loading = true;
      const token = localStorage.getItem("token");

      axios
        .get(`/dream`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          this.dream.data = response.data.data;
          this.dream.loaded = true;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.dream.loading = false;
        });
    },
  },
});
