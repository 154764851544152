<template>
  <div v-if="user" class="dashboard">
    <AppSideMenu @menu-toggle="onMenuToggle" />
    <div class="main" :style="getContentStyle">
      <router-view :key="$route.fullPath" :user="user" />
    </div>
  </div>
</template>

<script>
import AppSideMenu from "../layout/AppSideMenu.vue";
import AppTopBar from "../layout/AppTopBar.vue";
import { useAppStore } from "@/store/store.js";
import { RouterView } from "vue-router";

export default {
  components: {
    AppSideMenu,
    AppTopBar,
    RouterView,
  },
  data() {
    return {
      isMenuClosed: false,
      isAboutRoute: false,
    };
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },

    getContentStyle() {
      return {
        marginLeft: this.isMenuClosed ? "60px" : "250px",
      };
    },
    user() {
      const store = useAppStore();
      const user = store.userResponse;
      return user;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    onMenuToggle(isMenuClosed) {
      this.isMenuClosed = isMenuClosed;
    },
    loadData() {
      const store = useAppStore();
      store.getUser();
      store.getInitialObjects();
    },
  },
};
</script>

<style lang="scss">
.blur-effect {
  filter: blur(5px);
}
</style>

<style lang="scss" scoped>
.dashboard {
  position: relative;
}
.main {
  flex: 1;
  padding-top: 15px;
  padding-bottom: 20px;
  padding-right: 10px;
  padding-left: 20px;
  min-height: 100vh;
  transition: margin-left 0.3s ease;
  @media (max-width: 767px) {
    margin-left: 60px !important; // Remove a margem esquerda no mobile
  }
}
</style>
