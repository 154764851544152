<template>
  <div class="box main-box">
    <div :class="titleColor" class="title">
      <i v-if="typetransaction == 'credit'" class="fa-regular fa-circle-up" />
      <i
        v-else-if="typetransaction == 'debit'"
        class="fa-regular fa-circle-down"
      />
      <i
        v-else-if="typetransaction == 'investment'"
        class="fas fa-solid fa-chart-column"
      />
      <h1>{{ typeTitle }}</h1>
    </div>
    <div class="box-values">
      <h2 :class="valueColor">Realizado</h2>
      <p :class="hideCurrency ? 'blur-effect' : ''">
        {{ formatValue(currentValue) }}
      </p>
      <h4>
        {{ typeTitle === "Receita" ? "Valor informado na entrevista" : "Meta" }}
      </h4>
      <p :class="hideCurrency ? 'blur-effect' : ''">
        {{ formatValue(goalsValue) }}
      </p>
    </div>
  </div>
</template>

<script>
import { useAppStore } from "@/store/store.js";
import { mapActions, mapState } from "pinia";

export default {
  name: "TransactionBox",
  props: {
    typetransaction: {
      type: String,
      required: true,
    },
    currentValue: {
      type: Number,
      required: true,
    },
    goalsValue: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(useAppStore, ["hideCurrency"]),

    typeTitle() {
      if (this.typetransaction == "credit") {
        return "Receita";
      } else if (this.typetransaction == "debit") {
        return "Despesa";
      } else if (this.typetransaction == "investment") {
        return "Investimento";
      }
    },
    titleColor() {
      if (this.typetransaction == "credit") {
        return "green-title";
      } else if (this.typetransaction == "debit") {
        return "red-title";
      } else if (this.typetransaction == "investment") {
        return "blue-title";
      }
    },
    valueColor() {
      if (this.typetransaction == "credit") {
        return "green-value";
      } else if (this.typetransaction == "debit") {
        return "red-value";
      } else if (this.typetransaction == "investment") {
        return "blue-value";
      }
    },
  },
  methods: {
    formatValue(valor) {
      const appStore = useAppStore();
      return appStore.formatValue(valor);
    },
  },
};
</script>

<style lang="scss" scoped>
/* Common styles */

.main-box {
  color: #fff;
  width: 100% !important;
  padding: 10px;
  min-height: 203px;
}
.title {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  h1 {
    font-size: 14px;
    margin: 0;
    font-weight: bold;
  }
  i {
    margin-right: 10px;
  }
}

.box-values {
  text-align: center !important;

  h2 {
    font-size: 14px;
  }
  h4 {
    font-size: 14px;
    font-weight: 300;
  }
  p {
    font-size: 16px;
    font-weight: bold;
  }
}

/* Title colors based on typetransaction */
.green-title {
  background-color: rgba(56, 191, 56, 0.3);
  color: #00ad00;
}

.red-title {
  background-color: rgba(255, 0, 0, 0.3);
  color: #d92a2a;
}

.blue-title {
  background-color: #56b1ee67;
  color: #56b0ee;
}

/* Value colors based on typetransaction */
.green-value {
  color: #00ad00;
}

.red-value {
  color: #ff0000;
}

.blue-value {
  color: #56b0ee;
}
</style>
