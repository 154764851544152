<template>
  <div class="form-default">
    <!-- Select para categoryName -->
    <label for="categoryName">Categoria:</label>
    <select id="categoryName" v-model="selectedCategory">
      <option v-for="category in uniqueCategories" :key="category">
        {{ category }}
      </option>
    </select>

    <label for="type">Tipo de transação:</label>
    <select id="type" v-model="selectedType">
      <option v-for="type in uniqueTypes" :key="type" :value="type">
        {{ getTransactionType(type) }}
      </option>
    </select>

    <label for="methodName">Forma de Pagamento:</label>
    <select id="methodName" v-model="selectedMethod">
      <option v-for="method in uniqueMethods" :key="method">
        {{ method }}
      </option>
    </select>

    <label for="accountName">Conta ou cartão:</label>
    <select id="accountName" v-model="selectedAccount">
      <option v-for="account in accounts" :key="account.name" :value="account.name">
        {{ account.name }}
      </option>
    </select>

    <label for="accountName">Tipo de despesa:</label>
    <select id="accountName" v-model="expenseType">
      <option v-for="account in transactionType" :key="account.name" :value="account.name">
        {{ account.name }}
      </option>
    </select>

    <button @click="applyFilters">
      <span> Aplicar Filtros </span>
    </button>
  </div>
</template>

<script>
import { useAppStore } from "@/store/store.js";

export default {
  props: {
    transactions: Array
  },
  data() {
    return {
      selectedCategory: "",
      selectedMethod: "",
      selectedType: "",
      selectedAccount: "",
      expenseType: "",
      uniqueTypes: ["credit", "debit"]
    };
  },
  computed: {
    uniqueCategories() {
      return [
        ...new Set(
          this.transactions.map((transaction) =>
            transaction.id_transaction_category_data
              ? transaction.id_transaction_category_data.name
              : 'Sem Categoria'
          )
        )
      ];
    },
    transactionType() {
      const store = useAppStore();
      const transactionTypeResponse = store.transactionTypeResponse;
      const t = transactionTypeResponse?.filter((item) =>
        [1, 2].includes(item.id)
      );

      return t;
    },
    uniqueMethods() {
      return [
        ...new Set(
          this.transactions.map((transaction) =>
            transaction.id_transaction_method_data
              ? transaction.id_transaction_method_data.name
              : null
          )
        )
      ].filter((method) => method !== null);
    },
    uniqueAccountIds() {
      return [
        ...new Set(
          this.transactions.map((transaction) =>
            transaction.id_bank_account_data ? transaction.id_bank_account_data.name : null
          )
        )
      ].filter((id) => id !== null);
    },
    accounts() {
      const store = useAppStore();
      const accounts = store.accountsResponse;
      if(accounts){
        // Get the unique account IDs
        const uniqueIds = this.uniqueAccountIds;

        // Filter accounts based on the unique IDs
        return accounts?.filter(account => uniqueIds.includes(account.name));
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    getTransactionType(valor) {
      const appStore = useAppStore();
      return appStore.getTransactionType(valor);
    },
    applyFilters() {
      const selectedFilters = [];

      if (this.selectedCategory !== "") {
        selectedFilters.push(this.selectedCategory);
      }

      if (this.selectedMethod !== "") {
        selectedFilters.push(this.selectedMethod);
      }

      if (this.selectedType !== "") {
        selectedFilters.push(this.selectedType);
      }

      if (this.selectedAccount !== "") {
        selectedFilters.push(this.selectedAccount);
      }

      if (this.expenseType !== "") {
        selectedFilters.push(this.expenseType);
      }

      this.$emit("filters-applied", selectedFilters);
      this.closeModal();
    }
  }
};
</script>

<style lang="scss" scoped>
/* Adicione estilos conforme necessário */
</style>
