<template>
  <div>
    <AccountDetail
      v-if="isDetail"
      :account="selectedAccount"
      @returnToAcc="returnToAcc"
      @editAccount="editAccount"
    />
    <div v-else>
      <div class="row row-margin-bottom gx-0 gy-0">
        <div class="header-dash col-lg-10">
          <h1>Minhas contas</h1>
          <!-- <i class="fas fa-info-circle" /> -->
        </div>
      </div>

      <div class="row gap-3 gx-0 gy-0">
        <div class="col-lg box">
          <h3 class="custom-box-title">Contas</h3>

          <div
            class="col-lg-10 mx-auto d-flex justify-content-center"
            style="margin-bottom: 10px"
          >
            <button class="btn-default-bigger" @click="openModalaccount">
              <i class="fa-solid fa-circle-plus" />
              <span> Nova Conta corrente / Poupança</span>
            </button>
          </div>

          <div v-for="(account, index) in accounts_defaults" :key="index">
            <AccountBox
              :account="account"
              @accDetail="accDetail"
              @editAccount="editAccount"
            />
          </div>
        </div>

        <div class="col-lg box">
          <h3 class="custom-box-title">Cartões</h3>
          <div
            class="col-lg-10 mx-auto d-flex justify-content-center"
            style="margin-bottom: 10px"
          >
            <button class="btn-default-bigger" @click="openModalcreditCard">
              <i class="fa-solid fa-circle-plus" />
              <span>Novo Cartão de Credito</span>
            </button>
          </div>
          <div v-for="(account, index) in accounts_card" :key="index">
            <AccountBox
              :account="account"
              @accDetail="accDetail"
              @editAccount="editAccount"
            />
          </div>
        </div>
        <div class="col-lg box">
          <h3 class="custom-box-title">Contas de investimento</h3>

          <div
            class="col-lg-10 mx-auto d-flex justify-content-center"
            style="margin-bottom: 10px"
          >
            <button class="btn-default-bigger" @click="openModalInvestiment">
              <i class="fa-solid fa-circle-plus" />
              <span> Nova conta de Investimento</span>
            </button>
          </div>

          <div v-for="(account, index) in accounts_investment" :key="index">
            <AccountBox
              :account="account"
              @accDetail="accDetail"
              @editAccount="editAccount"
            />
          </div>
        </div>
      </div>
    </div>

    <Modal :title="modalTitle" :is-open="isModalOpen" @close="closeModal">
      <FormAccount
        v-if="!isEditing"
        :type_form="modalType"
        @close="closeModal"
      />
      <FormEditAccount
        v-if="isEditing"
        :account="accToedit"
        @close="closeModal"
        @returnToAcc="returnToAcc"
      />
    </Modal>
  </div>
</template>

<script>
import AccountBox from "../bank_account/AccountBox.vue";
import Modal from "../shared/Modal.vue";
import AccountDetail from "../bank_account/AccountDetail.vue";
import FormAccount from "../bank_account/FormAccount.vue";
import { useAppStore } from "@/store/store.js";
import FormEditAccount from "../bank_account/FormEditAccount.vue";

export default {
  components: {
    AccountBox,
    Modal,
    FormAccount,
    AccountDetail,
    FormEditAccount,
  },
  data() {
    return {
      modalType: "account",
      plan: "",
      // accounts: [],
      isEditing: false,
      isDetail: false,
      isModalOpen: false,
      accToedit: {},
      title: "Nova Conta",
    };
  },
  computed: {
    accounts_card() {
      if (!this.accounts) {
        return [];
      }

      // Filtrar apenas os objetos que têm account_type igual a 1
      return this.accounts.filter((item) => item.id_account_type == "4");
    },
    modalTitle() {
      if (!this.isEditing) {
        return this.title;
      } else {
        return this.accToedit.id_account_type === 4
          ? "Editar Cartão"
          : "Editar Conta";
      }
    },
    accounts_investment() {
      if (!this.accounts) {
        return [];
      }

      // Filtrar apenas os objetos que têm id_account_type igual a 3
      return this.accounts.filter((item) => item.id_account_type == "3");
    },
    accounts_defaults() {
      if (!this.accounts) {
        return [];
      }

      // Filtrar apenas os objetos que têm id_account_type igual a 1 ou 2
      return this.accounts.filter(
        (item) => item.id_account_type == "1" || item.id_account_type == "2"
      );
    },

    accounts() {
      const store = useAppStore();
      const accounts = store.accountsResponse;

      // Agora você pode usar a variável "transactionMethodResponse" em seu código

      return accounts;
    },
  },
  mounted() {
    // this.AccountPromise();
  },
  methods: {
    editAccount(value) {
      this.accToedit = value;
      this.isEditing = true;
      this.isModalOpen = true;
    },
    returnToAcc() {
      this.isDetail = false;
    },
    accDetail(acc) {
      this.isDetail = true;
      this.selectedAccount = acc;
    },
    // openModal() {
    //   this.isModalOpen = true;
    // },
    closeModal() {
      this.isModalOpen = false;
      this.isEditing = false;
    },

    openModalInvestiment() {
      this.isModalOpen = true;
      this.title = "Nova Conta de Investimento";
      this.modalType = "3";
    },
    openModalcreditCard() {
      this.isModalOpen = true;
      this.title = "Novo Cartão de Crédito";
      this.modalType = "4";
    },
    openModalaccount() {
      this.isModalOpen = true;
      this.title = "Nova Conta / Poupança";
      this.modalType = "1";
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-box-title {
  margin-left: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  font-weight: bold;
}
</style>
