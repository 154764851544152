<template>
  <div class="form-default container">
    <div>
      <p
        v-if="successMessage"
        class="success-message"
      >
        {{ successMessage }}
      </p>

      <p
        v-else-if="errorMessage"
        class="error-message"
      >
        {{ errorMessage }}
      </p>
    </div>
    <div class="row">
      <div class="col">
        <label>Valor da Fatura</label>
        <h4>{{ formatValue(currentBill.debit) }}</h4>
      </div>
      <div class="col">
        <label>Fatura</label>
        <h4>{{ formatYearMonth(currentBill.period) }}</h4>
      </div>
      <div class="separator" />
    </div>
    <div class="row">
      <div class="col">
        <label for="">Valor</label>
        <input
          id="amount"
          v-model="payload.amount"
          v-money="moneyConfig"
          type="text"
          name="amount"
          class="form-control"
          placeholder=""
          required
        >
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label for="">Data da transação</label>
        <input
          id="transaction_at"
          v-model="payload.transaction_at"
          type="date"
          name="transaction_at"
          class="form-control"
          placeholder="dd/mm/yyyy"
          onkeydown="return false" 
          onclick="this.showPicker()"
          required
        >
      </div>
      <div class="col">
        <label>Qual conta ou cartão?</label>
        <select
          id="type"
          v-model="payload.id_bank_account_sending"
          required
        >
          <option
            v-for="(type, index) in accounts"
            :key="index"
            :value="type.id"
          >
            {{ type.name }}
          </option>
        </select>
      </div>
    </div>

    <button @click="save">
      <span> Salvar </span>
    </button>
  </div>
</template>

<script>
import axios from "axios";
import { useAppStore } from "@/store/store.js";
import shared from "@/shared";
import { unformat } from "v-money3";
export default {
  components: {},
  props: {
    currentBill: {
      type: Object,
      required: true
    },
    formatValue: Function,
    formatYearMonth: Function
  },
  data() {
    return {
      selected: true,
      successMessage: "",
      errorMessage: "",
      fieldNames: {},
      requiredFields: [],
      payload: {
        amount: null,
        id_bank_account_sending: null,
        id_bank_account_receiving: this.currentBill.id,
        cc_period: this.currentBill.period,
        description: `Pagamento de Fatura ${this.formatYearMonth(
          this.currentBill.period
        )}`,
        transaction_at: null
      },
      moneyConfig: shared.moneyConfig()

    };
  },

  computed: {
    accounts() {
      const store = useAppStore();
      const accounts = store.accountsResponse;

      return accounts;
    },
    token() {
      const appStore = useAppStore();
      return appStore.token;
    }
  },
  created() {},
  mounted() {},
  methods: {
    validateForm(requiredFields) {
      this.errorMessage = "";

      for (const field of requiredFields) {
        if (!this.payload[field]) {
          this.errorMessage = `Por favor, preencha o campo '${field}'.`;
          return false;
        }
      }

      return true;
    },
    async save() {
      const store = useAppStore();
      for (const key in this.payload) {
        if (this.payload[key] !== null) {
          this.payload[key] = String(this.payload[key]);
        }
      }
      // Mapeamento de nomes de campos em inglês para português

      this.fieldNames = {
        amount: "valor",
        id_bank_account_sending: "Conta ou cartão",
        transaction_at: "Data da transação"
      };

      this.requiredFields = [
        "amount",
        "id_bank_account_sending",
        "transaction_at"
      ];

      const missingFields = [];

      for (const field of this.requiredFields) {
        if (!this.payload[field]) {
          missingFields.push(this.fieldNames[field]);
        }
      }

      if (missingFields.length > 0) {
        const missingFieldsString = missingFields.join(", ");
        this.payload.amount = null;
        this.errorMessage = `Os seguintes campos são obrigatórios: ${missingFieldsString}.`;
        return; // Abortar a função caso o formulário não seja válido
      }

      this.payload.amount = unformat(this.payload.amount, this.moneyConfig);
      try {
        const response = await axios.post(
          `/transfer`,
          this.payload,
          {
            headers: { Authorization: `Bearer ${this.token}` }
          }
        );
        //this.getBills(this.account.id);
        this.$emit("refresh");
        this.successMessage = "Fatura paga com sucesso!";
        (this.payload = {
          id_bank_account_sending: null,
          amount: null,
          type: "credit",
          description: `Pagamento de Fatura ${formatYearMonth(
            this.currentBill.period
          )}`,
          transaction_at: null
        }),
          setTimeout(() => {
            this.closeModal();
          }, 1500);
      } catch (error) {
        console.error(error);
        this.payload.amount = null;
        this.errorMessage =
          "Erro ao pagar a fatura. Por favor, tente novamente.";
      }
    },

    closeModal() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
h4 {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  margin: 0;
  margin-bottom: 10px;
}

.selected {
  background: #cb5540;
  border-radius: 5px 0px 0px 5px;

  h2 {
    font-weight: bold !important;
  }
}
</style>
