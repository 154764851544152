<template>
  <div class="form-default container">
    <div>
      <p v-if="successMessage" class="success-message">
        {{ successMessage }}
      </p>

      <p v-else-if="errorMessage" class="error-message">
        {{ errorMessage }}
      </p>
    </div>
    <div class="row">
      <div class="col">
        <label>Conta:</label>
        <h4>{{ account.name }}</h4>
      </div>

      <div class="row">
        <div class="col">
          <input
            id="fileUpload"
            ref="fileInput"
            type="file"
            accept=".ofx"
            @change="handleFileUpload"
          />
        </div>
      </div>

      <button class="mt-3" @click="save">
        <span> Salvar </span>
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useAppStore } from "@/store/store.js";
export default {
  components: {},
  props: {
    account: Object,
  },
  data() {
    return {
      selected: true,
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      successMessage: "",
      errorMessage: "",
      fieldNames: {},
      requiredFields: [],
      payload: {
        file: null,
      },
    };
  },

  computed: {
    token() {
      const appStore = useAppStore();
      return appStore.token;
    },
  },
  created() {},
  mounted() {},
  methods: {
    validateForm(requiredFields) {
      this.errorMessage = "";

      for (const field of requiredFields) {
        if (!this.payload[field]) {
          this.errorMessage = `Por favor, preencha o campo '${field}'.`;
          return false;
        }
      }

      return true;
    },
    async reloadData(month, year) {
      const store = useAppStore();
      await store.getCurrentBalanceByAccount(month, year);
      await store.getBalanceByAccount2();
      await store.getBalanceByAccount();

      await store.getTransactions();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.payload.file = file;
      } else {
        this.payload.file = null;
      }
    },

    async save() {
      const store = useAppStore();

      this.fieldNames = {
        file: "Arquivo",
      };

      this.requiredFields = ["file"];

      const missingFields = [];

      for (const field of this.requiredFields) {
        if (!this.payload[field]) {
          missingFields.push(this.fieldNames[field]);
        }
      }

      if (missingFields.length > 0) {
        const missingFieldsString = missingFields.join(", ");

        this.errorMessage = `Os seguintes campos são obrigatórios: ${missingFieldsString}.`;
        return;
      }

      try {
        // Crie um novo objeto FormData
        const formData = new FormData();
        formData.append("ofx_file", this.payload.file);

        const response = await axios.post(
          `/import-ofx?id=${this.account.id}`,
          formData, // Envie o FormData em vez do payload
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${this.token}`,
            },
          }
        );

        // this.getBills(this.account.id);
        this.$emit("refresh");
        this.reloadData();
        this.successMessage = "Upload de extrato feito com sucesso!";
        this.payload = {
          file: null,
        };

        setTimeout(() => {
          this.closeModal();
        }, 1500);
      } catch (error) {
        console.error(error);

        this.errorMessage =
          "Erro ao pagar a fatura. Por favor, tente novamente.";
      }
    },

    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
h4 {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  margin: 0;
  margin-bottom: 10px;
}

.selected {
  background: #cb5540;
  border-radius: 5px 0px 0px 5px;

  h2 {
    font-weight: bold !important;
  }
}
</style>
