<template>
  <div class="box">
    <h1>Dropdown com Estilo Personalizado</h1>
    <Dropdown
      v-model="selectedOption"
      :options="categories"
      class="custom-dropdown"
      option-label="name"
      placeholder="Selecione uma opção"
    >
      <template #option="{ option }">
        <div class="custom-option">
          <i
            :class="option.icon"
            :style="{ color: option.color }"
          />
          {{ option.name }}
        </div>
      </template>
    </Dropdown>


    <Dialog
      v-model:visible="dialog"
      :style="{ width: '450px' }"
      :header="modalTitle"
      :modal="true"
    >
      <div class="flex align-items-center justify-content-center">
        oi
      </div>
      <template #footer />
    </Dialog>
  </div>
</template>

<script>
import { useAppStore } from "@/store/store.js";

import Dropdown from 'primevue/dropdown'
import Dialog from 'primevue/dialog'

export default {
  components: {
    Dropdown, Dialog
  },
  data() {
    return {
      dialog: true,
      selectedOption: null,
      dropdownOptions: [
        { label: 'Opção 1', value: 'option1', icon: 'fas fa-circle', color: '#FF5733' },
        { label: 'Opção 2', value: 'option2', icon: 'fas fa-circle', color: '#33FF57' },
        { label: 'Opção 3', value: 'option3', icon: 'fas fa-circle', color: '#5733FF' }
    ]
    }
  },
  computed: {
    categories() {
      const store = useAppStore();
      const categories = store.categoriesResponse;

      return categories;
    },}
}
</script>

<style lang="scss">


</style>
