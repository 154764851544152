<template>
  <div
    v-if="isOpen"
    class="modal right-aligned-modal"
  >
    <div class="modal-content">
      <div class="header-modal">
        {{ title }}
        <i
          class="fa-solid fa-xmark"
          @click="closeModal"
        />
      </div>
      <slot class="slot" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: Boolean,
    title: String
  },
  methods: {
    closeModal() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>

.modal {
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgba(10, 10, 10, 0.267);
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

.right-aligned-modal .modal-content {
  margin-left: auto;
}

.header-modal {
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  i {
    cursor: pointer;
    user-select: none;
  }
}

.modal-content {
  background: #283045;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  min-height: 100vh;
  padding: 40px 20px;
  max-width: 30%;
}
@media screen and (max-width: 768px) {
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  }
  .modal-content {
    max-width: 85%;
  }

  .modal-content {
    padding: 10px 5px;
    min-height: none;
  }
}

</style>
