<template>
  <div class="nav-pagination">
    <!-- Botão de negação para voltar ao ano anterior -->
    <div class="nav-prev" @click="navigate(-1)">
      <i class="fas fa-chevron-left" />
    </div>
    <div ref="btnOptionsWrapper" class="btn-options-wrapper-month">
      <div ref="btnOptions" class="btn-options-month">
        <div v-for="year in anosExibidos" :key="year" @click="select(year)">
          <div
            class="month-box"
            :class="{
              'selected-month':
                anoSelecionado[0] == year[0] && anoSelecionado[1] == year[1],
            }"
          >
            <h2>
              {{ year[0] }}
            </h2>
            <h3 class="year">
              {{ year[1] }}
            </h3>
          </div>
        </div>
      </div>
    </div>

    <!-- Botão de negação para avançar ao próximo ano -->
    <div class="nav-next" @click="navigate(1)">
      <i class="fas fa-chevron-right" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    years: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      anoSelecionado: "",
    };
  },
  mounted() {
    this.anoSelecionado = this.getCurrentMonth(this.mesesUnicos);
  },
  computed: {
    anosExibidos() {
      const selectedIndex = this.mesesUnicos.findIndex(
        (item) => JSON.stringify(item) === JSON.stringify(this.anoSelecionado)
      );
      const startIndex = Math.max(0, selectedIndex - 3);
      const endIndex = Math.min(this.mesesUnicos.length - 1, selectedIndex + 3);
      return this.mesesUnicos.slice(startIndex, endIndex + 1);
    },
    mesesUnicos() {
      let unicos = [...new Set(this.years)];
      let split = [];
      unicos.forEach((item) => split.push(item.split("/")));
      this.anoSelecionado = this.getCurrentMonth(split);
      return split;
    },
  },
  watch: {
    anoSelecionado() {
      this.$emit("selectedMonth", this.anoSelecionado);
    },
  },
  methods: {
    select(value) {
      this.anoSelecionado = value;
      this.centerSelectedItem();
    },
    navigate(step) {
      const currentIndex = this.mesesUnicos.findIndex(
        (item) => JSON.stringify(item) === JSON.stringify(this.anoSelecionado)
      );
      if (currentIndex !== -1) {
        const nextIndex = currentIndex + step;
        if (nextIndex >= 0 && nextIndex < this.mesesUnicos.length) {
          this.anoSelecionado = this.mesesUnicos[nextIndex];
          this.$emit("selectedYear", this.anoSelecionado);
          this.centerSelectedItem();
        }
      }
    },
    centerSelectedItem() {
      // Obtém o elemento do item selecionado
      const selectedItem = this.$refs.btnOptions.querySelector(".selected");
      if (selectedItem) {
        // Calcula a posição do item selecionado em relação à navegação
        const wrapperWidth = this.$refs.btnOptionsWrapper.clientWidth;
        const itemLeft = selectedItem.offsetLeft;
        const itemWidth = selectedItem.offsetWidth;
        const itemCenter = itemLeft + itemWidth / 3;

        // Calcula a posição de rolagem para centralizar o item selecionado
        const scrollLeft = itemCenter - wrapperWidth / 3;
        this.$refs.btnOptionsWrapper.scrollTo({
          left: scrollLeft,
          behavior: "smooth",
        });
      }
    },
    getCurrentMonth(months) {
      let mesAtual = new Date().toLocaleString("pt-BR", {
        month: "short",
      });
      mesAtual = mesAtual.replace(".", "");
      const anoAtual = new Date().getFullYear().toString();
      for (const [mes, ano] of months) {
        if (mes == mesAtual && ano == anoAtual) {
          return [mes, ano];
        }
      }
      return months[months.length - 1];
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  .nav-prev,
  .nav-next {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    color: #fff;
    background-color: #cb5540;
    border-radius: 50%;
    cursor: pointer;
    user-select: none;

    i {
      font-size: 12px;
    }
  }

  .nav-prev:hover,
  .nav-next:hover {
    font-weight: bold;
  }

  .nav-prev {
    margin-left: 10px;
  }

  .nav-next {
    margin-right: 10px;
  }
}

.btn-options-wrapper-month {
  overflow: hidden; /* Adiciona uma barra de rolagem horizontal */
}

.btn-options-month {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: #fff;
  // margin-top: 20px;

  h2 {
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    user-select: none;
    text-transform: uppercase;
  }

  h2:hover {
    font-weight: bold;
  }

  h3 {
    font-size: 12px;
    display: none;
  }
}
.month-box {
  padding: 10px 20px;
  margin: 0 10px 0 0;
}

.selected-month {
  background: #cb5540;
  border-radius: 4px;
  text-align: center;
  h2 {
    font-weight: bold;
  }
  h3 {
    display: block;
    margin-bottom: 0;
  }
}
</style>
