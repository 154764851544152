<template>
  <div class="form-default container">
    <div>
      <p
        v-if="successMessage"
        class="success-message"
      >
        {{ successMessage }}
      </p>

      <p
        v-else-if="errorMessage"
        class="error-message"
      >
        {{ errorMessage }}
      </p>
    </div>

    <div class="row">
      <div class="col">
        <label for="">Valor</label>
        <input
          id="amount"
          v-model="payload.amount"
          v-money="moneyConfig"
          type="text"
          name="amount"
          class="form-control"
          placeholder=""
          required
        >
      </div>
      <div class="col">
        <label for="">Data da transação</label>
        <input
          id="transaction_at"
          v-model="payload.transaction_at"
          type="date"
          name="transaction_at"
          class="form-control"
          placeholder="dd/mm/yyyy"
          onkeydown="return false" 
          onclick="this.showPicker()"
          required
        >
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <label>Conta de saída</label>
        <select
          id="type"
          v-model="payload.id_bank_account_sending"
          required
        >
          <option
            v-for="(type, index) in accounts"
            :key="index"
            :value="type.id"
          >
            {{ type.name }}
          </option>
        </select>
        <h3 v-if="payload.id_bank_account_sending">
          Saldo da conta: {{ formatValue(accountSending[0].balance) }}
        </h3>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label>Conta destino</label>
        <select
          id="type"
          v-model="payload.id_bank_account_receiving"
          required
        >
          <option
            v-for="(type, index) in investment ? investmentAccounts : accounts"
            :key="index"
            :value="type.id"
          >
            {{ type.name }}
          </option>
        </select>
        <h3 v-if="payload.id_bank_account_receiving">
          Saldo da conta: {{ formatValue(accountReceiving[0].balance) }}
        </h3>
      </div>
    </div>
    <button @click="save">
      <span> Salvar </span>
    </button>
  </div>
</template>

<script>
import axios from 'axios';
import { useAppStore } from '@/store/store.js';
import shared from "@/shared";
import { unformat } from "v-money3";

export default {
  components: {},
  props: {
    formatValue: Function,
    investment: Boolean
  },
  data() {
    return {
      selected: true,
      successMessage: '',
      errorMessage: '',
      fieldNames: {},
      requiredFields: [],
      payload: {
        id_bank_account_sending: null,
        id_bank_account_receiving: null,
        amount: null,
        description: 'Transferência entre contas',
        transaction_at: null,
        transfer_type: this.investment ? this.investment : false
      },
      moneyConfig: shared.moneyConfig()
    };
  },

  computed: {
    accountReceiving() {
      let acc = this.accounts.filter(
        (acc) => acc.id == this.payload.id_bank_account_receiving,
      );
      return acc;
    },
    accountSending() {
      let acc = this.accounts.filter(
        (acc) => acc.id == this.payload.id_bank_account_sending,
      );
      return acc;
    },
    accounts() {
      const store = useAppStore();
      const accounts = store.accountsResponse;

      return accounts;
    },
    token() {
      const appStore = useAppStore();
      return appStore.token;
    },
    investmentAccounts() {
      const investmentAccount = this.accounts.filter(
        (acc) => acc.id_account_type == "3"
      );

      return investmentAccount;
    },
  },
  created() {},
  mounted() {},
  methods: {
    validateForm(requiredFields) {
      this.errorMessage = '';

      for (const field of requiredFields) {
        if (!this.payload[field]) {
          this.errorMessage = `Por favor, preencha o campo '${field}'.`;
          return false;
        }
      }

      return true;
    },
    async save() {
      const store = useAppStore();

      // Mapeamento de nomes de campos em inglês para português

      this.fieldNames = {
        amount: 'valor',
        id_bank_account_sending: 'Conta de saída',
        id_bank_account_receiving : 'Conta de Destino',
        transaction_at: 'Data da transação',
      };

      this.requiredFields = ['amount', 'id_bank_account_sending', 'transaction_at','id_bank_account_receiving'];

      const missingFields = [];

      for (const field of this.requiredFields) {
        if (!this.payload[field]) {
          missingFields.push(this.fieldNames[field]);
        }
      }

      if (missingFields.length > 0) {
        const missingFieldsString = missingFields.join(', ');
        this.payload.amount = null;
        this.errorMessage = `Os seguintes campos são obrigatórios: ${missingFieldsString}.`;
        return; // Abortar a função caso o formulário não seja válido
      }

     
      this.payload.amount =  unformat(this.payload.amount, this.moneyConfig);;
      try {
        const response = await axios.post(
          `/transfer`,
          this.payload,
          {
            headers: { Authorization: `Bearer ${this.token}` },
          },
        );
        //this.getBills(this.account.id);
        const store = useAppStore();
          store.updateData();
        this.successMessage = 'Transferência feita com sucesso!';
        this.payload = {
          id_bank_account_sending: null,
          id_bank_account_receiving: null,
          amount: null,
          transaction_at: null,
        };
        setTimeout(() => {
          this.closeModal();
        }, 1500);
      } catch (error) {
        console.error(error);
        this.payload.amount = null;
        this.errorMessage =
          'Erro ao Transferir. Por favor, tente novamente.';
      }
    },

    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
h4 {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  margin: 0;
  margin-bottom: 10px;
}
h3 {
  font-size: 12px;
  color: #fff;
  font-weight: 300;
  margin: 0;
  margin-bottom: 10px;
}

.selected {
  background: #cb5540;
  border-radius: 5px 0px 0px 5px;

  h2 {
    font-weight: bold !important;
  }
}
</style>
